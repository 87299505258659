import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import dayjs from "dayjs";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { listEmployees } from "../actions/employeeActions";
import { stringIncludes } from "../utils/utils";

const SearchBox = ({
 pageName,
 history,
 keyword,
 outlet,
 isActiveEmployee,
 date,
 userInfo,
 tab,
}) => {
 const [key, setKey] = useState(keyword);
 const [prevKeyword, setPrevKeyword] = useState(key);
 const location = useLocation(); // Added for reading the URL
 const dispatch = useDispatch();

 useEffect(() => {
  const searchParams = new URLSearchParams(location.search);
  // Update keyword state from URL if present
  const keywordParam = searchParams.get("keyword");
  if (keywordParam) {
   setKey(keywordParam); // Set the keyword from URL
  }
 }, [location.search, prevKeyword, outlet, pageName]);

 // Fungsi submit handler untuk form search
 const submitHandler = (e) => {
  e.preventDefault(); // Mencegah reload halaman
  if ((key && key.trim()) || !key) {
   history.push(
    outlet
     ? `/${pageName}/${key}?${
        userInfo.isAdmin ? "outlet=" + outlet : ""
       }&isActiveEmployee=${isActiveEmployee}&date=${
        date ? dayjs(date).format("YYYY-MM-DD") : ""
       }&tab=${tab}&keyword=${key}`
     : `/${pageName}/${key}?keyword=${key}`
   ); // Mengarahkan ke halaman dengan keyword
  }
  // Check if the keyword has changed
  const isKeywordChanged = key !== prevKeyword;
  if (isKeywordChanged || !key) {
   setPrevKeyword(key);
   if (stringIncludes(pageName, "employeeList")) {
    dispatch(listEmployees(key, null, outlet));
   }
  }
 };

 // Fungsi untuk menghapus search
 const clearSearch = () => {
  setKey(""); // Kosongkan input search
  history.push(
   outlet
    ? `/${pageName}?${
       userInfo.isAdmin ? "outlet=" + outlet : ""
      }&isActiveEmployee=${isActiveEmployee}&date=${
       date ? dayjs(date).format("YYYY-MM-DD") : ""
      }&tab=${tab}`
    : `/${pageName}`
  ); // Kembali ke halaman utama
 };

 return (
  <Form onSubmit={submitHandler} style={{ justifyContent: "right" }}>
   <div style={{ display: "flex", alignItems: "center" }}>
    <div
     style={{ position: "relative", display: "flex", alignItems: "center" }}
    >
     <Form.Control
      type="text"
      value={key} // Menggunakan state key
      name="q"
      placeholder="Search by name..."
      onChange={(e) => setKey(e.target.value)} // Set key saat input berubah
      style={{
       backgroundColor: "rgba(50,50,50,0.1)",
       borderRadius: "8px",
       paddingRight: "2.5em", // Ruang untuk ikon x
       width: "200px",
      }}
     />
     {key && (
      <Button
       size="sm"
       variant="light"
       type="button"
       className="clear-button"
       onClick={clearSearch} // Menghapus keyword dan kembali ke halaman utama
       style={{
        position: "absolute",
        right: "10px", // Posisikan di sebelah kanan input
        top: "50%", // Posisikan di tengah vertikal
        transform: "translateY(-50%)", // Koreksi agar benar-benar di tengah
        border: "none",
        fontSize: "1.5em",
        cursor: "pointer",
        height: "30px",
        background: "transparent",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
       }}
      >
       &times;
      </Button>
     )}
    </div>
    <button
     type="submit"
     className="button"
     style={{
      height: "2.3em",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
     }}
    >
     <i className="fas fa-search" style={{ fontSize: "1.25em" }} />
    </button>
   </div>
  </Form>
 );
};

export default SearchBox;
