/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Employee from "../components/employee";
import Loading from "../components/loader.jsx";
import Message from "../components/message.jsx";
import { listEmployees } from "../actions/employeeActions";
import { Route, useLocation } from "react-router-dom";
import SearchBox from "../components/searchBox";
import {
 Row,
 Col,
 ToggleButtonGroup,
 ToggleButton,
 Container,
 Form,
} from "react-bootstrap";
import {
 options,
 capitalizeEachWord,
 countActiveEmployee,
 countStatusEmployee,
 stringIncludes,
} from "../utils/utils.jsx";
import { getSheetDetails } from "../actions/sheetActions";
import dayjs from "dayjs";
import verifiedIcon from "../Assets/icon/verified.png";
import { logout } from "../actions/userActions";
import EmployeesTableView from "./employeeTableView";
import EmployeesTableBpjsView from "./employeeTableBpjsView";
import EmployeesTableBankView from "./employeeTableBankView";
import EmployeesTableAgeView from "./employeeTableAgeView";
import { outlets } from "../data/outlet.jsx";
import { employeeListTabs } from "../data/etc.jsx";
import Tab from "../components/tab";
import { updateSettings } from "../utils/localStorage";
import FormSelect from "../components/select/formSelect";
import { getAllOutlet } from "../actions/outletActions";
import EmployeeScheduleTableView from "./employeeTableScheduleView.jsx";

const EmployeeListView = ({ match, history }) => {
 const existingSettings = JSON.parse(localStorage.getItem("settings")) || {};

 const location = useLocation(); // Added for reading the URL
 const userLogin = useSelector((state) => state.userLogin);
 const { loading: userLoading, userInfo } = userLogin;
 const [isActiveEmployee, setIsActiveEmployee] = useState(true);
 const [tab, setTab] = useState(
  new URLSearchParams(location.search).get("tab") ||
   existingSettings?.employeeList?.tab ||
   "grid"
 );

 //const keyword = match.params.keyword;
 const [keyword, setKeyword] = useState("");

 const [outlet, setOutlet] = useState(
  (userInfo.isAdmin && userInfo.outlet) ||
   (userInfo.isCompanyLeader && userInfo.outlet)
   ? new URLSearchParams(location.search).get("outlet") ||
      existingSettings?.employeeList?.outlet ||
      userInfo.outlet
   : "ALL OUTLETS"
 );

 const [prevOutlet, setPrevOutlet] = useState(outlet);

 const handleOutletChange = (e) => {
  const selectedOutlet = e.target.value;
  setOutlet(selectedOutlet);

  // Update the URL with the selected outlet
  const searchParams = new URLSearchParams(location.search);
  searchParams.set("outlet", selectedOutlet);
  history.push({ search: searchParams.toString() });
 };

 const handleIsActiveEmployee = (value) => {
  setIsActiveEmployee(value);

  // Update the URL with the selected outlet
  const searchParams = new URLSearchParams(location.search);
  searchParams.set("isActive", value);
  history.push({ search: searchParams.toString() });
 };

 const handleTabChange = (e) => {
  const selectedTab = e.target.value;
  setTab(selectedTab);

  // Update the URL with the selected outlet
  const searchParams = new URLSearchParams(location.search);
  searchParams.set("tab", selectedTab);
  history.push({ search: searchParams.toString() });
 };

 const dispatch = useDispatch();

 const employeesList = useSelector((state) => state.employeesList);
 const { loading, error, employees, page, pages } = employeesList;

 const sheetDetails = useSelector((state) => state.sheetDetails);
 const { sheet } = sheetDetails;
 const spreadsheetNameToImport = "sheetImportCuti";

 const outletList = useSelector((state) => state.outletList);
 const { loading: loadingOutlets, error: errorOutlets, outlets } = outletList;

 useEffect(() => {
  if ((!userLoading && !userInfo) || stringIncludes(error, "not authorized")) {
   dispatch(logout());
   history.push("/login");
  }

  // Update outlet state from URL if present
  const searchParams = new URLSearchParams(location.search);
  const outletParam = searchParams.get("outlet");
  if (outletParam) {
   setOutlet(outletParam); // Set the outlet from URL
  }

  if (tab) {
   updateSettings(`employeeList`, `tab`, tab);
  }

  // Check if the tab has changed
  const isOutletChanged = outlet !== prevOutlet; // You need to maintain a `prevTab` state to compare
  if (isOutletChanged) {
   updateSettings(`employeeList`, `outlet`, outlet);
   setPrevOutlet(outlet);
  }

  // Update keyword state from URL if present
  const keywordParam = searchParams.get("keyword") || "";
  setKeyword(keywordParam); // Set the keyword from URL

  const isActiveEmployeeParam = searchParams.get("isActive");
  if (isActiveEmployeeParam === "false") {
   setIsActiveEmployee(false); // Set the outlet from URL
  } else if (isActiveEmployeeParam === "true") {
   setIsActiveEmployee(true); // Set the outlet from URL
  }

  if (isOutletChanged || (Array.isArray(employees) && !employees.length)) {
   dispatch(getSheetDetails(spreadsheetNameToImport));
   dispatch(listEmployees(keyword, null, outlet));
  }

  if (!loadingOutlets && Array.isArray(outlets) && !outlets.length) {
   dispatch(getAllOutlet());
  }
  //console.log(outlets);
 }, [
  userLoading,
  userInfo,
  spreadsheetNameToImport,
  outlet,
  error,
  history,
  tab,
  isActiveEmployee,
 ]);

 const getTable = () => {
  switch (tab) {
   case "table general":
    return (
     <>
      <EmployeesTableView isActiveEmployee={isActiveEmployee} outlet={outlet} />
     </>
    );

   case "table bpjs":
    return (
     <>
      <EmployeesTableBpjsView
       isActiveEmployee={isActiveEmployee}
       outlet={outlet}
      />
     </>
    );

   case "table bank":
    return (
     <>
      <EmployeesTableBankView
       isActiveEmployee={isActiveEmployee}
       outlet={outlet}
      />
     </>
    );

   case "table age":
    return (
     <>
      <EmployeesTableAgeView
       isActiveEmployee={isActiveEmployee}
       outlet={outlet}
      />
     </>
    );

   case "table schedule":
    return (
     <>
      <EmployeeScheduleTableView
       isActiveEmployee={isActiveEmployee}
       outlet={outlet}
      />
     </>
    );
  }
 };

 return (
  <>
   <>
    <Row style={{ justifyContent: "center" }}>
     <Col md="auto">
      <h3>{outlet ? `${capitalizeEachWord(outlet)} ` : ""}Employees</h3>
     </Col>
    </Row>

    <Row className="justify-content-md-left">
     <Col>
      <Tab
       tabs={employeeListTabs}
       tab={tab}
       setTab={handleTabChange}
       defaultSetTab={false}
      />
     </Col>
    </Row>

    <Row className="mt-3 justify-content-between">
     <Col md="auto">
      <ToggleButtonGroup type="radio" name="options">
       <ToggleButton
        key="isActiveEmployee"
        id="isActiveEmployeeRadio1"
        value={true}
        name="radio"
        onChange={(e) => handleIsActiveEmployee(e.target.value)}
        style={{
         backgroundColor: isActiveEmployee === true ? "#dc3545" : "#6c757d",
         border: "none", // Remove the outline
        }}
       >
        Active
       </ToggleButton>
       <ToggleButton
        key="resignEmployee"
        id="isActiveEmployeeRadio2"
        value={false}
        name="radio"
        onChange={(e) => handleIsActiveEmployee(e.target.value)}
        style={{
         backgroundColor: isActiveEmployee === false ? "#dc3545" : "#6c757d",
         border: "none", // Remove the outline
        }}
       >
        Resigned
       </ToggleButton>
      </ToggleButtonGroup>
     </Col>
     {userInfo.isAdmin || userInfo.isCompanyLeader ? (
      <FormSelect
       controlId="outlet"
       value={outlet || ""}
       onChange={handleOutletChange}
       options={outlets || ""}
       defaultLabel={false}
       customLabel="ALL OUTLETS"
      />
     ) : (
      <></>
     )}

     <Col md="auto">
      <Route
       render={({ history }) => (
        <SearchBox
         history={history}
         pageName={"employeeList"}
         keyword={keyword}
         outlet={outlet}
         isActiveEmployee={isActiveEmployee}
         userInfo={userInfo}
         tab={tab}
        />
       )}
      />{" "}
     </Col>
    </Row>

    {sheet ? (
     <Row style={{ fontSize: "80%" }}>
      <Col md="auto">Last updated employee:</Col>
      <Col md="auto">{sheet.employeeLastUpdatedName}</Col>
      <Col md="auto">
       {"at "}
       {sheet.employeeLastUpdatedAt
        ? dayjs(sheet.employeeLastUpdatedAt).format("D-MMM-YYYY HH:mm:ss wib")
        : null}
      </Col>
      <Col md="auto">
       <i
        className="fas fa-user"
        style={{ color: "green", paddingRight: "2px" }}
       ></i>
       <strong style={{ paddingRight: "2px" }}>{`Active:${countActiveEmployee(
        employees,
        true,
        outlet
       )} | `}</strong>
       <i
        className="fas fa-sign-out-alt"
        style={{ color: "red", paddingRight: "2px" }}
       ></i>
       <strong>{`Resigned:${countActiveEmployee(
        employees,
        false,
        outlet
       )}`}</strong>
      </Col>
      <Col md="auto">
       <img
        alt="Verified"
        src={verifiedIcon}
        style={{ width: "18px", height: "18px" }}
       />
       <strong style={{ paddingRight: "2px" }}>{`Tetap:${countStatusEmployee(
        employees,
        true,
        outlet
       )} | `}</strong>
       <i
        className="fas fa-user-clock"
        style={{ color: "gray", paddingRight: "2px" }}
       ></i>
       <strong>{`Probation:${countStatusEmployee(
        employees,
        false,
        outlet
       )}`}</strong>
      </Col>
     </Row>
    ) : (
     <></>
    )}
   </>
   {loading || loadingOutlets ? (
    <Loading />
   ) : error || errorOutlets ? (
    <Message variant="danger">{error}</Message>
   ) : stringIncludes(tab, "grid") ? (
    <>
     <Row>
      {userInfo.isAdmin ||
      userInfo.isOutletLeader ||
      userInfo.isCompanyLeader ? (
       employees.map((employee) =>
        isActiveEmployee ? (
         (!employee.dateResign && outlet === employee.outlet) ||
         (!employee.dateResign && outlet === "ALL OUTLETS") ? (
          <Col key={employee._id} sm={12} md={6} lg={4} xl={3}>
           <Employee employee={employee} />
          </Col>
         ) : null
        ) : (employee.dateResign && outlet === employee.outlet) ||
          (employee.dateResign && outlet === "ALL OUTLETS") ? (
         <Col key={employee._id} sm={12} md={6} lg={4} xl={3}>
          <Employee employee={employee} />
         </Col>
        ) : null
       )
      ) : (
       <Col key={employees._id} sm={12} md={6} lg={4} xl={3}>
        <Employee employee={employees} />
       </Col>
      )}
     </Row>
    </>
   ) : (
    getTable()
   )}
  </>
 );
};

export default EmployeeListView;
