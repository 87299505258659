export const updateSettings = (page, key, value) => {
 // Ambil data settings dari localStorage
 const settings = JSON.parse(localStorage.getItem("settings")) || {};

 // Buat atau perbarui page dan key yang diberikan
 const updatedSettings = {
  ...settings,
  [page]: {
   ...settings[page],
   [key]: value,
  },
 };

 // Simpan settings yang sudah diperbarui ke localStorage
 localStorage.setItem("settings", JSON.stringify(updatedSettings));
};
