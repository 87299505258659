import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Row, Col, Button, Card } from "react-bootstrap";
import {
 getEmployeeDetails,
 deleteEmployee,
 deleteEmployeeSchedule,
} from "../actions/employeeActions";
import { EMPLOYEE_UPDATE_RESET } from "../constants/employeeConstant";
import dayjs from "dayjs";
import GoBackButton from "../components/goBackButton";
import { getAge, getWorkDuration, stringIncludes } from "../utils/utils";
import { updateEmployee } from "../actions/employeeActions";
import { EMPLOYEE_DETAILS_REQUEST } from "../constants/employeeConstant";
import UploadProfilePicture from "../components/uploadProfilePicture";
import { logout } from "../actions/userActions";
import IconStarRating from "../components/iconStarRating";
import Tab from "../components/tab";
import { employeeTabs } from "../data/etc";
import FormSelect from "../components/select/formSelect";
import Loading from "../components/loader.jsx";
import Message from "../components/message.jsx";
import { listBanks } from "../actions/bankActions";
import { getAllStatus } from "../actions/statusActions";
import { getAllPosition } from "../actions/positionActions";
import { getAllOutlet } from "../actions/outletActions";
import Name from "../components/employee/name.jsx";
import Schedule from "../components/employee/schedule.jsx";
import CardPrimary from "../components/employeeDetails/cardPrimary";
import CardFooterLastUpdate from "../components/employee/CardFooterLastUpdate.jsx";

const EmployeeDetailsView = ({ match, history }) => {
 const userLogin = useSelector((state) => state.userLogin);
 const { loading: userLoading, userInfo } = userLogin;

 const [tab, setTab] = useState("work");

 const [employeeID, setEmployeeID] = useState("");
 const [name, setName] = useState("");
 const [outlet, setOutlet] = useState("");
 const [ktpID, setKtpID] = useState("");
 const [addressOfKTP, setAddressOfKTP] = useState("");
 const [address, setAddress] = useState("");

 const [status, setStatus] = useState("");
 const [position, setPosition] = useState("");
 const [sex, setSex] = useState("");
 const [bpjsKesehatan, setBpjsKesehatan] = useState("");
 const [bpjsKesehatanDescription, setBpjsKesehatanDescription] = useState("");
 const [bpjsKetenagakerjaan, setBpjsKetenagakerjaan] = useState("");
 const [bpjsKetenagakerjaanDescription, setBpjsKetenagakerjaanDescription] =
  useState("");

 const [placeOfBirth, setPlaceOfBirth] = useState("");
 const [dateOfBirth, setDateOfBirth] = useState("");
 const [dateIn, setDateIn] = useState("");
 const [dateResign, setDateResign] = useState("");
 const [phone, setPhone] = useState("");
 const [email, setEmail] = useState("");
 const [clockIn, setClockIn] = useState("");
 const [clockIn2, setClockIn2] = useState("");
 const [clockIn3, setClockIn3] = useState("");
 const [reasonResign, setReasonResign] = useState("");
 const [datePermanent, setDatePermanent] = useState("");
 const [bank, setBank] = useState("");
 const [bankAccount, setBankAccount] = useState("");
 const [motherName, setMotherName] = useState("");
 const [positionChange, setPositionChange] = useState("");
 const [kkID, setKkID] = useState("");
 const [npwpID, setNpwpID] = useState("");
 const [ukuranBaju, setUkuranBaju] = useState("");
 const [education, setEducation] = useState("");
 const [educationMajor, setEducationMajor] = useState("");
 const [university, setUniversity] = useState("");
 const [dateGraduation, setDateGraduation] = useState("");
 const [religion, setReligion] = useState("");
 const [emergencyName, setEmergencyName] = useState("");
 const [emergencyRelation, setEmergencyRelation] = useState("");
 const [emergencyPhone, setEmergencyPhone] = useState("");
 const [grade, setGrade] = useState(0);
 const [maritalStatus, setMaritalStatus] = useState("");
 const [lateInTolerance, setLateInTolerance] = useState("");
 const [updatedAt, setUpdatedAt] = useState("");
 const [createdAt, setCreatedAt] = useState("");
 const [lastLoginAt, setLastLoginAt] = useState("");
 const [lastSeenAt, setLastSeenAt] = useState("");

 const [isDisabled, setIsDisabled] = useState(true);

 const dispatch = useDispatch();
 const employeeDetails = useSelector((state) => state.employeeDetails);
 const { loading, error, employee } = employeeDetails;
 const employeeUpdate = useSelector((state) => state.employeeUpdate);
 const { success: successUpdate } = employeeUpdate;
 const bankList = useSelector((state) => state.bankList);
 const { loading: loadingBank, error: errorBank, banks } = bankList;

 const statusList = useSelector((state) => state.statusList);
 const {
  loading: loadingAllStatus,
  error: errorAllStatus,
  status: allStatus,
 } = statusList;

 const positionList = useSelector((state) => state.positionList);
 const {
  loading: loadingPositions,
  error: errorPositions,
  positions,
 } = positionList;

 const outletList = useSelector((state) => state.outletList);
 const { loading: loadingOutlets, error: errorOutlets, outlets } = outletList;

 const refresh = () => {
  setEmployeeID(employee.employeeID);
  setName(employee.name);
  setOutlet(employee.outlet);
  setKtpID(employee.ktpID);
  setAddressOfKTP(employee.addressOfKTP);
  setAddress(employee.address);
  setStatus(employee.status);
  setPosition(employee.position);
  setSex(employee.sex);
  setBpjsKesehatan(employee.bpjsKesehatan);
  setBpjsKesehatanDescription(employee.bpjsKesehatanDescription);
  setBpjsKetenagakerjaan(employee.bpjsKetenagakerjaan);
  setBpjsKetenagakerjaanDescription(employee.bpjsKetenagakerjaanDescription);
  setPlaceOfBirth(employee.placeOfBirth);
  setDateOfBirth(employee.dateOfBirth || "");
  setDateIn(employee.dateIn || "");
  setDateResign(employee.dateResign || "");
  setPhone(employee.phone);
  setEmail(employee.email);
  setClockIn(employee.clockIn || "");
  setClockIn2(employee.clockIn2 || "");
  setClockIn3(employee.clockIn3 || "");
  setReasonResign(employee.reasonResign);
  setDatePermanent(employee.datePermanent || "");
  setBank(employee.bank);
  setBankAccount(employee.bankAccount);
  setMotherName(employee.motherName);
  setPositionChange(employee.positionChange);
  setKkID(employee.kkID);
  setNpwpID(employee.npwpID);
  setUkuranBaju(employee.ukuranBaju);
  setEducation(employee.education);
  setEducationMajor(employee.educationMajor);
  setUniversity(employee.university);
  setDateGraduation(employee.dateGraduation || "");
  setReligion(employee.religion);
  setEmergencyName(employee.emergencyName);
  setEmergencyRelation(employee.emergencyRelation);
  setEmergencyPhone(employee.emergencyPhone);
  setGrade(employee.grade);
  setMaritalStatus(employee.maritalStatus);
  setLateInTolerance(employee.lateInTolerance);
  setUpdatedAt(employee.updatedAt);
  setCreatedAt(employee.createdAt);
  setLastLoginAt(employee.lastLoginAt);
  setLastSeenAt(employee.lastSeenAt);
 };

 //const banksMemo = useMemo(
 // () => {
 //  if (!loadingBank && !banks.length) dispatch(listBanks());
 //  if (banks) return banks;
 // }, // eslint-disable-next-line
 // []
 //);

 useEffect(() => {
  if ((!userLoading && !userInfo) || stringIncludes(error, "not authorized")) {
   dispatch(logout());
   history.push("/login");
  }
  if (!employee || match.params.id !== employee._id) {
   dispatch({ type: EMPLOYEE_DETAILS_REQUEST });
   dispatch(getEmployeeDetails(match.params.id));
  }
  if (employee && match.params.id === employee._id) {
   refresh();
  }
  if (!loadingBank && Array.isArray(banks) && !banks.length) {
   dispatch(listBanks());
  }
  //console.log({ banks });

  if (!loadingAllStatus && Array.isArray(allStatus) && !allStatus.length) {
   dispatch(getAllStatus());
  }
  //console.log(allStatus);

  if (!loadingPositions && Array.isArray(positions) && !positions.length) {
   dispatch(getAllPosition());
  }
  //console.log(positions);

  if (!loadingOutlets && Array.isArray(outlets) && !outlets.length) {
   dispatch(getAllOutlet());
  }
  //console.log(outlets);

  if (successUpdate) {
   dispatch({ type: EMPLOYEE_UPDATE_RESET });
   dispatch(getEmployeeDetails(match.params.id));
  } // eslint-disable-next-line
 }, [history, successUpdate, banks, employee]);

 const deleteEmployeeHandler = () => {
  if (window.confirm("Delete this employee?")) {
   dispatch(deleteEmployee(employee._id));
   history.push("/employeeList");
  }
 };

 const deleteEmployeeScheduleHandler = () => {
  if (window.confirm("Are you sure reseting schedule?")) {
   dispatch(deleteEmployeeSchedule(employee._id));

   setClockIn("");
   setClockIn2("");
   setClockIn3("");
  }
 };

 const submitHandler = () => {
  if (!employee) {
   return;
  }

  dispatch(
   updateEmployee({
    _id: employee._id,
    employeeID,
    name,
    outlet,
    ktpID,
    addressOfKTP,
    address,
    status,
    position,
    sex,
    bpjsKesehatan,
    bpjsKesehatanDescription,
    bpjsKetenagakerjaan,
    bpjsKetenagakerjaanDescription,
    placeOfBirth,
    dateOfBirth,
    dateIn,
    dateResign,
    phone,
    email,
    clockIn: clockIn ? dayjs(clockIn).toDate() : "",
    clockIn2: clockIn2 ? dayjs(clockIn2).toDate() : "",
    clockIn3: clockIn3 ? dayjs(clockIn3).toDate() : "",
    reasonResign,
    datePermanent,
    bank,
    bankAccount,
    motherName,
    positionChange,
    kkID,
    npwpID,
    ukuranBaju,
    education,
    educationMajor,
    university,
    dateGraduation: dateGraduation ? dayjs(dateGraduation).toDate() : "",
    religion,
    emergencyName,
    emergencyRelation,
    emergencyPhone,
    grade,
    maritalStatus,
    lateInTolerance,
   })
  );
  dispatch(getEmployeeDetails(match.params.id));
 };

 const defaultOptions = "Select...";

 // Fungsi untuk mengubah tanggal menjadi format yang diterima input type="date"
 const dateForPicker = (date) => {
  if (date) {
   return dayjs(date).format("YYYY-MM-DD");
  }
 };

 // Fungsi untuk mengubah string tanggal menjadi objek Date
 const dateFromDateString = (dateString) => {
  return dayjs(dateString).toDate();
 };

 const getAgeLong = () => {
  return getAge(dateOfBirth);
 };

 const handleClockInChange = (e) => {
  const timeValue = e.target.value; // waktu dari input, format HH:mm
  const currentDate = dayjs().format("YYYY-MM-DD"); // tanggal saat ini

  // Gabungkan tanggal saat ini dengan waktu input
  const clockInDayjs = dayjs(`${currentDate} ${timeValue}`, "YYYY-MM-DD HH:mm");

  setClockIn(clockInDayjs); // Simpan objek dayjs ke dalam state
 };

 const handleClockInChange2 = (e) => {
  const timeValue = e.target.value; // waktu dari input, format HH:mm
  const currentDate = dayjs().format("YYYY-MM-DD"); // tanggal saat ini

  // Gabungkan tanggal saat ini dengan waktu input
  const clockInDayjs = dayjs(`${currentDate} ${timeValue}`, "YYYY-MM-DD HH:mm");

  setClockIn2(clockInDayjs); // Simpan objek dayjs ke dalam state
 };

 const handleClockInChange3 = (e) => {
  const timeValue = e.target.value; // waktu dari input, format HH:mm
  const currentDate = dayjs().format("YYYY-MM-DD"); // tanggal saat ini

  // Gabungkan tanggal saat ini dengan waktu input
  const clockInDayjs = dayjs(`${currentDate} ${timeValue}`, "YYYY-MM-DD HH:mm");

  setClockIn3(clockInDayjs); // Simpan objek dayjs ke dalam state
 };

 return loading ||
  loadingBank ||
  loadingAllStatus ||
  loadingPositions ||
  loadingOutlets ? (
  <Loading />
 ) : error || errorBank || errorAllStatus || errorPositions || errorOutlets ? (
  <Message variant="danger">{error || errorBank}</Message>
 ) : (
  <>
   <Row className="justify-content-md-between">
    <Col md={2}>
     <GoBackButton onClick={() => history.goBack()}></GoBackButton>
    </Col>
   </Row>

   <Row className="justify-content-md-start">
    <Col md="auto">
     <h3>{isDisabled ? "Employee Details" : "Edit Employee"}</h3>
    </Col>
   </Row>
   <Row className="justify-content-md-start">
    <Col md="auto">
     <Card
      className="justify-content-center normal-card"
      style={{ textAlign: "center", maxWidth: "500px" }}
     >
      <Card.Body>
       <Row className="justify-content-md-between">
        <Col md="auto">
         <UploadProfilePicture />
        </Col>
       </Row>
       <Row className="justify-content-md-center mt-2">
        <Col md="auto">
         <Name name={name} status={status} />
        </Col>
       </Row>
       <Row className="justify-content-md-center">
        <Col md="auto">
         <IconStarRating employee={employee} />
        </Col>
       </Row>

       <CardFooterLastUpdate
        employee={{ lastLoginAt, lastSeenAt, createdAt, updatedAt }}
       />
      </Card.Body>
     </Card>
    </Col>
    <Col md="auto">
     <CardPrimary employee={employee} className="justify-content-md-start" />
    </Col>
    <Col md="auto">
     <Card
      className="justify-content-center normal-card"
      style={{ textAlign: "center", maxWidth: "500px" }}
     >
      <Card.Body>
       <Schedule
        shift1={clockIn}
        shift2={clockIn2}
        shift3={clockIn3}
        lateInTolerance={lateInTolerance}
       />
      </Card.Body>
     </Card>
    </Col>
   </Row>

   <Row className="justify-content-md-between">
    <Col md={2}>
     {!isDisabled && userInfo.isAdmin && (
      <Button
       variant="outline-danger"
       style={{ justifyContent: "right" }}
       onClick={deleteEmployeeHandler}
      >
       <i className="fas fa-trash"></i>
       {" Delete Employee"}
      </Button>
     )}
    </Col>

    <Col md="auto">
     {userInfo.isAdmin ? (
      isDisabled ? (
       <Button
        type="button"
        variant="light"
        onClick={() => setIsDisabled(false)}
        style={{ gap: "20px" }}
       >
        Edit
       </Button>
      ) : (
       <>
        <Button
         type="button"
         variant="light"
         onClick={() => {
          setIsDisabled(true);
          refresh();
         }}
         className="me-2"
        >
         Cancel
        </Button>
        <Button
         type="submit"
         variant="danger"
         onClick={() => {
          submitHandler();
          setIsDisabled(true);
         }}
         disabled={isDisabled}
        >
         Apply
        </Button>
       </>
      )
     ) : null}
    </Col>
   </Row>
   <Tab tabs={employeeTabs} tab={tab} setTab={setTab} />
   {stringIncludes(tab, "work") && (
    <>
     <Row className="mt-3">
      <Col md={3}>
       <h4 className="underline">Work</h4>
      </Col>
     </Row>

     <Row>
      <Col md={3}>
       <Form.Group controlId="employeeID">
        <Form.Label>Employee ID</Form.Label>
        <Form.Control
         type="number"
         placeholder="Your employee ID"
         value={employeeID}
         onChange={(e) => setEmployeeID(e.target.value)}
         disabled={isDisabled}
         onWheel={(e) => e.target.blur()}
        ></Form.Control>
       </Form.Group>

       <FormSelect
        formLabel="Status*"
        controlId="status"
        value={status || ""}
        onChange={(e) => setStatus(e.target.value)}
        options={allStatus || ""}
        disabled={isDisabled}
       ></FormSelect>

       <FormSelect
        formLabel="Outlet*"
        controlId="outlet"
        value={outlet || ""}
        onChange={(e) => setOutlet(e.target.value)}
        options={outlets || ""}
        disabled={isDisabled}
       ></FormSelect>

       <FormSelect
        formLabel="Position*"
        controlId="position"
        value={position || ""}
        onChange={(e) => setPosition(e.target.value)}
        options={positions || ""}
        disabled={isDisabled}
       ></FormSelect>
      </Col>
      <Col md={4}>
       <Form.Group controlId="name">
        <Form.Label>Full Name</Form.Label>
        <Form.Control
         placeholder="Your full name"
         value={name}
         onChange={(e) =>
          setName(e.target.value ? e.target.value.toUpperCase() : "")
         }
         disabled={isDisabled}
        ></Form.Control>
       </Form.Group>
       <Form.Group controlId="grade">
        <Form.Label>
         <Row>
          <Col md="auto">Grade / Golongan</Col>
          <Col md="auto">
           <IconStarRating employee={employee} />
          </Col>
         </Row>
        </Form.Label>
        <Form.Control
         type="number"
         placeholder="Your grade"
         value={grade}
         onChange={(e) =>
          setGrade(e.target.value ? Math.round(e.target.value) : 0)
         }
         disabled={isDisabled}
         onWheel={(e) => e.target.blur()}
        ></Form.Control>
       </Form.Group>
       <Form.Group controlId="dateIn">
        <Row>
         <Col>
          <Form.Label>Date Join</Form.Label>
         </Col>
         <Col md={9}>
          <Form.Text className="text-muted">
           {`Duration: ${getWorkDuration(dateIn, dateResign)}`}
          </Form.Text>
         </Col>
        </Row>
        <Form.Control
         type="date"
         value={dateForPicker(dateIn)}
         onChange={(e) => setDateIn(dateFromDateString(e.target.value))}
         disabled={isDisabled}
        />
       </Form.Group>

       <Form.Group controlId="datePermanent">
        <Row>
         <Col md="auto">
          <Form.Label>Date Permanent Employee / PKWTT</Form.Label>
         </Col>
         <Col md={9}>
          <Form.Text className="text-muted">
           {datePermanent
            ? `Permanent Duration: ${getWorkDuration(
               datePermanent,
               dateResign
              )}`
            : ""}
          </Form.Text>
         </Col>
        </Row>
        <Form.Control
         type="date"
         value={dateForPicker(datePermanent)}
         onChange={(e) => setDatePermanent(dateFromDateString(e.target.value))}
         disabled={isDisabled || status !== "TETAP"}
        />
       </Form.Group>
      </Col>
      <Col md={4}>
       <Form.Group controlId="positionChange">
        <Form.Label>Position Change/ Promosi/ Demosi/ Mutasi</Form.Label>
        <Form.Control
         value={positionChange || ""} // Gunakan string kosong jika nilai null
         onChange={(e) => setPositionChange(e.target.value)}
         disabled={isDisabled}
         as="textarea"
         rows={positionChange ? 3 : 1}
        ></Form.Control>
       </Form.Group>
       <Form.Group controlId="dateResign">
        <Row>
         <Col>
          <Form.Label className="text-danger">Date Resign</Form.Label>
         </Col>
        </Row>
        <Form.Control
         type="date"
         value={dateForPicker(dateResign)}
         onChange={(e) => setDateResign(dateFromDateString(e.target.value))}
         disabled={isDisabled}
        />
       </Form.Group>

       <Form.Group controlId="reasonResign">
        <Form.Label className="text-danger">Reason Resign</Form.Label>
        <Form.Control
         placeholder="Enter reason resign"
         value={reasonResign || ""} // Gunakan string kosong jika nilai null
         onChange={(e) => setReasonResign(e.target.value)}
         disabled={isDisabled}
         as="textarea"
         rows={reasonResign ? 3 : 1}
        ></Form.Control>
       </Form.Group>

       <Form.Group controlId="ukuranBaju">
        <Form.Label>Clothes Size / Ukuran Baju</Form.Label>
        <Form.Select
         value={ukuranBaju}
         onChange={(e) => setUkuranBaju(e.target.value)}
         disabled={isDisabled}
        >
         <option value="">{defaultOptions}</option>
         <option value="XS">XS: Extra Small</option>
         <option value="S">S: Small</option>
         <option value="M">M: Medium</option>
         <option value="L">L: Large</option>
         <option value="XL">XL: Extra Large</option>
         <option value="XXL">XXL: Double Extra Large</option>
         <option value="XXXL">XXXL: Triple Extra Large</option>
        </Form.Select>
       </Form.Group>
      </Col>
     </Row>
    </>
   )}

   {stringIncludes(tab, "schedule") && (
    <>
     <Row className="mt-3">
      <Col md={3}>
       <h4 className="underline">Schedule</h4>
      </Col>
     </Row>
     <Row>
      <Col md={2}>
       <Form.Group controlId="clockIn">
        <Form.Label>Shift In</Form.Label>
        <Form.Control
         type="time"
         value={clockIn ? dayjs(clockIn)?.format("HH:mm") : ""}
         onChange={handleClockInChange}
         disabled={isDisabled}
        />
       </Form.Group>
      </Col>
      <Col md={2}>
       <Form.Group controlId="clockIn2">
        <Form.Label>Shift In 2</Form.Label>
        <Form.Control
         type="time"
         value={clockIn2 ? dayjs(clockIn2)?.format("HH:mm") : ""}
         onChange={handleClockInChange2}
         disabled={isDisabled}
        />
       </Form.Group>
      </Col>
      <Col md={2}>
       <Form.Group controlId="clockIn">
        <Form.Label>Shift In 3</Form.Label>
        <Form.Control
         type="time"
         value={clockIn3 ? dayjs(clockIn3)?.format("HH:mm") : ""}
         onChange={handleClockInChange3}
         disabled={isDisabled}
        />
       </Form.Group>
      </Col>
      <Col md={2}>
       {!isDisabled && userInfo.isAdmin && (
        <Button
         variant="danger"
         style={{ justifyContent: "right" }}
         onClick={deleteEmployeeScheduleHandler}
        >
         <i className="fas fa-sync" />
         {" Reset Schedule"}
        </Button>
       )}
      </Col>
     </Row>
     <Row>
      <Col md={4}>
       <Form.Group controlId="lateInTolerance">
        <Form.Label>
         Late In Tolerance / Toleransi Keterlambatan (Menit)
        </Form.Label>
        <Form.Control
         type="number"
         value={lateInTolerance}
         onChange={(e) => setLateInTolerance(e.target.value)}
         disabled={isDisabled}
         onWheel={(e) => e.target.blur()}
        />
       </Form.Group>
      </Col>
     </Row>
    </>
   )}

   {stringIncludes(tab, "personal") && (
    <>
     <Row className="mt-3">
      <Col md={4}>
       <h4 className="underline">Personal Data</h4>
      </Col>
     </Row>
     <Row>
      <Col md={3}>
       <Form.Group controlId="sex">
        <Form.Label>
         sex<span>*</span>
        </Form.Label>
        <Form.Select
         value={sex}
         onChange={(e) => setSex(e.target.value)}
         disabled={isDisabled}
        >
         <option value="">{defaultOptions}</option>
         <option value="Men">Men</option>
         <option value="Women">Women</option>
        </Form.Select>
       </Form.Group>

       <Form.Group controlId="placeOfBirth">
        <Form.Label>Place of Birth</Form.Label>
        <Form.Control
         placeholder="Enter place of birth"
         value={placeOfBirth}
         onChange={(e) =>
          setPlaceOfBirth(e.target.value ? e.target.value.toUpperCase() : "")
         }
         disabled={isDisabled}
        ></Form.Control>
       </Form.Group>

       <Form.Group controlId="dateOfBirth">
        <Row>
         <Col md="auto">
          <Form.Label>Date of Birth </Form.Label>
         </Col>
         <Col md={8}>
          <Form.Text className="text-muted">{getAgeLong()}</Form.Text>
         </Col>
        </Row>
        <Form.Control
         type="date"
         value={dateForPicker(dateOfBirth)}
         onChange={(e) => setDateOfBirth(dateFromDateString(e.target.value))}
         disabled={isDisabled}
        />
       </Form.Group>
      </Col>
      <Col md={3}>
       <Form.Group controlId="religion">
        <Form.Label>Religion / Agama</Form.Label>
        <Form.Select
         value={religion}
         onChange={(e) => setReligion(e.target.value)}
         disabled={isDisabled}
        >
         <option value="">{defaultOptions}</option>
         <option value="ISLAM">Islam</option>
         <option value="PROTESTAN">Kristen Protestan</option>
         <option value="KATOLIK">Kristen Katolik</option>
         <option value="HINDU">Hindu</option>
         <option value="BUDDHA">Buddha</option>
         <option value="KHONGHUCU">Khonghucu</option>
         <option value="LAINNYA">Lainnya</option>
        </Form.Select>
       </Form.Group>
       <Form.Group controlId="maritalStatus">
        <Form.Label>Marital Status / Status Perkawinan</Form.Label>
        <Form.Select
         value={maritalStatus}
         onChange={(e) => setMaritalStatus(e.target.value)}
         disabled={isDisabled}
        >
         <option value="">{defaultOptions}</option>
         <option value="TK">Tidak Kawin</option>
         <option value="K/0">Kawin, belum punya anak</option>
         <option value="K/1">Kawin, anak 1</option>
         <option value="K/2">Kawin, anak 2</option>
         <option value="K/3">Kawin, anak 3</option>
        </Form.Select>
       </Form.Group>{" "}
       <Form.Group controlId="motherName">
        <Form.Label>Mother's Full Name/ Nama Lengkap Ibu Kandung</Form.Label>
        <Form.Control
         value={motherName}
         onChange={(e) =>
          setMotherName(e.target.value ? e.target.value.toUpperCase() : "")
         }
         disabled={isDisabled}
        ></Form.Control>
       </Form.Group>
      </Col>
      <Col md={4}>
       <Form.Group controlId="addressOfKTP">
        <Form.Label>Address of KTP</Form.Label>
        <Form.Control
         placeholder="Enter address of KTP"
         value={addressOfKTP || ""} // Gunakan string kosong jika nilai null
         onChange={(e) => setAddressOfKTP(e.target.value)}
         disabled={isDisabled}
         as="textarea"
         rows={3}
        ></Form.Control>
       </Form.Group>
       <Form.Group controlId="address">
        <Form.Label>Address of Domicile</Form.Label>
        <Form.Control
         placeholder="Enter address of domicile"
         value={address || ""} // Gunakan string kosong jika nilai null
         onChange={(e) => setAddress(e.target.value)}
         disabled={isDisabled}
         as="textarea"
         rows={3}
        ></Form.Control>
       </Form.Group>
      </Col>
     </Row>
     <Row className="mt-3">
      <Col md={4}>
       <h4 className="underline">Document</h4>
      </Col>
     </Row>
     <Row>
      <Col md={3}>
       <Form.Group controlId="ktpID">
        <Form.Label>KTP</Form.Label>
        <Form.Control
         type="number"
         placeholder="Enter KTP ID"
         value={ktpID}
         onChange={(e) => setKtpID(e.target.value)}
         disabled={isDisabled}
         onWheel={(e) => e.target.blur()} // prevent number change on scroll
        ></Form.Control>
       </Form.Group>
      </Col>
      <Col md={3}>
       <Form.Group controlId="kkID">
        <Form.Label>Kartu Keluarga</Form.Label>
        <Form.Control
         type="number"
         value={kkID}
         onChange={(e) => setKkID(e.target.value)}
         disabled={isDisabled}
         onWheel={(e) => e.target.blur()}
        ></Form.Control>
       </Form.Group>
      </Col>
      <Col md={3}>
       <Form.Group controlId="npwpID">
        <Form.Label>NPWP</Form.Label>
        <Form.Control
         type="number"
         placeholder="Enter NPWP"
         value={npwpID}
         onChange={(e) => setNpwpID(e.target.value)}
         disabled={isDisabled}
         onWheel={(e) => e.target.blur()} // prevent number change on scroll
        ></Form.Control>
       </Form.Group>
      </Col>
     </Row>
    </>
   )}

   {stringIncludes(tab, "contact") && (
    <>
     <Row className="mt-3">
      <Col md={3}>
       <h4 className="underline">Contact</h4>
      </Col>
     </Row>
     <Row>
      <Col md={2}>
       <Form.Group controlId="phone">
        <Row>
         <Col>
          <Form.Label>Phone</Form.Label>
         </Col>
        </Row>
        <Form.Control
         placeholder="Enter phone"
         value={phone}
         onChange={(e) => setPhone(e.target.value)}
         disabled={isDisabled}
        ></Form.Control>
       </Form.Group>
      </Col>
      <Col md={4}>
       <Form.Group controlId="email">
        <Form.Label>Email</Form.Label>
        <Form.Control
         placeholder="Enter email"
         value={email}
         onChange={(e) => setEmail(e.target.value)}
         disabled={isDisabled}
        ></Form.Control>
       </Form.Group>
      </Col>
      <Col md={4}></Col>
     </Row>
     <Row className="mt-3">
      <Col md={3}>
       <h4 className="underline">Emergency Contact</h4>
      </Col>
     </Row>
     <Row>
      <Col md={2}>
       <Form.Group controlId="emergencyPhone">
        <Row>
         <Col>
          <Form.Label>Emergency Phone</Form.Label>
         </Col>
        </Row>
        <Form.Control
         placeholder="Enter emergency phone"
         value={emergencyPhone}
         onChange={(e) => setEmergencyPhone(e.target.value)}
         disabled={isDisabled}
        ></Form.Control>
       </Form.Group>
      </Col>
      <Col md={2}>
       <Form.Group controlId="emergencyRelation">
        <Form.Label>Emergency Relation</Form.Label>
        <Form.Select
         value={emergencyRelation}
         onChange={(e) => setEmergencyRelation(e.target.value)}
         disabled={isDisabled}
        >
         <option value="">{defaultOptions}</option>
         <option value="IBU">Ibu</option>
         <option value="AYAH">Ayah</option>
         <option value="KAKAK">Kakak</option>
         <option value="ADIK">Adik</option>
         <option value="ISTRI">Istri</option>
         <option value="SUAMI">Suami</option>
        </Form.Select>
       </Form.Group>
      </Col>
      <Col md={4}>
       <Form.Group controlId="emergencyName">
        <Form.Label>Emergency Full Name</Form.Label>
        <Form.Control
         placeholder="Enter emergency name"
         value={emergencyName}
         onChange={(e) =>
          setEmergencyName(e.target.value ? e.target.value.toUpperCase() : "")
         }
         disabled={isDisabled}
        ></Form.Control>
       </Form.Group>
      </Col>
     </Row>
    </>
   )}

   {stringIncludes(tab, "education") && (
    <>
     <Row className="mt-3">
      <Col md={3}>
       <h4 className="underline">Education</h4>
      </Col>
     </Row>
     <Row>
      <Col md={4}>
       <Form.Group controlId="education">
        <Form.Label>Education</Form.Label>
        <Form.Select
         value={education}
         onChange={(e) => setEducation(e.target.value)}
         disabled={isDisabled}
        >
         <option value="">{defaultOptions}</option>
         <option value="SD">SD</option>
         <option value="SMP">SMP</option>
         <option value="SMA">SMA</option>
         <option value="SMK">SMK</option>
         <option value="D1">D1</option>
         <option value="D2">D2</option>
         <option value="D3">D3</option>
         <option value="D4">D4</option>
         <option value="S1">S1</option>
         <option value="S2">S2</option>
         <option value="S3">S3</option>
        </Form.Select>
       </Form.Group>
       <Form.Group controlId="university">
        <Form.Label>University / School Name</Form.Label>
        <Form.Control
         placeholder="Enter university or school name"
         value={university}
         onChange={(e) =>
          setUniversity(e.target.value ? e.target.value.toUpperCase() : "")
         }
         disabled={isDisabled}
        ></Form.Control>
       </Form.Group>
      </Col>
      <Col md={4}>
       <Form.Group controlId="educationMajor">
        <Form.Label>Major / Jurusan</Form.Label>
        <Form.Control
         placeholder="Enter major or jurusan"
         value={educationMajor}
         onChange={(e) => setEducationMajor(e.target.value)}
         disabled={isDisabled}
        ></Form.Control>
       </Form.Group>
       <Form.Group controlId="dateGraduation">
        <Row>
         <Col md="auto">
          <Form.Label>Date Graduation</Form.Label>
         </Col>
         <Col md="auto">
          <Form.Text className="text-muted">
           {dateGraduation &&
            `${getWorkDuration(dateGraduation, dateResign)} after graduation`}
          </Form.Text>
         </Col>
        </Row>
        <Form.Control
         type="date"
         value={dateForPicker(dateGraduation)}
         onChange={(e) => setDateGraduation(dateFromDateString(e.target.value))}
         disabled={isDisabled}
        />
       </Form.Group>
      </Col>
     </Row>
    </>
   )}
   {stringIncludes(tab, "bpjs") && (
    <>
     <Row className="mt-3">
      <Col md={3}>
       <h4 className="underline">BPJS Kesehatan</h4>
      </Col>
     </Row>
     <Row>
      <Col md={3}>
       <Form.Group controlId="bpjsKesehatan">
        <Form.Label>No. BPJS Kesehatan</Form.Label>
        <Form.Control
         type="number"
         placeholder="Enter BPJS Kesehatan"
         value={bpjsKesehatan}
         onChange={(e) => setBpjsKesehatan(e.target.value)}
         disabled={isDisabled}
         onWheel={(e) => e.target.blur()}
        ></Form.Control>
       </Form.Group>
      </Col>
      <Col md={4}>
       <Form.Group controlId="bpjsKesehatanDescription">
        <Form.Label>BPJS Kesehatan Description</Form.Label>
        <Form.Control
         placeholder="Enter BPJS Kesehatan Description"
         value={bpjsKesehatanDescription || ""} // Gunakan string kosong jika nilai null
         onChange={(e) => setBpjsKesehatanDescription(e.target.value)}
         disabled={isDisabled}
         as="textarea"
         rows={4}
        ></Form.Control>
       </Form.Group>
      </Col>
     </Row>
     <Row className="mt-3">
      <Col md={3}>
       <h4 className="underline">BPJS Ketenagakerjaan</h4>
      </Col>
     </Row>
     <Row>
      <Col md={3}>
       <Form.Group controlId="bpjsKetenagakerjaan">
        <Form.Label>No. BPJS Ketenagakerjaan</Form.Label>
        <Form.Control
         type="number"
         placeholder="Enter BPJS Ketenagakerjaan"
         value={bpjsKetenagakerjaan}
         onChange={(e) => setBpjsKetenagakerjaan(e.target.value)}
         disabled={isDisabled}
         onWheel={(e) => e.target.blur()}
        ></Form.Control>
       </Form.Group>
      </Col>
      <Col md={4}>
       <Form.Group controlId="bpjsKetenagakerjaanDescription">
        <Form.Label>BPJS Ketenagakerjaan Description</Form.Label>
        <Form.Control
         placeholder="Enter BPJS Ketenagakerjaan Description"
         value={bpjsKetenagakerjaanDescription || ""} // Gunakan string kosong jika nilai null
         onChange={(e) => setBpjsKetenagakerjaanDescription(e.target.value)}
         disabled={isDisabled}
         as="textarea"
         rows={4}
        ></Form.Control>
       </Form.Group>
      </Col>
     </Row>
    </>
   )}
   {stringIncludes(tab, "bank") && (
    <>
     <Row className="mt-3">
      <Col md={3}>
       <h4 className="underline">Bank</h4>
      </Col>
     </Row>
     <Row>
      <FormSelect
       formLabel="Bank Name"
       controlId="bank"
       value={bank || ""}
       onChange={(e) => setBank(e.target.value)}
       options={banks || ""}
       disabled={isDisabled}
      ></FormSelect>
      <Col md={3}>
       <Form.Group controlId="bankAccount">
        <Form.Label>Bank Account / No. Rekening</Form.Label>
        <Form.Control
         type="number"
         value={bankAccount}
         onChange={(e) => setBankAccount(e.target.value)}
         disabled={isDisabled}
         onWheel={(e) => e.target.blur()}
        ></Form.Control>
       </Form.Group>
      </Col>
     </Row>
    </>
   )}
  </>
 );
};

export default EmployeeDetailsView;
