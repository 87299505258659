import React from "react";
import { Col, Form } from "react-bootstrap";
import { defaultOptions } from "../../data/etc";
import { capitalizeEachWord } from "../../utils/utils";

const FormSelect = ({
 formLabel = "",
 controlId = "",
 value = "",
 onChange = () => {},
 options = [],
 disabled = false,
 defaultLabel = true,
 customLabel = "",
}) => {
 if (!Array.isArray(options) || !options || !options.length || !controlId) {
  return "";
 }

 return (
  <Col md="auto">
   <Form.Group controlId={controlId}>
    {formLabel && <Form.Label>{formLabel}</Form.Label>}
    <Form.Select
     value={value}
     onChange={onChange}
     style={{
      color: "white",
      backgroundColor: "#dc3545",
     }}
     disabled={disabled}
    >
     {options && defaultLabel && !customLabel && (
      <option key={defaultOptions} value={""}>
       {defaultOptions}
      </option>
     )}
     {options && !defaultLabel && customLabel && (
      <option key={customLabel} value={customLabel}>
       {capitalizeEachWord(customLabel)}
      </option>
     )}
     {options &&
      options.map(
       (item) =>
        item?.name &&
        item?.label && (
         <option key={item.name} value={item.name}>
          {item.label}
         </option>
        )
      )}
    </Form.Select>
   </Form.Group>
  </Col>
 );
};

export default FormSelect;
