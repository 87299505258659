import {
 SHEET_LIST_REQUEST,
 SHEET_LIST_SUCCESS,
 SHEET_LIST_ERROR,
 SHEET_ADD_ERROR,
 SHEET_ADD_REQUEST,
 SHEET_ADD_SUCCESS,
 SHEET_UPDATE_ERROR,
 SHEET_UPDATE_REQUEST,
 SHEET_UPDATE_SUCCESS,
 SHEET_UPDATE_RESET,
 SHEET_DELETE_ERROR,
 SHEET_DELETE_REQUEST,
 SHEET_DELETE_SUCCESS,
 SHEET_DELETE_RESET,
 SHEET_DETAILS_REQUEST,
 SHEET_DETAILS_SUCCESS,
 SHEET_DETAILS_ERROR,
} from "../constants/sheetConstant";

export const sheetListReducer = (state = { sheets: [] }, action) => {
 switch (action.type) {
  case SHEET_LIST_REQUEST:
   return { ...state, loading: true };
  case SHEET_LIST_SUCCESS:
   return {
    loading: false,
    sheets: action.payload.sheets,
    pages: action.payload.pages,
    page: action.payload.page,
   };
  case SHEET_LIST_ERROR:
   return { loading: false, error: action.payload };

  default:
   return state;
 }
};

export const sheetAddReducer = (state = { sheets: {} }, action) => {
 switch (action.type) {
  case SHEET_ADD_REQUEST:
   return { ...state, loading: true };
  case SHEET_ADD_SUCCESS:
   return { loading: false, success: true };
  case SHEET_ADD_ERROR:
   return { loading: false, error: action.payload };

  default:
   return state;
 }
};

export const sheetDetailsReducer = (state = {}, action) => {
 switch (action.type) {
  case SHEET_DETAILS_REQUEST:
   return { ...state, loading: true };
  case SHEET_DETAILS_SUCCESS:
   return { loading: false, sheet: action.payload };
  case SHEET_DETAILS_ERROR:
   return { loading: false, error: action.payload };

  default:
   return state;
 }
};

export const sheetUpdateReducer = (state = {}, action) => {
 switch (action.type) {
  case SHEET_UPDATE_REQUEST:
   return { ...state, loading: true };
  case SHEET_UPDATE_SUCCESS:
   return { loading: false, success: true };
  case SHEET_UPDATE_ERROR:
   return { loading: false, error: action.payload };
  case SHEET_UPDATE_RESET:
   return {};

  default:
   return state;
 }
};

export const sheetDeleteReducer = (state = {}, action) => {
 switch (action.type) {
  case SHEET_DELETE_REQUEST:
   return { ...state, loading: true };
  case SHEET_DELETE_SUCCESS:
   return { loading: false, success: true };
  case SHEET_DELETE_ERROR:
   return { loading: false, error: action.payload };
  case SHEET_DELETE_RESET:
   return {};

  default:
   return state;
 }
};
