/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../components/loader.jsx";
import Message from "../components/message.jsx";
import { listEmployees } from "../actions/employeeActions.jsx";
import { Route, useLocation } from "react-router-dom";
import SearchBox from "../components/searchBox.jsx";
import {
 Row,
 Col,
 Container,
 Form,
 ToggleButtonGroup,
 ToggleButton,
} from "react-bootstrap";
import { TooltipIcon } from "../components/tooltip.jsx";
import {
 capitalizeEachWord,
 options,
 countActiveEmployee,
 countStatusEmployee,
 stringIncludes,
} from "../utils/utils.jsx";
import dayjs from "dayjs";
import { getSheetDetails } from "../actions/sheetActions.jsx";
import { getAnalysisAttendanceSummaryByDate } from "../actions/attendanceSummaryActions.jsx";
import AddAttendanceTableView from "./addAttendanceTableView.jsx";
import { Toggle } from "rsuite";
import AttendanceExportToExcel from "../components/attendanceExportToExcel.jsx";
import { DatePicker } from "rsuite";
import verifiedIcon from "../Assets/icon/verified.png";
import { logout } from "../actions/userActions.jsx";
import { outlets } from "../data/outlet.jsx";
import FormSelect from "../components/select/formSelect";
import { getAllOutlet } from "../actions/outletActions";

const AddAttendanceView = ({ match, history }) => {
 const existingSettings = JSON.parse(localStorage.getItem("settings")) || {};

 const location = useLocation(); // Added for reading the URL
 const userLogin = useSelector((state) => state.userLogin);
 const { loading: userLoading, userInfo } = userLogin;
 const [isActiveEmployee, setIsActiveEmployee] = useState(true);
 const [outlet, setOutlet] = useState(
  new URLSearchParams(location.search).get("outlet") ||
   existingSettings?.attendance?.outlet ||
   userInfo.outlet
 );
 //console.log(`userInfo: ${JSON.stringify(userInfo)}`);
 //console.log(`userInfo.outlet: ${userInfo.outlet}`);

 const lastMonth = dayjs().subtract(1, "month")?.month();
 const thisMonth = dayjs()?.month();
 const [month, setMonth] = useState(
  dayjs().get("date") > 15 ? thisMonth : lastMonth
 );

 //console.log(`month: ${month}\nday:${dayjs().get("date")}`);
 const [date, setDate] = useState(
  dayjs(`${dayjs().year()}-${month}-26`)?.startOf("day").toDate()
 );

 const attendanceSummaryAnalysis = useSelector(
  (state) => state.attendanceSummaryAnalysis
 );
 const { loading, error, attendanceSummary } = attendanceSummaryAnalysis;

 const attendanceDataEnter = useSelector((state) => state.attendanceDataEnter);
 const { loading: addAttendanceLoading } = attendanceDataEnter;

 const keyword = match.params.keyword;

 const dispatch = useDispatch();

 const sheetDetails = useSelector((state) => state.sheetDetails);
 const { sheet } = sheetDetails;
 const spreadsheetNameToImport = "sheetImportCuti";

 const outletList = useSelector((state) => state.outletList);
 const { loading: loadingOutlets, error: errorOutlets, outlets } = outletList;

 const handleIsActiveEmployee = (value) => {
  setIsActiveEmployee(value);

  // Update the URL with the selected outlet
  const searchParams = new URLSearchParams(location.search);
  searchParams.set("isActiveEmployee", value);
  history.push({ search: searchParams.toString() });
 };

 const handleOutletChange = (e) => {
  const selectedOutlet = e.target.value;
  setOutlet(selectedOutlet);

  // Update the URL with the selected outlet
  const searchParams = new URLSearchParams(location.search);
  searchParams.set("outlet", selectedOutlet);
  history.push({ search: searchParams.toString() });
 };

 const handleDateChange = (date) => {
  if (!date) return;

  const newDate = dayjs(date);

  setDate(
   dayjs(`${newDate.format("YYYY-MM")}-26`)
    .startOf("day")
    .toDate()
  );

  const searchParams = new URLSearchParams(location.search);
  searchParams.set("date", newDate.format("YYYY-MM-26"));
  history.push({ search: searchParams.toString() });
 };

 // eslint-disable-next-line
 useEffect(() => {
  if ((!userLoading && !userInfo) || stringIncludes(error, "not authorized")) {
   dispatch(logout());
   history.push("/login");
  }
  dispatch(getSheetDetails(spreadsheetNameToImport));
  dispatch(
   getAnalysisAttendanceSummaryByDate(isActiveEmployee, keyword, outlet, date)
  );
  // Update outlet state from URL if present
  const searchParams = new URLSearchParams(location.search);
  const outletParam = searchParams.get("outlet");
  if (outletParam) {
   setOutlet(outletParam); // Set the outlet from URL
  }
  const isActiveEmployeeParam = searchParams.get("isActiveEmployee");
  if (isActiveEmployeeParam === "false") {
   setIsActiveEmployee(false); // Set the outlet from URL
  } else if (isActiveEmployeeParam === "true") {
   setIsActiveEmployee(true); // Set the outlet from URL
  }

  const dateParam = searchParams.get("date");
  if (dateParam) {
   const newDate = dayjs(dateParam);
   if (!newDate.isSame(dayjs(date), "month")) {
    setDate(newDate.startOf("day").toDate());
   }
  }

  if (!loadingOutlets && Array.isArray(outlets) && !outlets.length) {
   dispatch(getAllOutlet());
  }
  //console.log(outlets);

  if (outlet) {
   const settings = JSON.parse(localStorage.getItem("settings")) || {};
   // Tambahkan/ubah properti pada settings
   const updatedSettings = {
    ...settings, // Tetap pertahankan properti yang sudah ada
    attendance: {
     ...settings.attendance, // Pertahankan properti dalam sideNav jika ada
     outlet, // Tambahkan atau ubah properti expand
    },
   };

   // Simpan settings yang sudah diperbarui ke localStorage
   localStorage.setItem("settings", JSON.stringify(updatedSettings));
  }
 }, [
  userLoading,
  userInfo,
  spreadsheetNameToImport,
  outlet,
  location,
  history,
  date,
  addAttendanceLoading,
 ]);

 return (
  <>
   <Container>
    <Row style={{ justifyContent: "center" }}>
     <Col md="auto">
      <h3>ATTENDANCE</h3>
     </Col>
    </Row>
   </Container>
   <Row>
    <Col>
     <h5>{`${capitalizeEachWord(outlet)}`}</h5>
    </Col>
    <Col className="d-flex justify-content-end">
     <AttendanceExportToExcel
      attendanceData={attendanceSummary}
      startDate={date}
      outlet={outlet}
      isActiveEmployee={isActiveEmployee}
     />
    </Col>
   </Row>
   <Row>
    <Col md="auto">
     <h6>{`${dayjs(date).format("DD MMMM YYYY")} - ${dayjs(date)
      .add(1, "month")
      .subtract(1, "day")
      .format("DD MMMM YYYY")}`}</h6>
    </Col>
   </Row>
   <Row>
    <Col></Col>
   </Row>

   <Row>
    <Col md="auto">
     <ToggleButtonGroup type="radio" name="options">
      <ToggleButton
       key="isActiveEmployee"
       id="isActiveEmployeeRadio1"
       value={true}
       name="radio"
       onChange={(e) => handleIsActiveEmployee(e.target.value)}
       style={{
        backgroundColor: isActiveEmployee === true ? "#dc3545" : "#6c757d",
        border: "none", // Remove the outline
       }}
      >
       Active
      </ToggleButton>
      <ToggleButton
       key="resignEmployee"
       id="isActiveEmployeeRadio2"
       value={false}
       name="radio"
       onChange={(e) => handleIsActiveEmployee(e.target.value)}
       style={{
        backgroundColor: isActiveEmployee === false ? "#dc3545" : "#6c757d",
        border: "none", // Remove the outline
       }}
      >
       Resigned
      </ToggleButton>
     </ToggleButtonGroup>
    </Col>
    <Col md="auto" xs="auto">
     <Form>
      {userInfo.isAdmin || userInfo.isCompanyLeader ? (
       <FormSelect
        controlId="outlet"
        value={outlet || ""}
        onChange={handleOutletChange}
        options={outlets || ""}
        defaultLabel={false}
       />
      ) : (
       <></>
      )}
     </Form>
    </Col>
    <Col>
     <DatePicker
      format="MMMM yyyy"
      placeholder={date ? dayjs(date).format("MMMM YYYY") : ""}
      onChange={handleDateChange}
      showMeridian={false}
      ranges={[]}
      //defaultValue={date ? dayjs(date).startOf("day").toDate() : null} // Ensure this is a dayjs object
     />
    </Col>

    <Col md="auto">
     <Route
      render={({ history }) => (
       <SearchBox
        history={history}
        pageName={"attendance"}
        keyword={keyword}
        outlet={outlet}
        isActiveEmployee={isActiveEmployee}
        date={date}
        userInfo={userInfo}
       />
      )}
     />
    </Col>
   </Row>

   {sheet ? (
    <Row style={{ fontSize: "80%" }}>
     <Col md="auto">Last updated employee:</Col>
     <Col md="auto">{sheet.employeeLastUpdatedName}</Col>
     <Col md="auto">
      {"at "}
      {sheet.employeeLastUpdatedAt
       ? dayjs(sheet.employeeLastUpdatedAt).format("D-MMM-YYYY HH:mm:ss wib")
       : null}
     </Col>

     <Col md="auto">
      <i
       className="fas fa-user"
       style={{ color: "green", paddingRight: "2px" }}
      ></i>
      <strong style={{ paddingRight: "2px" }}>{`Active:${countActiveEmployee(
       attendanceSummary,
       true
      )} | `}</strong>
      <i
       className="fas fa-sign-out-alt"
       style={{ color: "red", paddingRight: "2px" }}
      ></i>
      <strong>{`Resigned:${countActiveEmployee(
       attendanceSummary,
       false
      )}`}</strong>
     </Col>
     <Col md="auto">
      <img
       alt="Verified"
       src={verifiedIcon}
       style={{ width: "18px", height: "18px" }}
      />
      <strong style={{ paddingRight: "2px" }}>{`Tetap:${countStatusEmployee(
       attendanceSummary,
       true
      )} | `}</strong>
      <i
       className="fas fa-user-clock"
       style={{ color: "gray", paddingRight: "2px" }}
      ></i>
      <strong>{`Probation:${countStatusEmployee(
       attendanceSummary,
       false
      )}`}</strong>
     </Col>
    </Row>
   ) : (
    <></>
   )}
   {loading || addAttendanceLoading || loadingOutlets ? (
    <Loading />
   ) : error || errorOutlets ? (
    <Message variant="danger">{error}</Message>
   ) : (
    <>
     {
      <AddAttendanceTableView
       keyword={keyword}
       isActiveEmployee={isActiveEmployee}
       outlet={outlet}
       date={date}
       userInfo={userInfo}
      />
     }
    </>
   )}
  </>
 );
};

export default AddAttendanceView;
