import React from "react";
import { Card } from "react-bootstrap";
import Outlet from "../employee/outlet.jsx";
import Position from "../employee/position.jsx";
import DateIn from "../employee/dateIn.jsx";
import Age from "../employee/age.jsx";
import WorkDuration from "../employee/workDuration.jsx";
import Contact from "../employee/contact.jsx";

const CardPrimary = ({ employee, className = "justify-content-md-center" }) => {
 if (!employee) return "";

 return (
  <Card
   className="justify-content-center normal-card"
   style={{ textAlign: "center", maxWidth: "500px" }}
  >
   <Card.Body>
    <Outlet outlet={employee.outlet} className={className} />
    <Position position={employee.position} className={className} />
    <DateIn
     dateIn={employee.dateIn}
     dateResign={employee.dateResign}
     className={className}
    />
    <Age dateOfBirth={employee.dateOfBirth} className={className} />
    <WorkDuration
     dateIn={employee.dateIn}
     dateResign={employee.dateResign}
     className={className}
    />
    <Contact
     phone={employee.phone}
     email={employee.email}
     ukuranBaju={employee.ukuranBaju}
     isWaSquare={true}
     emailColor="gray"
     className={className}
    />
   </Card.Body>
  </Card>
 );
};

export default CardPrimary;
