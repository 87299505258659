import {
 GOOGLESHEET_EXECUTE_REQUEST,
 GOOGLESHEET_EXECUTE_SUCCESS,
 GOOGLESHEET_EXECUTE_ERROR,
} from "../constants/googlesheetConstant";
import axios from "axios";

export const executeGoogle = () => async (dispatch, getState) => {
 try {
  dispatch({ type: GOOGLESHEET_EXECUTE_REQUEST });
  const {
   userLogin: { userInfo },
  } = getState();
  const config = {
   headers: {
    Authorization: `Bearer ${userInfo.token}`,
   },
  };

  const { data } = await axios.get(`/employee/leave/update`, config);

  dispatch({
   type: GOOGLESHEET_EXECUTE_SUCCESS,
   payload: data,
  });
 } catch (error) {
  dispatch({
   type: GOOGLESHEET_EXECUTE_ERROR,
   payload:
    error.response && error.response.data.message
     ? error.response.data.message
     : error.message,
  });
 }
};
