import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Form, Button, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/message";
import Loader from "../components/loader";
import FormContainer from "../components/formContainer";
import { getUserDetails, updateUser } from "../actions/userActions";
import { USER_UPDATE_RESET } from "../constants/userConstants";
import { logout } from "../actions/userActions";
import { stringIncludes } from "../utils/utils";
import dayjs from "dayjs";

const UserEditView = ({ match, history }) => {
 const userId = match.params.userId;

 const [employeeID, setEmployeeID] = useState("");
 const [name, setName] = useState("");
 const [email, setEmail] = useState("");
 const [isAdmin, setIsAdmin] = useState(false);
 const [isOutletLeader, setIsOutletLeader] = useState(false);
 const [isCompanyLeader, setIsCompanyLeader] = useState(false);
 const [outlet, setOutlet] = useState("");
 const [place, setPlace] = useState("");

 const dispatch = useDispatch();

 const userLogin = useSelector((state) => state.userLogin);
 const { loading: userLoading, userInfo } = userLogin;

 const userDetails = useSelector((state) => state.userDetails);
 const { loading, error, user } = userDetails;

 const userUpdate = useSelector((state) => state.userUpdate);
 const {
  loading: loadingUpdate,
  error: errorUpdate,
  success: successUpdate,
 } = userUpdate;

 useEffect(() => {
  if (
   (!userLoading && !userInfo) ||
   stringIncludes(error, "not authorized") ||
   stringIncludes(errorUpdate, "not authorized")
  ) {
   dispatch(logout());
   history.push("/login");
  }
  if (successUpdate) {
   dispatch({ type: USER_UPDATE_RESET });
   history.push("/userList");
  } else {
   if (!user || !user.name || user._id !== userId) {
    dispatch(getUserDetails(userId));
   } else {
    setEmployeeID(user.employeeID);
    setName(user.name);
    setEmail(user.email);
    setIsAdmin(user.isAdmin);
    setIsOutletLeader(user.isOutletLeader);
    setIsCompanyLeader(user.isCompanyLeader);
    setOutlet(user.outlet);
    setPlace(user.place);
   }
  }
 }, [
  dispatch,
  history,
  userId,
  user,
  successUpdate,
  userLoading,
  userInfo,
  error,
  errorUpdate,
 ]);

 const submitHandler = (e) => {
  e.preventDefault();
  dispatch(
   updateUser({
    _id: userId,
    employeeID,
    name,
    email,
    isAdmin,
    isOutletLeader,
    isCompanyLeader,
    outlet,
    place,
   })
  );
 };

 return (
  <>
   <Link to="/userList" className="btn btn-light my-3">
    Go Back
   </Link>
   {loadingUpdate && <Loader />}
   {errorUpdate && <Message variant="danger">{errorUpdate}</Message>}
   {loading ? (
    <Loader />
   ) : error ? (
    <Message variant="danger">{error}</Message>
   ) : (
    <FormContainer>
     <h1>Edit User</h1>
     <Row>
      <Form.Label>
       {"User last updated at "}
       {user.updatedAt && dayjs(user.updatedAt).format("DD-MMM-YYYY HH:mm wib")}
      </Form.Label>
     </Row>
     <Row>
      <Form.Label>
       {"User created at "}
       {user.createdAt && dayjs(user.createdAt).format("DD-MMM-YYYY HH:mm wib")}
      </Form.Label>
     </Row>

     <Form onSubmit={submitHandler}>
      <Form.Group controlId="employeeID">
       <Form.Label>Employee ID</Form.Label>
       <Form.Control
        type="employeeID"
        placeholder="Enter employee ID"
        value={employeeID}
        onChange={(e) => setEmployeeID(e.target.value)}
       ></Form.Control>
      </Form.Group>

      <Form.Group controlId="name">
       <Form.Label>Name</Form.Label>
       <Form.Control
        type="name"
        placeholder="Enter name"
        value={name}
        onChange={(e) => setName(e.target.value)}
       ></Form.Control>
      </Form.Group>

      <Form.Group controlId="email">
       <Form.Label>Email Address</Form.Label>
       <Form.Control
        type="email"
        placeholder="Enter email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
       ></Form.Control>
      </Form.Group>

      <Form.Group controlId="outlet">
       <Form.Label>Outlet</Form.Label>
       <Form.Control
        type="text"
        placeholder="Enter outlet"
        value={outlet}
        onChange={(e) => setOutlet(e.target.value)}
       ></Form.Control>
      </Form.Group>

      <Form.Group controlId="place">
       <Form.Label>Place</Form.Label>
       <Form.Control
        type="text"
        placeholder="Enter place"
        value={place}
        onChange={(e) => setPlace(e.target.value)}
       ></Form.Control>
      </Form.Group>

      <Form.Group controlId="isadmin" className="mt-3">
       <Form.Check
        type="checkbox"
        label="Is Admin"
        checked={isAdmin}
        onChange={(e) => setIsAdmin(e.target.checked)}
       ></Form.Check>
      </Form.Group>

      <Form.Group controlId="isOutletLeader">
       <Form.Check
        type="checkbox"
        label="Is Outlet Leader"
        checked={isOutletLeader}
        onChange={(e) => setIsOutletLeader(e.target.checked)}
       ></Form.Check>
      </Form.Group>

      <Form.Group controlId="isCompanyLeader">
       <Form.Check
        type="checkbox"
        label="Is Company Leader"
        checked={isCompanyLeader}
        onChange={(e) => setIsCompanyLeader(e.target.checked)}
       ></Form.Check>
      </Form.Group>

      <Button type="submit" variant="danger" className="mt-3 mb-3">
       Update
      </Button>
     </Form>
    </FormContainer>
   )}
  </>
 );
};

export default UserEditView;
