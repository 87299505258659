import React from "react";

const IconStarRating = ({ employee }) => {
 if (!employee || !employee.grade) {
  return <></>;
 }
 const grade = Math.round(employee.grade) || 0;
 if (!grade) {
  return <></>;
 }

 // Buat array dengan panjang sesuai jumlah grade, lalu map menjadi icon bintang
 const stars = Array(grade)
  .fill(0)
  .map((_, index) => (
   <i
    key={index}
    className="fas fa-star"
    style={{ color: "gold", fontSize: "9px" }}
   ></i>
  ));

 return <>{stars}</>;
};

export default IconStarRating;
