import {
 BANK_LIST_REQUEST,
 BANK_LIST_SUCCESS,
 BANK_LIST_ERROR,
 BANK_ADD_ERROR,
 BANK_ADD_REQUEST,
 BANK_ADD_SUCCESS,
 BANK_DELETE_ERROR,
 BANK_DELETE_REQUEST,
 BANK_DELETE_SUCCESS,
 BANK_UPDATE_ERROR,
 BANK_UPDATE_REQUEST,
 BANK_UPDATE_SUCCESS,
 BANK_DETAILS_REQUEST,
 BANK_DETAILS_SUCCESS,
 BANK_DETAILS_ERROR,
} from "../constants/bankConstant";
import axios from "axios";
import { getAxiosTargetPath } from "../utils/utils";
import { logout } from "./userActions";

export const listBanks = () => async (dispatch, getState) => {
 try {
  dispatch({ type: BANK_LIST_REQUEST });
  const {
   userLogin: { userInfo },
  } = getState();
  const config = {
   headers: {
    Authorization: `Bearer ${userInfo.token}`,
   },
  };
  const axiosPath = getAxiosTargetPath(`/bank/all`);
  const { data } = await axios.get(axiosPath, config);
  dispatch({
   type: BANK_LIST_SUCCESS,
   payload: data,
  });
 } catch (error) {
  const message =
   error.response && error.response.data.message
    ? error.response.data.message
    : error.message;
  if (message === "Not authorized, token failed") {
   dispatch(logout());
  }
  dispatch({
   type: BANK_LIST_ERROR,
   payload: message,
  });
 }
};

export const addBank = (bank) => async (dispatch, getState) => {
 try {
  dispatch({ type: BANK_ADD_REQUEST });
  const {
   userLogin: { userInfo },
  } = getState();
  const config = {
   headers: {
    Authorization: `Bearer ${userInfo.token}`,
   },
  };

  const { data } = await axios.post(`/bank/addBank`, bank, config);

  dispatch({
   type: BANK_ADD_SUCCESS,
   payload: data,
  });
 } catch (error) {
  dispatch({
   type: BANK_ADD_ERROR,
   payload:
    error.response && error.response.data.message
     ? error.response.data.message
     : error.message,
  });
 }
};

export const getBankDetails = (id) => async (dispatch, getState) => {
 try {
  dispatch({ type: BANK_DETAILS_REQUEST });
  const {
   userLogin: { userInfo },
  } = getState();
  const config = {
   headers: {
    Authorization: `Bearer ${userInfo.token}`,
   },
  };

  const { data } = await axios.get(`/bank/${id}`, config);

  dispatch({
   type: BANK_DETAILS_SUCCESS,
   payload: data,
  });
 } catch (error) {
  dispatch({
   type: BANK_DETAILS_ERROR,
   payload:
    error.response && error.response.data.message
     ? error.response.data.message
     : error.message,
  });
 }
};

export const getBankDetailsByBankId =
 (bankID) => async (dispatch, getState) => {
  try {
   dispatch({ type: BANK_DETAILS_REQUEST });
   const {
    userLogin: { userInfo },
   } = getState();
   const config = {
    headers: {
     Authorization: `Bearer ${userInfo.token}`,
    },
   };

   const { data } = await axios.get(`/bank/user/${bankID}`, config);

   dispatch({
    type: BANK_DETAILS_SUCCESS,
    payload: data,
   });
  } catch (error) {
   dispatch({
    type: BANK_DETAILS_ERROR,
    payload:
     error.response && error.response.data.message
      ? error.response.data.message
      : error.message,
   });
  }
 };

export const updateBank = (bank) => async (dispatch, getState) => {
 try {
  dispatch({ type: BANK_UPDATE_REQUEST });
  const {
   userLogin: { userInfo },
  } = getState();
  const config = {
   headers: {
    Authorization: `Bearer ${userInfo.token}`,
   },
  };
  const { data } = await axios.put(`/bank/${bank._id}`, bank, config);

  dispatch({
   type: BANK_UPDATE_SUCCESS,
   payload: data,
  });
 } catch (error) {
  dispatch({
   type: BANK_UPDATE_ERROR,
   payload:
    error.response && error.response.data.message
     ? error.response.data.message
     : error.message,
  });
 }
};

export const deleteBank = (id) => async (dispatch, getState) => {
 try {
  dispatch({ type: BANK_DELETE_REQUEST });
  const {
   userLogin: { userInfo },
  } = getState();
  const config = {
   headers: {
    Authorization: `Bearer ${userInfo.token}`,
   },
  };

  const { data } = await axios.delete(`/bank/${id}`, config);

  dispatch({
   type: BANK_DELETE_SUCCESS,
   payload: data,
  });
 } catch (error) {
  dispatch({
   type: BANK_DELETE_ERROR,
   payload:
    error.response && error.response.data.message
     ? error.response.data.message
     : error.message,
  });
 }
};
