import {
 STATUS_LIST_REQUEST,
 STATUS_LIST_SUCCESS,
 STATUS_LIST_ERROR,
 STATUS_ADD_ERROR,
 STATUS_ADD_REQUEST,
 STATUS_ADD_SUCCESS,
 STATUS_UPDATE_ERROR,
 STATUS_UPDATE_REQUEST,
 STATUS_UPDATE_SUCCESS,
 STATUS_UPDATE_RESET,
 STATUS_DELETE_ERROR,
 STATUS_DELETE_REQUEST,
 STATUS_DELETE_SUCCESS,
 STATUS_DELETE_RESET,
 STATUS_DETAILS_REQUEST,
 STATUS_DETAILS_SUCCESS,
 STATUS_DETAILS_ERROR,
} from "../constants/statusConstant";

export const statusListReducer = (state = { status: [] }, action) => {
 switch (action.type) {
  case STATUS_LIST_REQUEST:
   return { ...state, loading: true };
  case STATUS_LIST_SUCCESS:
   return {
    loading: false,
    status: action.payload.status,
   };
  case STATUS_LIST_ERROR:
   return { loading: false, error: action.payload };

  default:
   return state;
 }
};

export const statusAddReducer = (state = { status: {} }, action) => {
 switch (action.type) {
  case STATUS_ADD_REQUEST:
   return { ...state, loading: true };
  case STATUS_ADD_SUCCESS:
   return { loading: false, success: true };
  case STATUS_ADD_ERROR:
   return { loading: false, error: action.payload };

  default:
   return state;
 }
};

export const statusDetailsReducer = (state = {}, action) => {
 switch (action.type) {
  case STATUS_DETAILS_REQUEST:
   return { ...state, loading: true };
  case STATUS_DETAILS_SUCCESS:
   return { loading: false, status: action.payload };
  case STATUS_DETAILS_ERROR:
   return { loading: false, error: action.payload };

  default:
   return state;
 }
};

export const statusUpdateReducer = (state = {}, action) => {
 switch (action.type) {
  case STATUS_UPDATE_REQUEST:
   return { ...state, loading: true };
  case STATUS_UPDATE_SUCCESS:
   return { loading: false, success: true };
  case STATUS_UPDATE_ERROR:
   return { loading: false, error: action.payload };
  case STATUS_UPDATE_RESET:
   return {};

  default:
   return state;
 }
};

export const statusDeleteReducer = (state = {}, action) => {
 switch (action.type) {
  case STATUS_DELETE_REQUEST:
   return { ...state, loading: true };
  case STATUS_DELETE_SUCCESS:
   return { loading: false, success: true };
  case STATUS_DELETE_ERROR:
   return { loading: false, error: action.payload };
  case STATUS_DELETE_RESET:
   return {};

  default:
   return state;
 }
};
