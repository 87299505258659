import React from "react";
import { Row, Col } from "react-bootstrap";
import { getAge } from "../../utils/utils";

const Age = ({ dateOfBirth, className = "justify-content-md-center" }) => {
 if (!dateOfBirth) return "";

 return (
  <Row className={className}>
   <Col md="auto" className="d-flex justify-content-center">
    <i
     className="fas fa-seedling"
     style={{ color: "green", marginRight: "5px" }}
    ></i>
    {getAge(dateOfBirth, false, true)}
   </Col>
  </Row>
 );
};

export default Age;
