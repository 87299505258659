import {
 GOOGLESHEET_LIST_REQUEST,
 GOOGLESHEET_LIST_SUCCESS,
 GOOGLESHEET_LIST_ERROR,
 GOOGLESHEET_ADD_ERROR,
 GOOGLESHEET_ADD_REQUEST,
 GOOGLESHEET_ADD_SUCCESS,
 GOOGLESHEET_UPDATE_ERROR,
 GOOGLESHEET_UPDATE_REQUEST,
 GOOGLESHEET_UPDATE_SUCCESS,
 GOOGLESHEET_UPDATE_RESET,
 GOOGLESHEET_DETAILS_REQUEST,
 GOOGLESHEET_DETAILS_SUCCESS,
 GOOGLESHEET_DETAILS_ERROR,
 GOOGLESHEET_EXECUTE_REQUEST,
 GOOGLESHEET_EXECUTE_SUCCESS,
 GOOGLESHEET_EXECUTE_ERROR,
} from "../constants/googlesheetConstant";

export const googlesheetListReducer = (state = { employees: [] }, action) => {
 switch (action.type) {
  case GOOGLESHEET_LIST_REQUEST:
   return { ...state, loading: true };
  case GOOGLESHEET_LIST_SUCCESS:
   return {
    loading: false,
    employees: action.payload.employees,
    pages: action.payload.pages,
    page: action.payload.page,
   };
  case GOOGLESHEET_LIST_ERROR:
   return { loading: false, error: action.payload };

  default:
   return state;
 }
};

export const googlesheetAddReducer = (state = { employees: {} }, action) => {
 switch (action.type) {
  case GOOGLESHEET_ADD_REQUEST:
   return { ...state, loading: true };
  case GOOGLESHEET_ADD_SUCCESS:
   return { loading: false, success: true };
  case GOOGLESHEET_ADD_ERROR:
   return { loading: false, error: action.payload };

  default:
   return state;
 }
};

export const googlesheetDetailsReducer = (state = {}, action) => {
 switch (action.type) {
  case GOOGLESHEET_DETAILS_REQUEST:
   return { ...state, loading: true };
  case GOOGLESHEET_DETAILS_SUCCESS:
   return { loading: false, googlesheet: action.payload };
  case GOOGLESHEET_DETAILS_ERROR:
   return { loading: false, error: action.payload };

  default:
   return state;
 }
};

export const googlesheetExecuteReducer = (state = {}, action) => {
 switch (action.type) {
  case GOOGLESHEET_EXECUTE_REQUEST:
   return { ...state, loading: true };
  case GOOGLESHEET_EXECUTE_SUCCESS:
   return { loading: false, isExecuteGoogleFinished: action.payload };
  case GOOGLESHEET_EXECUTE_ERROR:
   return { loading: false, error: action.payload };

  default:
   return state;
 }
};

export const googlesheetUpdateReducer = (state = {}, action) => {
 switch (action.type) {
  case GOOGLESHEET_UPDATE_REQUEST:
   return { ...state, loading: true };
  case GOOGLESHEET_UPDATE_SUCCESS:
   return { loading: false, success: true };
  case GOOGLESHEET_UPDATE_ERROR:
   return { loading: false, error: action.payload };
  case GOOGLESHEET_UPDATE_RESET:
   return {};

  default:
   return state;
 }
};
