import { React } from "react";

// Fungsi untuk memformat nomor telepon
const formatPhoneNumber = (phone) => {
 // Memeriksa apakah nomor telepon berawalan "08"
 if (phone.startsWith("08")) {
  // Mengganti "08" dengan "628"
  return `628${phone.slice(2)}`;
 }
 return phone;
};

// Komponen untuk menampilkan link WhatsApp
const WhatsAppLink = ({
 phone,
 fontSize = "1.75em",
 marginLeft = 0,
 marginRight = 0,
 isSquare = false,
}) => {
 if (!phone) return "";

 // Memformat nomor telepon
 const formattedPhoneNumber = formatPhoneNumber(phone);
 const whatsappUrl = `https://wa.me/${formattedPhoneNumber}`;

 return (
  <a href={whatsappUrl} target="_blank" rel="noopener noreferrer">
   <i
    className={`fab fa-whatsapp${isSquare ? "-square" : ""}`}
    style={{
     fontSize: fontSize,
     color: "#25D366",
     marginLeft: marginLeft,
     marginRight: marginRight,
    }}
   />
  </a>
 );
};

export { WhatsAppLink };
