import React, { useEffect } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Table, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/message";
import Loader from "../components/loader";
import { listUsers, deleteUser } from "../actions/userActions";
import { TooltipIcon } from "../components/tooltip";
import { logout } from "../actions/userActions";
import { stringIncludes } from "../utils/utils";
import dayjs from "dayjs";

const UserListView = ({ history }) => {
 const dispatch = useDispatch();

 const userList = useSelector((state) => state.userList);
 const { loading, error, users } = userList;

 const userLogin = useSelector((state) => state.userLogin);
 const { loading: userLoading, error: userError, userInfo } = userLogin;

 const userDelete = useSelector((state) => state.userDelete);
 const { success: successDelete } = userDelete;

 useEffect(() => {
  if (
   (!userLoading && !userInfo) ||
   stringIncludes(error, "not authorized") ||
   stringIncludes(userError, "not authorized")
  ) {
   dispatch(logout());
   history.push("/login");
  }
  if (userInfo.isAdmin) {
   dispatch(listUsers());
  }
 }, [
  dispatch,
  history,
  successDelete,
  userInfo,
  userLoading,
  error,
  userError,
 ]);

 const deleteHandler = (id) => {
  if (window.confirm("Are you sure")) {
   dispatch(deleteUser(id));
  }
 };

 return (
  <>
   <h3>Users</h3>
   {loading ? (
    <Loader />
   ) : error ? (
    <Message variant="danger">{error}</Message>
   ) : (
    <Table striped bordered hover size="sm" className="custom-table">
     <thead className="sticky-header">
      <tr>
       <th>Date Created</th>
       <th>Last Updated</th>
       <th>Last Logged In</th>
       <th>Last Seen</th>
       <th>Employee ID</th>
       <th>NAME</th>
       <th>EMAIL</th>
       <th>
        ADMIN{" "}
        <TooltipIcon
         text="Admin could see all employees and edit them"
         color="white"
        />
       </th>
       <th>
        COMPANY LEADER{" "}
        <TooltipIcon
         text="Company Leader could see all employees in the same Company but cannot edit them"
         color="white"
        />
       </th>
       <th>
        OUTLET LEADER{" "}
        <TooltipIcon
         text="Outlet Leader could see all employees in the same Outlet but cannot edit them"
         color="white"
        />
       </th>
       <th>EDIT</th>
      </tr>
     </thead>
     <tbody>
      {users.map((user) => (
       <tr key={user._id}>
        <td>
         {user.createdAt && dayjs(user.createdAt).format("DD-MMM-YYYY HH:mm")}
        </td>
        <td>
         {user.updatedAt && dayjs(user.updatedAt).format("DD-MMM-YYYY HH:mm")}
        </td>
        <td>
         {user.lastLoginAt &&
          dayjs(user.lastLoginAt).format("DD-MMM-YYYY HH:mm:ss")}
        </td>
        <td>
         {user.lastSeenAt &&
          dayjs(user.lastSeenAt).format("DD-MMM-YYYY HH:mm:ss")}
        </td>
        <td>{user.employeeID}</td>
        <td>{user.name}</td>
        <td>
         <a href={`mailto:${user.email}`}>{user.email}</a>
        </td>
        <td>
         {user.isAdmin ? (
          <i className="fas fa-check" style={{ color: "green" }}></i>
         ) : (
          <i className="fas fa-times" style={{ color: "red" }}></i>
         )}
        </td>
        <td>
         {user.isCompanyLeader ? (
          <i className="fas fa-check" style={{ color: "green" }}></i>
         ) : (
          <i className="fas fa-times" style={{ color: "red" }}></i>
         )}
        </td>
        <td>
         {user.isOutletLeader ? (
          <i className="fas fa-check" style={{ color: "green" }}></i>
         ) : (
          <i className="fas fa-times" style={{ color: "red" }}></i>
         )}
        </td>
        <td>
         <LinkContainer to={`/user/${user._id}/edit`}>
          <Button variant="light" className="btn-sm">
           <i className="fas fa-edit"></i>
          </Button>
         </LinkContainer>
         <Button
          variant="danger"
          className="btn-sm"
          onClick={() => deleteHandler(user._id)}
         >
          <i className="fas fa-trash"></i>
         </Button>
        </td>
       </tr>
      ))}
     </tbody>
    </Table>
   )}
  </>
 );
};

export default UserListView;
