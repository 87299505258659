import React from "react";
import { stringIncludes } from "../utils/utils";
import iconIndustry from "../Assets/icon/industry.png";
import iconOffice from "../Assets/icon/office.png";
import iconShop from "../Assets/icon/shop.png";

const IconOutlet = ({ outlet }) => {
 if (!outlet || !iconIndustry || !iconOffice || !iconShop) {
  return <></>;
 }
 const findOutlet = () => {
  if (stringIncludes(outlet, "khalid")) {
   return iconIndustry;
  }
  if (stringIncludes(outlet, "management")) {
   return iconOffice;
  }
  return iconShop;
 };

 return (
  <div
   className="logo-container"
   style={{ position: "relative", top: "-5px", left: "-7px" }}
  >
   <img
    src={findOutlet()}
    alt="outlet"
    style={{
     width: "20px",
     height: "20px",
    }}
   />
  </div>
 );
};

export default IconOutlet;
