import {
 SETTINGS_ADD_ERROR,
 SETTINGS_ADD_REQUEST,
 SETTINGS_ADD_SUCCESS,
 SETTINGS_UPDATE_ERROR,
 SETTINGS_UPDATE_REQUEST,
 SETTINGS_UPDATE_SUCCESS,
 SETTINGS_UPDATE_RESET,
 SETTINGS_DELETE_ERROR,
 SETTINGS_DELETE_REQUEST,
 SETTINGS_DELETE_SUCCESS,
 SETTINGS_DELETE_RESET,
 SETTINGS_DETAILS_REQUEST,
 SETTINGS_DETAILS_SUCCESS,
 SETTINGS_DETAILS_ERROR,
} from "../constants/settingsConstant";

export const settingsAddReducer = (state = { settings: {} }, action) => {
 switch (action.type) {
  case SETTINGS_ADD_REQUEST:
   return { ...state, loading: true };
  case SETTINGS_ADD_SUCCESS:
   return { loading: false, success: true };
  case SETTINGS_ADD_ERROR:
   return { loading: false, error: action.payload };

  default:
   return state;
 }
};

export const settingsDetailsReducer = (state = {}, action) => {
 switch (action.type) {
  case SETTINGS_DETAILS_REQUEST:
   return { ...state, loading: true };
  case SETTINGS_DETAILS_SUCCESS:
   return { loading: false, settings: action.payload };
  case SETTINGS_DETAILS_ERROR:
   return { loading: false, error: action.payload };

  default:
   return state;
 }
};

export const settingsUpdateReducer = (state = {}, action) => {
 switch (action.type) {
  case SETTINGS_UPDATE_REQUEST:
   return { ...state, loading: true };
  case SETTINGS_UPDATE_SUCCESS:
   return { loading: false, success: true };
  case SETTINGS_UPDATE_ERROR:
   return { loading: false, error: action.payload };
  case SETTINGS_UPDATE_RESET:
   return {};

  default:
   return state;
 }
};

export const settingsDeleteReducer = (state = {}, action) => {
 switch (action.type) {
  case SETTINGS_DELETE_REQUEST:
   return { ...state, loading: true };
  case SETTINGS_DELETE_SUCCESS:
   return { loading: false, success: true };
  case SETTINGS_DELETE_ERROR:
   return { loading: false, error: action.payload };
  case SETTINGS_DELETE_RESET:
   return {};

  default:
   return state;
 }
};
