export const SETTINGS_ADD_REQUEST = "SETTINGS_ADD_REQUEST";
export const SETTINGS_ADD_SUCCESS = "SETTINGS_ADD_SUCCESS";
export const SETTINGS_ADD_ERROR = "SETTINGS_ADD_ERROR";

export const SETTINGS_DETAILS_REQUEST = "SETTINGS_DETAILS_REQUEST";
export const SETTINGS_DETAILS_SUCCESS = "SETTINGS_DETAILS_SUCCESS";
export const SETTINGS_DETAILS_ERROR = "SETTINGS_DETAILS_ERROR";

export const SETTINGS_UPDATE_REQUEST = "SETTINGS_UPDATE_REQUEST";
export const SETTINGS_UPDATE_SUCCESS = "SETTINGS_UPDATE_SUCCESS";
export const SETTINGS_UPDATE_ERROR = "SETTINGS_UPDATE_ERROR";
export const SETTINGS_UPDATE_RESET = "SETTINGS_UPDATE_RESET";

export const SETTINGS_DELETE_REQUEST = "SETTINGS_DELETE_REQUEST";
export const SETTINGS_DELETE_SUCCESS = "SETTINGS_DELETE_SUCCESS";
export const SETTINGS_DELETE_ERROR = "SETTINGS_DELETE_ERROR";
export const SETTINGS_DELETE_RESET = "SETTINGS_DELETE_RESET";
