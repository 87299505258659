import {
 STATUS_LIST_REQUEST,
 STATUS_LIST_SUCCESS,
 STATUS_LIST_ERROR,
 STATUS_ADD_ERROR,
 STATUS_ADD_REQUEST,
 STATUS_ADD_SUCCESS,
 STATUS_DELETE_ERROR,
 STATUS_DELETE_REQUEST,
 STATUS_DELETE_SUCCESS,
 STATUS_UPDATE_ERROR,
 STATUS_UPDATE_REQUEST,
 STATUS_UPDATE_SUCCESS,
 STATUS_DETAILS_REQUEST,
 STATUS_DETAILS_SUCCESS,
 STATUS_DETAILS_ERROR,
} from "../constants/statusConstant";
import axios from "axios";
import { getAxiosTargetPath } from "../utils/utils";
import { logout } from "./userActions";

export const getAllStatus = () => async (dispatch, getState) => {
 try {
  dispatch({ type: STATUS_LIST_REQUEST });
  const {
   userLogin: { userInfo },
  } = getState();
  const config = {
   headers: {
    Authorization: `Bearer ${userInfo.token}`,
   },
  };
  const axiosPath = getAxiosTargetPath(`/status/all`);
  const { data } = await axios.get(axiosPath, config);
  dispatch({
   type: STATUS_LIST_SUCCESS,
   payload: data,
  });
 } catch (error) {
  const message =
   error.response && error.response.data.message
    ? error.response.data.message
    : error.message;
  if (message === "Not authorized, token failed") {
   dispatch(logout());
  }
  dispatch({
   type: STATUS_LIST_ERROR,
   payload: message,
  });
 }
};

export const addStatus = (status) => async (dispatch, getState) => {
 try {
  dispatch({ type: STATUS_ADD_REQUEST });
  const {
   userLogin: { userInfo },
  } = getState();
  const config = {
   headers: {
    Authorization: `Bearer ${userInfo.token}`,
   },
  };

  const { data } = await axios.post(`/status/addStatus`, status, config);

  dispatch({
   type: STATUS_ADD_SUCCESS,
   payload: data,
  });
 } catch (error) {
  dispatch({
   type: STATUS_ADD_ERROR,
   payload:
    error.response && error.response.data.message
     ? error.response.data.message
     : error.message,
  });
 }
};

export const getStatusDetails = (id) => async (dispatch, getState) => {
 try {
  dispatch({ type: STATUS_DETAILS_REQUEST });
  const {
   userLogin: { userInfo },
  } = getState();
  const config = {
   headers: {
    Authorization: `Bearer ${userInfo.token}`,
   },
  };

  const { data } = await axios.get(`/status/${id}`, config);

  dispatch({
   type: STATUS_DETAILS_SUCCESS,
   payload: data,
  });
 } catch (error) {
  dispatch({
   type: STATUS_DETAILS_ERROR,
   payload:
    error.response && error.response.data.message
     ? error.response.data.message
     : error.message,
  });
 }
};

export const getStatusDetailsByStatusId =
 (statusID) => async (dispatch, getState) => {
  try {
   dispatch({ type: STATUS_DETAILS_REQUEST });
   const {
    userLogin: { userInfo },
   } = getState();
   const config = {
    headers: {
     Authorization: `Bearer ${userInfo.token}`,
    },
   };

   const { data } = await axios.get(`/status/user/${statusID}`, config);

   dispatch({
    type: STATUS_DETAILS_SUCCESS,
    payload: data,
   });
  } catch (error) {
   dispatch({
    type: STATUS_DETAILS_ERROR,
    payload:
     error.response && error.response.data.message
      ? error.response.data.message
      : error.message,
   });
  }
 };

export const updateStatus = (status) => async (dispatch, getState) => {
 try {
  dispatch({ type: STATUS_UPDATE_REQUEST });
  const {
   userLogin: { userInfo },
  } = getState();
  const config = {
   headers: {
    Authorization: `Bearer ${userInfo.token}`,
   },
  };
  const { data } = await axios.put(`/status/${status._id}`, status, config);

  dispatch({
   type: STATUS_UPDATE_SUCCESS,
   payload: data,
  });
 } catch (error) {
  dispatch({
   type: STATUS_UPDATE_ERROR,
   payload:
    error.response && error.response.data.message
     ? error.response.data.message
     : error.message,
  });
 }
};

export const deleteStatus = (id) => async (dispatch, getState) => {
 try {
  dispatch({ type: STATUS_DELETE_REQUEST });
  const {
   userLogin: { userInfo },
  } = getState();
  const config = {
   headers: {
    Authorization: `Bearer ${userInfo.token}`,
   },
  };

  const { data } = await axios.delete(`/status/${id}`, config);

  dispatch({
   type: STATUS_DELETE_SUCCESS,
   payload: data,
  });
 } catch (error) {
  dispatch({
   type: STATUS_DELETE_ERROR,
   payload:
    error.response && error.response.data.message
     ? error.response.data.message
     : error.message,
  });
 }
};
