import React from "react";
import { Row, Col } from "react-bootstrap";
import dayjs from "dayjs";

const Schedule = ({ shift1, shift2, shift3, lateInTolerance }) => {
 const rowClassName = "d-flex justify-content-left";
 const marginRight = "5px";
 return (
  <>
   {
    <Row className={rowClassName}>
     <Col md="auto">
      <i
       className="fas fa-clock"
       style={{ color: "#1D9BF0", marginRight: marginRight }}
      />
      {shift1 ? `Shift 1: ${dayjs(shift1)?.format("HH:mm")}` : `Shift 1: -`}
     </Col>
    </Row>
   }
   {
    <Row className={rowClassName}>
     <Col md="auto">
      <i
       className="fas fa-clock"
       style={{ color: "#1D9BF0", marginRight: marginRight }}
      />
      {shift2 ? `Shift 2: ${dayjs(shift2)?.format("HH:mm")}` : `Shift 2: -`}
     </Col>
    </Row>
   }
   {
    <Row className={rowClassName}>
     <Col md="auto">
      <i
       className="fas fa-clock"
       style={{ color: "#1D9BF0", marginRight: marginRight }}
      />
      {shift3 ? `Shift 3: ${dayjs(shift3)?.format("HH:mm")}` : `Shift 3: -`}
     </Col>
    </Row>
   }
   {
    <Row className={rowClassName}>
     <Col md="auto">
      <i
       className="fas fa-check-circle"
       style={{ color: "green", marginRight: marginRight }}
      />
      {lateInTolerance
       ? `Late in tolerance: ${lateInTolerance} minutes`
       : `Late in tolerance: -`}
     </Col>
    </Row>
   }
  </>
 );
};

export default Schedule;
