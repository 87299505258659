import {
 LEAVE_LIST_REQUEST,
 LEAVE_LIST_SUCCESS,
 LEAVE_LIST_ERROR,
 LEAVE_ADD_ERROR,
 LEAVE_ADD_REQUEST,
 LEAVE_ADD_SUCCESS,
 LEAVE_UPDATE_ERROR,
 LEAVE_UPDATE_REQUEST,
 LEAVE_UPDATE_SUCCESS,
 LEAVE_UPDATE_RESET,
 LEAVE_DELETE_ERROR,
 LEAVE_DELETE_REQUEST,
 LEAVE_DELETE_SUCCESS,
 LEAVE_DELETE_RESET,
 LEAVE_DETAILS_REQUEST,
 LEAVE_DETAILS_SUCCESS,
 LEAVE_DETAILS_ERROR,
} from "../constants/leaveConstant";

export const leaveListReducer = (state = { leaves: [] }, action) => {
 switch (action.type) {
  case LEAVE_LIST_REQUEST:
   return { ...state, loading: true };
  case LEAVE_LIST_SUCCESS:
   return {
    loading: false,
    leaves: action.payload.leaves,
    pages: action.payload.pages,
    page: action.payload.page,
   };
  case LEAVE_LIST_ERROR:
   return { loading: false, error: action.payload };

  default:
   return state;
 }
};

export const leaveAddReducer = (state = { leaves: {} }, action) => {
 switch (action.type) {
  case LEAVE_ADD_REQUEST:
   return { ...state, loading: true };
  case LEAVE_ADD_SUCCESS:
   return { loading: false, success: true };
  case LEAVE_ADD_ERROR:
   return { loading: false, error: action.payload };

  default:
   return state;
 }
};

export const leaveDetailsReducer = (state = {}, action) => {
 switch (action.type) {
  case LEAVE_DETAILS_REQUEST:
   return { ...state, loading: true };
  case LEAVE_DETAILS_SUCCESS:
   return { loading: false, leave: action.payload };
  case LEAVE_DETAILS_ERROR:
   return { loading: false, error: action.payload };

  default:
   return state;
 }
};

export const leaveUpdateReducer = (state = {}, action) => {
 switch (action.type) {
  case LEAVE_UPDATE_REQUEST:
   return { ...state, loading: true };
  case LEAVE_UPDATE_SUCCESS:
   return { loading: false, success: true };
  case LEAVE_UPDATE_ERROR:
   return { loading: false, error: action.payload };
  case LEAVE_UPDATE_RESET:
   return {};

  default:
   return state;
 }
};

export const leaveDeleteReducer = (state = {}, action) => {
 switch (action.type) {
  case LEAVE_DELETE_REQUEST:
   return { ...state, loading: true };
  case LEAVE_DELETE_SUCCESS:
   return { loading: false, success: true };
  case LEAVE_DELETE_ERROR:
   return { loading: false, error: action.payload };
  case LEAVE_DELETE_RESET:
   return {};

  default:
   return state;
 }
};
