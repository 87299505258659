import { options } from "../utils/utils";

export const outletOptions = options([
 "SRIWIJAYA",
 "SUDIRMAN",
 "SETIABUDI",
 "MAHAKAM",
 "TAMAN TEMAN",
 "MANAGEMENT",
 "KHALID MEDAN",
 "KHALID BINTARO",
]);

export const outlets = [
 "SRIWIJAYA",
 "SUDIRMAN",
 "SETIABUDI",
 "MAHAKAM",
 "TAMAN TEMAN",
 "MANAGEMENT",
 "KHALID MEDAN",
 "KHALID BINTARO",
];
