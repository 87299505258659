import React from "react";
import { Row, Col } from "react-bootstrap";

const PhotoProfile = ({
 profilePicturePath,
 width = "100px",
 height = "100px",
 borderRadius = "50%",
 loading = "lazy",
 children,
 childrenClassName = "d-flex justify-content-center align-items-end",
 isInsideRow = true,
 marginRight = "0px",
}) => {
 if (!isInsideRow) {
  return (
   <img
    src={
     profilePicturePath
      ? `../${profilePicturePath}`
      : `../uploads/images/profilePictures/blank-profile-picture.png`
    }
    alt="Profile"
    style={{
     width: width,
     height: height,
     borderRadius: borderRadius,
     marginRight: marginRight,
    }}
    loading={loading}
    onError={(e) => {
     e.target.onerror = null; // Menghindari loop error
     e.target.src =
      "../uploads/images/profilePictures/blank-profile-picture.png";
    }}
   />
  );
 }

 return (
  <Row className="justify-content-md-center">
   <Col md="auto" className="d-flex justify-content-center">
    <img
     src={
      profilePicturePath
       ? `../${profilePicturePath}`
       : `../uploads/images/profilePictures/blank-profile-picture.png`
     }
     alt="Profile"
     style={{
      width: width,
      height: height,
      borderRadius: borderRadius,
     }}
     loading={loading}
     onError={(e) => {
      e.target.onerror = null; // Menghindari loop error
      e.target.src =
       "../uploads/images/profilePictures/blank-profile-picture.png";
     }}
    />
   </Col>
   {children && (
    <Col md="auto" className={childrenClassName}>
     {children}
    </Col>
   )}
  </Row>
 );
};

export default PhotoProfile;
