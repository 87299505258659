import React from "react";
import { useSelector } from "react-redux";
import Message from "../components/message";
import Loader from "../components/loader";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { stringIncludes, capitalizeEachWord } from "../utils/utils";
import { Table } from "react-bootstrap";
import verifiedIcon from "../Assets/icon/verified.png";
import { useSortableData } from "../utils/sort";

const EmployeesTableBpjsView = ({ isActiveEmployee, outlet }) => {
 const employeesList = useSelector((state) => state.employeesList);
 const { loading, error, employees } = employeesList;

 const userLogin = useSelector((state) => state.userLogin);
 const { userInfo } = userLogin;

 const ProductTable = (props) => {
  const { items, requestSort, sortConfig } = useSortableData(props.products);
  const getClassNamesFor = (name) => {
   if (!sortConfig) {
    return;
   }
   return sortConfig.key === name ? sortConfig.direction : undefined;
  };
  return (
   <div>
    <Table striped bordered hover size="sm" className="custom-table">
     <thead className="sticky-header">
      <tr>
       <th
        style={{
         textAlign: "left",
        }}
       >
        <button
         type="button"
         onClick={() => requestSort("employeeID")}
         className={getClassNamesFor("employeeID") + " sort-btn"}
        >
         ID
        </button>
       </th>
       <th style={{ textAlign: "left" }}>
        <button
         type="button"
         onClick={() => requestSort("name")}
         className={getClassNamesFor("name") + " sort-btn"}
        >
         Name
        </button>
       </th>
       <th style={{ textAlign: "center" }}>
        <button
         type="button"
         onClick={() => requestSort("status")}
         className={getClassNamesFor("status") + " sort-btn"}
        >
         Status
        </button>
       </th>
       {!isActiveEmployee && (
        <th style={{ textAlign: "right" }}>
         <button
          type="button"
          onClick={() => requestSort("dateResign")}
          className={getClassNamesFor("dateResign") + " sort-btn"}
         >
          Date Resigned
         </button>
        </th>
       )}
       <th style={{ textAlign: "center" }}>
        <button
         type="button"
         onClick={() => requestSort("bpjsKesehatan")}
         className={getClassNamesFor("bpjsKesehatan") + " sort-btn"}
        >
         No. BPJS Kesehatan
        </button>
       </th>
       <th style={{ textAlign: "left" }}>
        <button
         type="button"
         onClick={() => requestSort("bpjsKesehatanDescription")}
         className={getClassNamesFor("bpjsKesehatanDescription") + " sort-btn"}
        >
         Description
        </button>
       </th>
       <th style={{ textAlign: "center" }}>
        <button
         type="button"
         onClick={() => requestSort("bpjsKetenagakerjaan")}
         className={getClassNamesFor("bpjsKetenagakerjaan") + " sort-btn"}
        >
         No. BPJS Ketenagakerjaan
        </button>
       </th>
       <th style={{ textAlign: "left" }}>
        <button
         type="button"
         onClick={() => requestSort("bpjsKetenagakerjaanDescription")}
         className={
          getClassNamesFor("bpjsKetenagakerjaanDescription") + " sort-btn"
         }
        >
         Description
        </button>
       </th>
      </tr>
     </thead>
     <tbody style={{ color: "#000000" }}>
      {userInfo.isAdmin ||
      userInfo.isOutletLeader ||
      userInfo.isCompanyLeader ||
      userInfo.isSuperUser ? (
       items.map((item, index) =>
        isActiveEmployee ? (
         (!item.dateResign && outlet === item.outlet) ||
         (!item.dateResign && outlet === "ALL OUTLETS") ? (
          <tr key={item._id}>
           <td>{item.employeeID}</td>
           <td>
            <Link to={`/employee/${item._id}`} className="link-black">
             {item.name}{" "}
             {stringIncludes(item.status, "tetap") && verifiedIcon && (
              <img
               src={verifiedIcon}
               style={{ width: "15px", height: "15px" }}
               alt="Verified"
              />
             )}
            </Link>
           </td>
           <td
            className={
             stringIncludes(item.status, "tetap") ? "tetap" : "probation"
            }
           >
            {capitalizeEachWord(item.status)}
           </td>
           {item.dateResign && (
            <td style={{ textAlign: "right" }}>
             {dayjs(item.dateResign).format("DD-MMM-YYYY")}
            </td>
           )}
           <td style={{ textAlign: "center" }}>{item.bpjsKesehatan}</td>
           <td
            style={{
             textAlign: "left",
             maxWidth: "250px",
            }}
           >
            {item.bpjsKesehatanDescription}
           </td>
           <td style={{ textAlign: "center" }}>{item.bpjsKetenagakerjaan}</td>
           <td style={{ textAlign: "left" }}>
            {item.bpjsKetenagakerjaanDescription}
           </td>
          </tr>
         ) : null
        ) : (item.dateResign && outlet === item.outlet) ||
          (item.dateResign && outlet === "ALL OUTLETS") ? (
         <tr key={item._id}>
          <td>{item.employeeID}</td>
          <td>
           <Link to={`/employee/${item._id}`} className="link-black">
            {item.name}{" "}
            {stringIncludes(item.status, "tetap") && verifiedIcon && (
             <img
              src={verifiedIcon}
              style={{ width: "15px", height: "15px" }}
              alt="Verified"
             />
            )}
           </Link>
          </td>
          <td
           className={
            stringIncludes(item.status, "tetap") ? "tetap" : "probation"
           }
          >
           {capitalizeEachWord(item.status)}
          </td>
          {item.dateResign && (
           <td style={{ textAlign: "right" }}>
            {dayjs(item.dateResign).format("DD-MMM-YYYY")}
           </td>
          )}
          <td style={{ textAlign: "center" }}>{item.bpjsKesehatan}</td>
          <td style={{ textAlign: "left", maxWidth: "250px" }}>
           {item.bpjsKesehatanDescription}
          </td>
          <td style={{ textAlign: "center" }}>{item.bpjsKetenagakerjaan}</td>
          <td style={{ textAlign: "left" }}>
           {item.bpjsKetenagakerjaanDescription}
          </td>
         </tr>
        ) : null
       )
      ) : (
       <tr key={items._id}>
        <td>{items.employeeID}</td>
        <td>
         <Link to={`/employee/${items._id}`} className="link-black">
          {items.name}{" "}
          {stringIncludes(items.status, "tetap") && verifiedIcon && (
           <img
            src={verifiedIcon}
            style={{ width: "15px", height: "15px" }}
            alt="Verified"
           />
          )}
         </Link>
        </td>
        <td
         className={
          stringIncludes(items.status, "tetap") ? "tetap" : "probation"
         }
        >
         {capitalizeEachWord(items.status)}
        </td>
        {items.dateResign && (
         <td style={{ textAlign: "right" }}>
          {dayjs(items.dateResign).format("DD-MMM-YYYY")}
         </td>
        )}
        <td style={{ textAlign: "center" }}>{items.bpjsKesehatan}</td>
        <td style={{ textAlign: "left", maxWidth: "250px" }}>
         {items.bpjsKesehatanDescription}
        </td>
        <td style={{ textAlign: "center" }}>{items.bpjsKetenagakerjaan}</td>
        <td style={{ textAlign: "left" }}>
         {items.bpjsKetenagakerjaanDescription}
        </td>
       </tr>
      )}
     </tbody>
    </Table>
   </div>
  );
 };

 return (
  <>
   {loading ? (
    <Loader />
   ) : error ? (
    <Message variant="danger">{error}</Message>
   ) : (
    <ProductTable products={employees} />
   )}
  </>
 );
};

export default EmployeesTableBpjsView;
