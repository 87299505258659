/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HomeViewBgImage } from "../components/backgroundImage";
import { stringIncludes } from "../utils/utils";

const HomeView = ({ match, history }) => {
 const keyword = match.params.keyword;
 const pageNumber = match.params.pageNumber || 1;
 const userLogin = useSelector((state) => state.userLogin);
 const { loading: userLoading, error, userInfo } = userLogin;
 const dispatch = useDispatch();

 // eslint-disable-next-line
 useEffect(() => {
  if ((!userLoading && !userInfo) || stringIncludes(error, "not authorized")) {
   history.push("/login");
  }
 }, [keyword, pageNumber, userLoading, userInfo, history, error]);

 return (
  <>
   <HomeViewBgImage>
    <h3 style={{ color: "gray" }}>Human Resource Information System</h3>
   </HomeViewBgImage>
  </>
 );
};

export default HomeView;
