export const defaultOptions = "Select...";

export const employeeTabs = [
 { label: "Work", value: "work" },
 { label: "Personal", value: "personal" },
 { label: "Contact", value: "contact" },
 { label: "Education", value: "education" },
 { label: "BPJS", value: "bpjs" },
 { label: "Bank", value: "bank" },
 { label: "Schedule", value: "schedule" },
];

export const employeeListTabs = [
 { label: "Grid", value: "grid" },
 { label: "Table General", value: "table general" },
 { label: "Table BPJS", value: "table bpjs" },
 { label: "Table Bank", value: "table bank" },
 { label: "Table Age", value: "table age" },
 { label: "Table Schedule", value: "table schedule" },
];

export const bankOptions = [
 { label: "Select...", name: "" },
 { label: "Bank Central Asia (BCA)", name: "BCA" },
 { label: "Bank Mandiri", name: "Mandiri" },
 { label: "Bank Rakyat Indonesia (BRI)", name: "BRI" },
 { label: "Bank Negara Indonesia (BNI)", name: "BNI" },
 { label: "CIMB Niaga", name: "CIMB" },
 { label: "Bank Tabungan Negara (BTN)", name: "BTN" },
 { label: "Bank Danamon", name: "Danamon" },
 { label: "Bank Permata", name: "Permata" },
 { label: "OCBC NISP", name: "OCBC" },
 { label: "Panin Bank", name: "Panin" },
 { label: "Maybank Indonesia", name: "Maybank" },
 { label: "Bank Mega", name: "Mega" },
 { label: "Bank Sinarmas", name: "Sinarmas" },
 { label: "Bank Bukopin", name: "Bukopin" },
 { label: "Sea Bank", name: "Seabank" },
 { label: "Bank Jago", name: "Bank Jago" },
 // Bank Syariah
 { label: "BCA Syariah", name: "BCA Syariah" },
 { label: "Bank Syariah Indonesia (BSI)", name: "BSI" },
 { label: "Bank Muamalat", name: "Muamalat" },
 { label: "BRI Syariah", name: "BRI Syariah" },
 { label: "BNI Syariah", name: "BNI Syariah" },
 { label: "Mandiri Syariah", name: "Mandiri Syariah" },
 { label: "Panin Dubai Syariah", name: "Panin Syariah" },
 // Bank Daerah Sumatera Utara
 { label: "Bank Sumut", name: "Bank Sumut" },
 // Bank Daerah Aceh
 { label: "Bank Aceh", name: "Bank Aceh" },
 { label: "Bank Aceh Syariah", name: "Bank Aceh Syariah" },
 // Bank Daerah DKI Jakarta dan Tangerang
 { label: "Bank DKI", name: "Bank DKI" },
 { label: "Bank BJB", name: "Bank BJB" }, // Bank Jawa Barat dan Banten
];
