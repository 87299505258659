import React from "react";
import { Image } from "react-bootstrap";
import logoImage from "../Assets/Image/LogoFullColourNoOutline.png";
import { HStack } from "rsuite";

const Logo = ({ children }) => {
 return (
  <HStack className="page-brand" spacing={12} justifyContent="center">
   <Image
    src={logoImage}
    alt="Logo"
    style={{ width: "125px", height: "80px", marginBottom: "10px" }}
   />
  </HStack>
 );
};

export { Logo };
