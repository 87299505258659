import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Row, Col } from "react-bootstrap";
import dayjs from "dayjs";
import GoBackButton from "../components/goBackButton";
import { getAge, getWorkDuration } from "../utils/utils";
import { addOutlet } from "../actions/outletActions";
import { /*options,*/ stringIncludes } from "../utils/utils";
import Loading from "../components/loader";
import Message from "../components/message.jsx";
import { logout } from "../actions/userActions";

const AddOutletView = ({ match, history }) => {
 /*
 name,
  outletCode,
  address,
  subDistrict,
  district,
  city,
  state,
  abbreviation,
  dateFound,
*/
 const [name, setName] = useState("");
 const [outletCode, setOutletCode] = useState("");
 const [address, setAddress] = useState("");
 const [subDistrict, setSubDistrict] = useState("");
 const [district, setDistrict] = useState("");
 const [city, setCity] = useState("");
 // eslint-disable-next-line
 const [state, setState] = useState("");
 // eslint-disable-next-line
 const [abbreviation, setAbbreviation] = useState("");
 const [dateFound, setDateFound] = useState("");

 const dispatch = useDispatch();
 const userLogin = useSelector((state) => state.userLogin);
 const { loading: userLoading, userInfo } = userLogin;
 const outletAdd = useSelector((state) => state.outletAdd);
 const { loading, error, success } = outletAdd;

 useEffect(() => {
  if ((!userLoading && !userInfo) || stringIncludes(error, "not authorized")) {
   dispatch(logout());
   history.push("/login");
  }
  if (success) {
   history.push("/outletList");
  }
 }, [dispatch, history, success, userInfo, loading, userLoading, error]);

 const submitHandler = () => {
  dispatch(
   addOutlet({
    name,
    outletCode,
    address,
    subDistrict,
    district,
    city,
    state,
    abbreviation,
    dateFound,
   })
  );
 };

 // Fungsi untuk mengubah tanggal menjadi format YYYY-MM-DD
 const dateForPicker = (date) => {
  return date ? dayjs(date).format("YYYY-MM-DD") : "";
 };

 // Fungsi untuk mengubah string tanggal menjadi objek Date
 const dateFromDateString = (dateString) => {
  return dateString ? dayjs(dateString).toDate() : null;
 };

 const getAgeLong = () => {
  return getAge(dateFound);
 };

 return (
  <>
   {loading ? (
    <Loading />
   ) : (
    <>
     {error && <Message variant="danger">{error}</Message>}
     <Row>
      <Col md={4}>
       <GoBackButton onClick={() => history.goBack()}></GoBackButton>
      </Col>
      <Col md={4}>
       <h3>{`Add Outlet`}</h3>
      </Col>
     </Row>
     <Row>
      <Col md={4}>
       <h4 className="underline">Data</h4>
      </Col>
     </Row>

     <Row>
      <Col md={4}>
       <Form.Group controlId="outletCode">
        <Form.Label>
         Outlet Code<span>*</span>
        </Form.Label>
        <Form.Control
         placeholder="Enter outlet code"
         value={outletCode}
         onChange={(e) => setOutletCode(e.target.value)}
        ></Form.Control>
       </Form.Group>
       <Form.Group controlId="name">
        <Form.Label>
         Name<span>*</span>
        </Form.Label>
        <Form.Control
         placeholder="Enter name"
         value={name}
         onChange={(e) => setName(e.target.value)}
        ></Form.Control>
       </Form.Group>
      </Col>
      <Col md={4}>
       <Form.Group controlId="dateFound">
        <Row>
         <Col>
          <Form.Label>
           Date Found<span>*</span>
          </Form.Label>
         </Col>
         <Col md={9}>
          <Form.Text className="text-muted">
           {dateFound && `Duration: ${getWorkDuration(dateFound, undefined)}`}
          </Form.Text>
         </Col>
        </Row>
        <Form.Control
         type="date"
         value={dateForPicker(dateFound)}
         onChange={(e) => setDateFound(dateFromDateString(e.target.value))}
        />
       </Form.Group>
      </Col>
     </Row>
     <Row>
      <Col md={4}>
       <h4 className="underline">Address</h4>
      </Col>
     </Row>
     <Row>
      <Col md={4}>
       <Form.Group controlId="address">
        <Form.Label>Adress</Form.Label>
        <Form.Control
         placeholder="Enter address"
         value={address}
         onChange={(e) => setAddress(e.target.value)}
        ></Form.Control>
       </Form.Group>
       <Form.Group controlId="subDistrict">
        <Form.Label>Kelurahan</Form.Label>
        <Form.Control
         placeholder="Enter Kelurahan"
         value={subDistrict}
         onChange={(e) => setSubDistrict(e.target.value)}
        ></Form.Control>
       </Form.Group>
      </Col>
      <Col md={4}>
       <Form.Group controlId="kecamatan">
        <Form.Label>Kecamatan</Form.Label>
        <Form.Control
         placeholder="Enter kecamatan"
         value={district}
         onChange={(e) => setDistrict(e.target.value)}
        ></Form.Control>
       </Form.Group>
       <Form.Group controlId="city">
        <Form.Label>Kota</Form.Label>
        <Form.Control
         placeholder="Enter kota/kabupaten"
         value={city}
         onChange={(e) => setCity(e.target.value)}
        ></Form.Control>
       </Form.Group>
       <Form.Group controlId="dateFound">
        <Row>
         <Col>
          <Form.Label>Date Found</Form.Label>
         </Col>
         <Col md={8}>
          <Form.Text className="text-muted">{getAgeLong()}</Form.Text>
         </Col>
        </Row>
        <Form.Control
         type="date"
         value={dateForPicker(dateFound)}
         onChange={(e) => setDateFound(dateFromDateString(e.target.value))}
        />
       </Form.Group>
      </Col>
     </Row>
     <Row>
      <Col md={3}>
       <h4 className="underline">Contact</h4>
      </Col>
     </Row>
     <Row>
      <Col md={3}>
       {
        <button
         type="submit"
         className="button"
         onClick={() => {
          submitHandler();
         }}
        >
         Add Employee
        </button>
       }
      </Col>
     </Row>
    </>
   )}
  </>
 );
};

export default AddOutletView;
