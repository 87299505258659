import React from "react";
import { Row, Col } from "react-bootstrap";
import verifiedIcon from "../../Assets/icon/verified.png";
import { stringIncludes } from "../../utils/utils";

const Name = ({ name, status, color = "black" }) => {
 if (!name || !status) return "";

 return (
  <Row className="justify-content-md-center">
   <Col md="auto" className="d-flex justify-content-center">
    <span style={{ color: color, fontWeight: "500" }}>{name}</span>
    {stringIncludes(status, "tetap") && (
     <img
      src={verifiedIcon}
      style={{ width: "20px", height: "20px" }}
      alt="Verified"
     />
    )}
   </Col>
  </Row>
 );
};

export default Name;
