import React from "react";
import { useSelector } from "react-redux";
import Message from "../components/message";
import Loader from "../components/loader";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { stringIncludes, capitalizeEachWord } from "../utils/utils";
import { WhatsAppLink } from "../components/whatsapp";
import { EmailLink } from "../components/email";
import { Row, Col, Table } from "react-bootstrap";
import verifiedIcon from "../Assets/icon/verified.png";
import { useSortableData } from "../utils/sort";

const EmployeesTableBankView = ({ isActiveEmployee, outlet }) => {
 const employeesList = useSelector((state) => state.employeesList);
 const { loading, error, employees } = employeesList;

 const userLogin = useSelector((state) => state.userLogin);
 const { userInfo } = userLogin;

 const ProductTable = (props) => {
  const { items, requestSort, sortConfig } = useSortableData(props.products);
  const getClassNamesFor = (name) => {
   if (!sortConfig) {
    return;
   }
   return sortConfig.key === name ? sortConfig.direction : undefined;
  };
  return (
   <div>
    <Table striped bordered hover size="sm" className="custom-table">
     <thead className="sticky-header">
      <tr>
       <th style={{ textAlign: "left" }}>
        <button
         type="button"
         onClick={() => requestSort("employeeID")}
         className={getClassNamesFor("employeeID") + " sort-btn"}
        >
         ID
        </button>
       </th>
       <th style={{ textAlign: "left" }}>
        <button
         type="button"
         onClick={() => requestSort("name")}
         className={getClassNamesFor("name") + " sort-btn"}
        >
         Name
        </button>
       </th>
       <th style={{ textAlign: "center" }}>
        <button
         type="button"
         onClick={() => requestSort("status")}
         className={getClassNamesFor("status") + " sort-btn"}
        >
         Status
        </button>
       </th>
       {!isActiveEmployee && (
        <th style={{ textAlign: "right" }}>
         <button
          type="button"
          onClick={() => requestSort("dateResign")}
          className={getClassNamesFor("dateResign") + " sort-btn"}
         >
          Date Resigned
         </button>
        </th>
       )}
       <th style={{ textAlign: "center" }}>
        <button
         type="button"
         onClick={() => requestSort("bank")}
         className={getClassNamesFor("bank") + " sort-btn"}
        >
         Bank Name
        </button>
       </th>
       <th style={{ textAlign: "left" }}>
        <button
         type="button"
         onClick={() => requestSort("bankAccount")}
         className={getClassNamesFor("bankAccount") + " sort-btn"}
        >
         Bank Account / No. Rekening
        </button>
       </th>
       <th style={{ textAlign: "left" }}>
        <button
         type="button"
         onClick={() => requestSort("phone")}
         className={getClassNamesFor("phone") + " sort-btn"}
        >
         Phone
        </button>
       </th>
       <th style={{ textAlign: "left" }}>
        <button
         type="button"
         onClick={() => requestSort("email")}
         className={getClassNamesFor("email") + " sort-btn"}
        >
         Email
        </button>
       </th>
      </tr>
     </thead>
     <tbody style={{ color: "#000000" }}>
      {userInfo.isAdmin ||
      userInfo.isOutletLeader ||
      userInfo.isCompanyLeader ||
      userInfo.isSuperUser ? (
       items.map((item, index) =>
        isActiveEmployee ? (
         (!item.dateResign && outlet === item.outlet) ||
         (!item.dateResign && outlet === "ALL OUTLETS") ? (
          <tr key={item._id}>
           <td>{item.employeeID}</td>
           <td>
            <Link to={`/employee/${item._id}`} className="link-black">
             {item.name}{" "}
             {stringIncludes(item.status, "tetap") && verifiedIcon && (
              <img
               src={verifiedIcon}
               style={{ width: "15px", height: "15px" }}
               alt="Verified"
              />
             )}
            </Link>
           </td>
           <td
            className={
             stringIncludes(item.status, "tetap") ? "tetap" : "probation"
            }
           >
            {capitalizeEachWord(item.status)}
           </td>
           {item.dateResign && (
            <td style={{ textAlign: "right" }}>
             {dayjs(item.dateResign).format("DD-MMM-YYYY")}
            </td>
           )}
           <td style={{ textAlign: "center" }}>{item.bank}</td>
           <td style={{ textAlign: "left" }}>{item.bankAccount}</td>
           <td style={{ textAlign: "left" }}>
            <Row>
             <Col md={5} className="d-flex justify-content-start">
              {item.phone}
             </Col>
             <Col md={5} className="d-flex justify-content-end">
              {item.phone && (
               <WhatsAppLink
                phone={item.phone}
                fontSize="1em"
                marginLeft="10px"
               />
              )}
             </Col>
            </Row>
           </td>
           <td style={{ textAlign: "left" }}>
            <Row>
             <Col md={5} className="d-flex justify-content-start">
              {item.email}
             </Col>
             <Col md={5} className="d-flex justify-content-end">
              {item.email && (
               <EmailLink email={item.email} fontSize="1em" marginLeft="10px" />
              )}
             </Col>
            </Row>
           </td>
          </tr>
         ) : null
        ) : (item.dateResign && outlet === item.outlet) ||
          (item.dateResign && outlet === "ALL OUTLETS") ? (
         <tr key={item._id}>
          <td>{item.employeeID}</td>
          <td>
           <Link to={`/employee/${item._id}`} className="link-black">
            {item.name}{" "}
            {stringIncludes(item.status, "tetap") && verifiedIcon && (
             <img
              src={verifiedIcon}
              style={{ width: "15px", height: "15px" }}
              alt="Verified"
             />
            )}
           </Link>
          </td>
          <td
           className={
            stringIncludes(item.status, "tetap") ? "tetap" : "probation"
           }
          >
           {capitalizeEachWord(item.status)}
          </td>
          {item.dateResign && (
           <td style={{ textAlign: "right" }}>
            {dayjs(item.dateResign).format("DD-MMM-YYYY")}
           </td>
          )}
          <td style={{ textAlign: "center" }}>{item.bank}</td>
          <td style={{ textAlign: "left" }}>{item.bankAccount}</td>
          <td style={{ textAlign: "left" }}>
           <Row>
            <Col md="auto" className="d-flex justify-content-start">
             {item.phone}
            </Col>
            <Col md="auto" className="d-flex justify-content-end">
             {item.phone && (
              <WhatsAppLink
               phone={item.phone}
               fontSize="1em"
               marginLeft="10px"
              />
             )}
            </Col>
           </Row>
          </td>
          <td style={{ textAlign: "left" }}>
           <Row>
            <Col md={5} className="d-flex justify-content-start">
             {item.email}
            </Col>
            <Col md={5} className="d-flex justify-content-end">
             {item.email && (
              <EmailLink email={item.email} fontSize="1em" marginLeft="10px" />
             )}
            </Col>
           </Row>
          </td>
         </tr>
        ) : null
       )
      ) : (
       <tr key={items._id}>
        <td>{items.employeeID}</td>
        <td>
         <Link to={`/employee/${items._id}`} className="link-black">
          {items.name}{" "}
          {stringIncludes(items.status, "tetap") && verifiedIcon && (
           <img
            src={verifiedIcon}
            style={{ width: "15px", height: "15px" }}
            alt="Verified"
           />
          )}
         </Link>
        </td>
        <td
         className={
          stringIncludes(items.status, "tetap") ? "tetap" : "probation"
         }
        >
         {capitalizeEachWord(items.status)}
        </td>
        {items.dateResign && (
         <td style={{ textAlign: "right" }}>
          {dayjs(items.dateResign).format("DD-MMM-YYYY")}
         </td>
        )}
        <td style={{ textAlign: "center" }}>{items.bank}</td>
        <td style={{ textAlign: "left" }}>{items.bankAccount}</td>
        <td style={{ textAlign: "left" }}>
         <Row>
          <Col md="auto" className="d-flex justify-content-start">
           {items.phone}
          </Col>
          <Col md="auto" className="d-flex justify-content-end">
           {items.phone && (
            <WhatsAppLink
             phone={items.phone}
             fontSize="1em"
             marginLeft="10px"
            />
           )}
          </Col>
         </Row>
        </td>
        <td style={{ textAlign: "left" }}>
         <Row>
          <Col md={5} className="d-flex justify-content-start">
           {items.email}
          </Col>
          <Col md={5} className="d-flex justify-content-end">
           {items.email && (
            <EmailLink email={items.email} fontSize="1em" marginLeft="10px" />
           )}
          </Col>
         </Row>
        </td>
       </tr>
      )}
     </tbody>
    </Table>
   </div>
  );
 };

 return (
  <>
   {loading ? (
    <Loader />
   ) : error ? (
    <Message variant="danger">{error}</Message>
   ) : (
    <ProductTable products={employees} />
   )}
  </>
 );
};

export default EmployeesTableBankView;
