import React from "react";
import { Row, Col, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import { stringIncludes } from "../utils/utils";

const Tab = ({
 tabs = [{}],
 tab = "",
 setTab,
 rowClassName = "mt-3 justify-content-md-left",
 defaultSetTab = true,
}) => {
 if (!Array.isArray(tabs)) return <></>;
 if (!tabs.length) return <></>;

 return (
  <Row className={rowClassName}>
   <Col md="auto">
    <ToggleButtonGroup type="radio" name="options">
     {tabs.map((item, index) => (
      <ToggleButton
       key={item.value}
       value={item.value}
       id={`tab-${index + 1}`}
       onChange={
        defaultSetTab
         ? (e) => {
            setTab(e.target.value);
           }
         : setTab
       }
       style={{
        backgroundColor: stringIncludes(tab, item.value)
         ? "#dc3545"
         : "#6c757d",
        border: "none", // Remove the outline
       }}
      >
       {item.label}
      </ToggleButton>
     ))}
    </ToggleButtonGroup>
   </Col>
  </Row>
 );
};

export default Tab;
