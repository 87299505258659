export const BANK_LIST_REQUEST = "BANK_LIST_REQUEST";
export const BANK_LIST_SUCCESS = "BANK_LIST_SUCCESS";
export const BANK_LIST_ERROR = "BANK_LIST_ERROR";

export const BANK_ADD_REQUEST = "BANK_ADD_REQUEST";
export const BANK_ADD_SUCCESS = "BANK_ADD_SUCCESS";
export const BANK_ADD_ERROR = "BANK_ADD_ERROR";

export const BANK_DETAILS_REQUEST = "BANK_DETAILS_REQUEST";
export const BANK_DETAILS_SUCCESS = "BANK_DETAILS_SUCCESS";
export const BANK_DETAILS_ERROR = "BANK_DETAILS_ERROR";

export const BANK_UPDATE_REQUEST = "BANK_UPDATE_REQUEST";
export const BANK_UPDATE_SUCCESS = "BANK_UPDATE_SUCCESS";
export const BANK_UPDATE_ERROR = "BANK_UPDATE_ERROR";
export const BANK_UPDATE_RESET = "BANK_UPDATE_RESET";

export const BANK_DELETE_REQUEST = "BANK_DELETE_REQUEST";
export const BANK_DELETE_SUCCESS = "BANK_DELETE_SUCCESS";
export const BANK_DELETE_ERROR = "BANK_DELETE_ERROR";
export const BANK_DELETE_RESET = "BANK_DELETE_RESET";
