export const POSITION_LIST_REQUEST = "POSITION_LIST_REQUEST";
export const POSITION_LIST_SUCCESS = "POSITION_LIST_SUCCESS";
export const POSITION_LIST_ERROR = "POSITION_LIST_ERROR";

export const POSITION_ADD_REQUEST = "POSITION_ADD_REQUEST";
export const POSITION_ADD_SUCCESS = "POSITION_ADD_SUCCESS";
export const POSITION_ADD_ERROR = "POSITION_ADD_ERROR";

export const POSITION_DETAILS_REQUEST = "POSITION_DETAILS_REQUEST";
export const POSITION_DETAILS_SUCCESS = "POSITION_DETAILS_SUCCESS";
export const POSITION_DETAILS_ERROR = "POSITION_DETAILS_ERROR";

export const POSITION_UPDATE_REQUEST = "POSITION_UPDATE_REQUEST";
export const POSITION_UPDATE_SUCCESS = "POSITION_UPDATE_SUCCESS";
export const POSITION_UPDATE_ERROR = "POSITION_UPDATE_ERROR";
export const POSITION_UPDATE_RESET = "POSITION_UPDATE_RESET";

export const POSITION_DELETE_REQUEST = "POSITION_DELETE_REQUEST";
export const POSITION_DELETE_SUCCESS = "POSITION_DELETE_SUCCESS";
export const POSITION_DELETE_ERROR = "POSITION_DELETE_ERROR";
export const POSITION_DELETE_RESET = "POSITION_DELETE_RESET";
