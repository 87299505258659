import {
 POSITION_LIST_REQUEST,
 POSITION_LIST_SUCCESS,
 POSITION_LIST_ERROR,
 POSITION_ADD_ERROR,
 POSITION_ADD_REQUEST,
 POSITION_ADD_SUCCESS,
 POSITION_DELETE_ERROR,
 POSITION_DELETE_REQUEST,
 POSITION_DELETE_SUCCESS,
 POSITION_UPDATE_ERROR,
 POSITION_UPDATE_REQUEST,
 POSITION_UPDATE_SUCCESS,
 POSITION_DETAILS_REQUEST,
 POSITION_DETAILS_SUCCESS,
 POSITION_DETAILS_ERROR,
} from "../constants/positionConstant";
import axios from "axios";
import { getAxiosTargetPath } from "../utils/utils";
import { logout } from "./userActions";

export const getAllPosition = () => async (dispatch, getState) => {
 try {
  dispatch({ type: POSITION_LIST_REQUEST });
  const {
   userLogin: { userInfo },
  } = getState();
  const config = {
   headers: {
    Authorization: `Bearer ${userInfo.token}`,
   },
  };
  const axiosPath = getAxiosTargetPath(`/position/all`);
  const { data } = await axios.get(axiosPath, config);
  dispatch({
   type: POSITION_LIST_SUCCESS,
   payload: data,
  });
 } catch (error) {
  const message =
   error.response && error.response.data.message
    ? error.response.data.message
    : error.message;
  if (message === "Not authorized, token failed") {
   dispatch(logout());
  }
  dispatch({
   type: POSITION_LIST_ERROR,
   payload: message,
  });
 }
};

export const addPosition = (position) => async (dispatch, getState) => {
 try {
  dispatch({ type: POSITION_ADD_REQUEST });
  const {
   userLogin: { userInfo },
  } = getState();
  const config = {
   headers: {
    Authorization: `Bearer ${userInfo.token}`,
   },
  };

  const { data } = await axios.post(`/position/addPosition`, position, config);

  dispatch({
   type: POSITION_ADD_SUCCESS,
   payload: data,
  });
 } catch (error) {
  dispatch({
   type: POSITION_ADD_ERROR,
   payload:
    error.response && error.response.data.message
     ? error.response.data.message
     : error.message,
  });
 }
};

export const getPositionDetails = (id) => async (dispatch, getState) => {
 try {
  dispatch({ type: POSITION_DETAILS_REQUEST });
  const {
   userLogin: { userInfo },
  } = getState();
  const config = {
   headers: {
    Authorization: `Bearer ${userInfo.token}`,
   },
  };

  const { data } = await axios.get(`/position/${id}`, config);

  dispatch({
   type: POSITION_DETAILS_SUCCESS,
   payload: data,
  });
 } catch (error) {
  dispatch({
   type: POSITION_DETAILS_ERROR,
   payload:
    error.response && error.response.data.message
     ? error.response.data.message
     : error.message,
  });
 }
};

export const getPositionDetailsByPositionId =
 (positionID) => async (dispatch, getState) => {
  try {
   dispatch({ type: POSITION_DETAILS_REQUEST });
   const {
    userLogin: { userInfo },
   } = getState();
   const config = {
    headers: {
     Authorization: `Bearer ${userInfo.token}`,
    },
   };

   const { data } = await axios.get(`/position/user/${positionID}`, config);

   dispatch({
    type: POSITION_DETAILS_SUCCESS,
    payload: data,
   });
  } catch (error) {
   dispatch({
    type: POSITION_DETAILS_ERROR,
    payload:
     error.response && error.response.data.message
      ? error.response.data.message
      : error.message,
   });
  }
 };

export const updatePosition = (position) => async (dispatch, getState) => {
 try {
  dispatch({ type: POSITION_UPDATE_REQUEST });
  const {
   userLogin: { userInfo },
  } = getState();
  const config = {
   headers: {
    Authorization: `Bearer ${userInfo.token}`,
   },
  };
  const { data } = await axios.put(
   `/position/${position._id}`,
   position,
   config
  );

  dispatch({
   type: POSITION_UPDATE_SUCCESS,
   payload: data,
  });
 } catch (error) {
  dispatch({
   type: POSITION_UPDATE_ERROR,
   payload:
    error.response && error.response.data.message
     ? error.response.data.message
     : error.message,
  });
 }
};

export const deletePosition = (id) => async (dispatch, getState) => {
 try {
  dispatch({ type: POSITION_DELETE_REQUEST });
  const {
   userLogin: { userInfo },
  } = getState();
  const config = {
   headers: {
    Authorization: `Bearer ${userInfo.token}`,
   },
  };

  const { data } = await axios.delete(`/position/${id}`, config);

  dispatch({
   type: POSITION_DELETE_SUCCESS,
   payload: data,
  });
 } catch (error) {
  dispatch({
   type: POSITION_DELETE_ERROR,
   payload:
    error.response && error.response.data.message
     ? error.response.data.message
     : error.message,
  });
 }
};
