import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import { FaQuestionCircle } from "react-icons/fa";
import dayjs from "dayjs";

const TooltipIcon = ({ text, color = "rgba(52, 58, 64, 0.8)" }) => {
 const renderTooltip = (props) => <Tooltip id="tooltip">{props}</Tooltip>;

 return (
  <OverlayTrigger
   placement="top"
   delay={{ show: 0, hide: 200 }}
   overlay={renderTooltip(text)}
  >
   <span>
    <FaQuestionCircle size={15} color={color} />
   </span>
  </OverlayTrigger>
 );
};

const TooltipButton = ({
 text,
 color = "rgba(52, 58, 64, 0.8)",
 buttonText,
 buttonClassName,
 key,
 status,
 date,
 dateIn,
 dateResign,
 late = 0,
 lateInTolerance = 0,
 description = "",
 clockIn = null,
 clockOut = null,
}) => {
 // Function to replace newline characters with <br />
 const formatText = (text, isTooltip = false) => {
  if (!text) return "";
  if (!description) {
   return text?.replace(/\n/g, "<br />");
  }
  if (!isTooltip) {
   return text + `\n...`.replace(/\n/g, "<br />");
  }
  return `${text}\n${description}`.replace(/\n/g, "<br />");
 };

 const renderTooltip = (props) => (
  <Tooltip id="tooltip">
   <span dangerouslySetInnerHTML={{ __html: formatText(props, true) }} />
  </Tooltip>
 );

 const [show, setShow] = useState(false);

 useEffect(() => {
  if (show) {
   const timer = setTimeout(() => setShow(false), 2000);
   return () => clearTimeout(timer);
  }
 }, [show]);
 const backgroundColor = (status) => {
  switch (status) {
   case "hadir":
    if (late > 0 && late <= lateInTolerance) {
     return "rgb(251, 217, 117)";
    } else if (late > lateInTolerance) {
     return "#f29900";
    }
    return "rgb(169, 199, 169)";
   case "sakit":
    return "rgb(251, 217, 117)";
   case "hanya lembur":
    return "#7f8c8d";
   default:
    return "rgb(242, 153, 153)";
  }
 };
 /* "select...",
    "hadir",
    "sakit",
    "sakit non surat",
    "izin",
    "absen",
    "cuti tahunan",
    "cuti istri melahirkan",
    "cuti melahirkan",
    "cuti anggota keluarga satu rumah meninggal",*/
 const icon = (status) => {
  switch (status) {
   case "hadir":
    return (
     <span dangerouslySetInnerHTML={{ __html: formatText(buttonText) }} />
    );
   case "hanya lembur":
    return (
     <span dangerouslySetInnerHTML={{ __html: formatText(buttonText) }} />
    );
   case "sakit":
    return `${
     clockIn && clockOut
      ? `${dayjs(clockIn).format("HH:mm")} - ${dayjs(clockOut).format(
         "HH:mm"
        )}\n`
      : ""
    }${description ? `sakit ....` : "sakit"}`;
   case "cuti tahunan":
    return description ? "cuti ...." : "cuti";
   case "cuti istri melahirkan":
    return description ? "cuti ...." : "cuti";
   case "cuti melahirkan":
    return description ? "cuti ...." : "cuti";
   case "cuti anggota keluarga satu rumah meninggal":
    return description ? "cuti ...." : "cuti";
   case undefined:
    if (!date || !dateIn || (dateResign && dateIn && date)) {
     if (dateResign) {
      const isAfterResigned =
       dayjs(date).isAfter(dateResign, "day") ||
       dayjs(date).isSame(dateResign, "day");
      if (isAfterResigned) {
       return (
        <i
         className="fas fa-sign-out-alt"
         style={{ color: "red", paddingRight: "2px" }}
        />
       );
      }
     }
     return <i className="fas fa-times" style={{ color: "red" }}></i>;
    }
    return date && dayjs(date).isBefore(dateIn, "day") ? (
     <i
      className="fas fa-sign-in-alt"
      style={{ color: "red", paddingRight: "2px" }}
     />
    ) : (
     <i className="fas fa-times" style={{ color: "red" }}></i>
    );
   default:
    return description ? `${status} ....` : status;
  }
 };
 return (
  <OverlayTrigger
   placement="top"
   delay={{ show: 200, hide: 200 }}
   overlay={renderTooltip(text)}
  >
   <button
    type="button"
    className={
     status === "hadir"
      ? "attendance-hadir-button"
      : "attendance-tidakhadir-button"
    }
    style={{
     backgroundColor: backgroundColor(status),
     fontWeight: status === "hadir" ? "" : "500",
     fontSize: "9px",
     minHeight: "50px",
     maxHeight: "65px",
     minWidth: "28px",
     maxWidth: "30px",
     alignItems: "center",
    }}
   >
    {icon(status)}
   </button>
  </OverlayTrigger>
 );
};

function TooltipButtonSimple({
 text,
 variant = "light",
 buttonText,
 buttonBackgroundColor = null,
 buttonColor = "black",
 buttonFontSize = "8px",
 buttonMaxHeight,
}) {
 const renderTooltip = (props) => (
  <Tooltip id="button-tooltip" {...props}>
   {text}
  </Tooltip>
 );

 return (
  <OverlayTrigger
   placement="top"
   delay={{ show: 250, hide: 400 }}
   overlay={text ? renderTooltip : <></>}
  >
   <Button
    size="sm"
    variant={buttonBackgroundColor ? "" : variant}
    className="d-flex align-items-center"
    style={{
     verticalAlign: "middle", // Vertical centering
     borderRadius: "5px",
     fontSize: buttonFontSize,
     color: buttonColor,
     backgroundColor: buttonBackgroundColor,
     height: "20px",
     maxHeight: buttonMaxHeight,
    }}
   >
    {buttonText}
   </Button>
  </OverlayTrigger>
 );
}

export { TooltipIcon, TooltipButton, TooltipButtonSimple };
