import React from "react";
import { Row, Col } from "react-bootstrap";
import { getWorkDuration } from "../../utils/utils";

const WorkDuration = ({
 dateIn,
 dateResign,
 className = "justify-content-md-center",
}) => {
 if (!dateIn) return "";

 return (
  <Row className={className}>
   <Col md="auto" className="d-flex justify-content-center">
    <i
     className="fas fa-briefcase"
     style={{ color: "#7f8c8d", marginRight: "5px" }}
    />
    {getWorkDuration(dateIn, dateResign)}
   </Col>
  </Row>
 );
};

export default WorkDuration;
