import {
 OUTLET_LIST_REQUEST,
 OUTLET_LIST_SUCCESS,
 OUTLET_LIST_ERROR,
 OUTLET_ADD_ERROR,
 OUTLET_ADD_REQUEST,
 OUTLET_ADD_SUCCESS,
 OUTLET_UPDATE_ERROR,
 OUTLET_UPDATE_REQUEST,
 OUTLET_UPDATE_SUCCESS,
 OUTLET_UPDATE_RESET,
 OUTLET_DELETE_ERROR,
 OUTLET_DELETE_REQUEST,
 OUTLET_DELETE_SUCCESS,
 OUTLET_DELETE_RESET,
 OUTLET_DETAILS_REQUEST,
 OUTLET_DETAILS_SUCCESS,
 OUTLET_DETAILS_ERROR,
} from "../constants/outletConstant";

export const outletListReducer = (state = { outlets: [] }, action) => {
 switch (action.type) {
  case OUTLET_LIST_REQUEST:
   return { ...state, loading: true };
  case OUTLET_LIST_SUCCESS:
   return {
    loading: false,
    outlets: action.payload.outlets,
   };
  case OUTLET_LIST_ERROR:
   return { loading: false, error: action.payload };

  default:
   return state;
 }
};

export const outletAddReducer = (state = { outlets: {} }, action) => {
 switch (action.type) {
  case OUTLET_ADD_REQUEST:
   return { ...state, loading: true };
  case OUTLET_ADD_SUCCESS:
   return { loading: false, success: true };
  case OUTLET_ADD_ERROR:
   return { loading: false, error: action.payload };

  default:
   return state;
 }
};

export const outletDetailsReducer = (state = {}, action) => {
 switch (action.type) {
  case OUTLET_DETAILS_REQUEST:
   return { ...state, loading: true };
  case OUTLET_DETAILS_SUCCESS:
   return { loading: false, outlet: action.payload };
  case OUTLET_DETAILS_ERROR:
   return { loading: false, error: action.payload };

  default:
   return state;
 }
};

export const outletUpdateReducer = (state = {}, action) => {
 switch (action.type) {
  case OUTLET_UPDATE_REQUEST:
   return { ...state, loading: true };
  case OUTLET_UPDATE_SUCCESS:
   return { loading: false, success: true };
  case OUTLET_UPDATE_ERROR:
   return { loading: false, error: action.payload };
  case OUTLET_UPDATE_RESET:
   return {};

  default:
   return state;
 }
};

export const outletDeleteReducer = (state = {}, action) => {
 switch (action.type) {
  case OUTLET_DELETE_REQUEST:
   return { ...state, loading: true };
  case OUTLET_DELETE_SUCCESS:
   return { loading: false, success: true };
  case OUTLET_DELETE_ERROR:
   return { loading: false, error: action.payload };
  case OUTLET_DELETE_RESET:
   return {};

  default:
   return state;
 }
};
