export const STATUS_LIST_REQUEST = "STATUS_LIST_REQUEST";
export const STATUS_LIST_SUCCESS = "STATUS_LIST_SUCCESS";
export const STATUS_LIST_ERROR = "STATUS_LIST_ERROR";

export const STATUS_ADD_REQUEST = "STATUS_ADD_REQUEST";
export const STATUS_ADD_SUCCESS = "STATUS_ADD_SUCCESS";
export const STATUS_ADD_ERROR = "STATUS_ADD_ERROR";

export const STATUS_DETAILS_REQUEST = "STATUS_DETAILS_REQUEST";
export const STATUS_DETAILS_SUCCESS = "STATUS_DETAILS_SUCCESS";
export const STATUS_DETAILS_ERROR = "STATUS_DETAILS_ERROR";

export const STATUS_UPDATE_REQUEST = "STATUS_UPDATE_REQUEST";
export const STATUS_UPDATE_SUCCESS = "STATUS_UPDATE_SUCCESS";
export const STATUS_UPDATE_ERROR = "STATUS_UPDATE_ERROR";
export const STATUS_UPDATE_RESET = "STATUS_UPDATE_RESET";

export const STATUS_DELETE_REQUEST = "STATUS_DELETE_REQUEST";
export const STATUS_DELETE_SUCCESS = "STATUS_DELETE_SUCCESS";
export const STATUS_DELETE_ERROR = "STATUS_DELETE_ERROR";
export const STATUS_DELETE_RESET = "STATUS_DELETE_RESET";
