import {
 BANK_LIST_REQUEST,
 BANK_LIST_SUCCESS,
 BANK_LIST_ERROR,
 BANK_ADD_ERROR,
 BANK_ADD_REQUEST,
 BANK_ADD_SUCCESS,
 BANK_UPDATE_ERROR,
 BANK_UPDATE_REQUEST,
 BANK_UPDATE_SUCCESS,
 BANK_UPDATE_RESET,
 BANK_DELETE_ERROR,
 BANK_DELETE_REQUEST,
 BANK_DELETE_SUCCESS,
 BANK_DELETE_RESET,
 BANK_DETAILS_REQUEST,
 BANK_DETAILS_SUCCESS,
 BANK_DETAILS_ERROR,
} from "../constants/bankConstant";

export const bankListReducer = (state = { banks: [] }, action) => {
 switch (action.type) {
  case BANK_LIST_REQUEST:
   return { ...state, loading: true };
  case BANK_LIST_SUCCESS:
   return {
    loading: false,
    banks: action.payload.banks,
   };
  case BANK_LIST_ERROR:
   return { loading: false, error: action.payload };

  default:
   return state;
 }
};

export const bankAddReducer = (state = { banks: {} }, action) => {
 switch (action.type) {
  case BANK_ADD_REQUEST:
   return { ...state, loading: true };
  case BANK_ADD_SUCCESS:
   return { loading: false, success: true };
  case BANK_ADD_ERROR:
   return { loading: false, error: action.payload };

  default:
   return state;
 }
};

export const bankDetailsReducer = (state = {}, action) => {
 switch (action.type) {
  case BANK_DETAILS_REQUEST:
   return { ...state, loading: true };
  case BANK_DETAILS_SUCCESS:
   return { loading: false, bank: action.payload };
  case BANK_DETAILS_ERROR:
   return { loading: false, error: action.payload };

  default:
   return state;
 }
};

export const bankUpdateReducer = (state = {}, action) => {
 switch (action.type) {
  case BANK_UPDATE_REQUEST:
   return { ...state, loading: true };
  case BANK_UPDATE_SUCCESS:
   return { loading: false, success: true };
  case BANK_UPDATE_ERROR:
   return { loading: false, error: action.payload };
  case BANK_UPDATE_RESET:
   return {};

  default:
   return state;
 }
};

export const bankDeleteReducer = (state = {}, action) => {
 switch (action.type) {
  case BANK_DELETE_REQUEST:
   return { ...state, loading: true };
  case BANK_DELETE_SUCCESS:
   return { loading: false, success: true };
  case BANK_DELETE_ERROR:
   return { loading: false, error: action.payload };
  case BANK_DELETE_RESET:
   return {};

  default:
   return state;
 }
};
