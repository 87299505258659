import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";

const GoBackButton = ({ text = `Go Back`, paddingLeft = "15px" }) => {
 const history = useHistory();

 const goBack = () => {
  history.goBack(); // This function will take you back to the previous page in history
 };

 return (
  <div style={{ paddingLeft: paddingLeft }}>
   <Button onClick={goBack} variant="secondary" style={{ marginRight: "10px" }}>
    {text}
   </Button>
  </div>
 );
};

export default GoBackButton;
