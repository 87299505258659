import {
 POSITION_LIST_REQUEST,
 POSITION_LIST_SUCCESS,
 POSITION_LIST_ERROR,
 POSITION_ADD_ERROR,
 POSITION_ADD_REQUEST,
 POSITION_ADD_SUCCESS,
 POSITION_UPDATE_ERROR,
 POSITION_UPDATE_REQUEST,
 POSITION_UPDATE_SUCCESS,
 POSITION_UPDATE_RESET,
 POSITION_DELETE_ERROR,
 POSITION_DELETE_REQUEST,
 POSITION_DELETE_SUCCESS,
 POSITION_DELETE_RESET,
 POSITION_DETAILS_REQUEST,
 POSITION_DETAILS_SUCCESS,
 POSITION_DETAILS_ERROR,
} from "../constants/positionConstant";

export const positionListReducer = (state = { positions: [] }, action) => {
 switch (action.type) {
  case POSITION_LIST_REQUEST:
   return { ...state, loading: true };
  case POSITION_LIST_SUCCESS:
   return {
    loading: false,
    positions: action.payload.positions,
   };
  case POSITION_LIST_ERROR:
   return { loading: false, error: action.payload };

  default:
   return state;
 }
};

export const positionAddReducer = (state = { positions: {} }, action) => {
 switch (action.type) {
  case POSITION_ADD_REQUEST:
   return { ...state, loading: true };
  case POSITION_ADD_SUCCESS:
   return { loading: false, success: true };
  case POSITION_ADD_ERROR:
   return { loading: false, error: action.payload };

  default:
   return state;
 }
};

export const positionDetailsReducer = (state = {}, action) => {
 switch (action.type) {
  case POSITION_DETAILS_REQUEST:
   return { ...state, loading: true };
  case POSITION_DETAILS_SUCCESS:
   return { loading: false, position: action.payload };
  case POSITION_DETAILS_ERROR:
   return { loading: false, error: action.payload };

  default:
   return state;
 }
};

export const positionUpdateReducer = (state = {}, action) => {
 switch (action.type) {
  case POSITION_UPDATE_REQUEST:
   return { ...state, loading: true };
  case POSITION_UPDATE_SUCCESS:
   return { loading: false, success: true };
  case POSITION_UPDATE_ERROR:
   return { loading: false, error: action.payload };
  case POSITION_UPDATE_RESET:
   return {};

  default:
   return state;
 }
};

export const positionDeleteReducer = (state = {}, action) => {
 switch (action.type) {
  case POSITION_DELETE_REQUEST:
   return { ...state, loading: true };
  case POSITION_DELETE_SUCCESS:
   return { loading: false, success: true };
  case POSITION_DELETE_ERROR:
   return { loading: false, error: action.payload };
  case POSITION_DELETE_RESET:
   return {};

  default:
   return state;
 }
};
