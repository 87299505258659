import React from "react";

const IconShirt = ({ size, fontSize = 0 }) => {
 if (!size) {
  return <></>;
 }
 const findSize = (size) => {
  switch (size) {
   case "XS":
    return "12px";
   case "S":
    return "14px";
   case "M":
    return "14px";
   case "L":
    return "14px";
   case "XL":
    return "12px";
   case "XXL":
    return "10px";
   case "XXXL":
    return "10px";
   default:
    return "12px";
  }
 };

 const findLabel = (size) => {
  switch (size) {
   case "XS":
    return size;
   case "S":
    return size;
   case "M":
    return size;
   case "L":
    return size;
   case "XL":
    return size;
   case "XXL":
    return "2XL";
   case "XXXL":
    return "3XL";
   default:
    return size;
  }
 };
 return (
  <div className="logo-container">
   <i
    style={{ fontSize: fontSize ? fontSize : "2em" }}
    className="fas fa-tshirt shirt-icon"
   ></i>
   <span className="size-label" style={{ fontSize: `${findSize(size)}` }}>
    {findLabel(size)}
   </span>
  </div>
 );
};

export default IconShirt;
