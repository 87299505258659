export const EMPLOYEE_LIST_REQUEST = "EMPLOYEE_LIST_REQUEST";
export const EMPLOYEE_LIST_SUCCESS = "EMPLOYEE_LIST_SUCCESS";
export const EMPLOYEE_LIST_ERROR = "EMPLOYEE_LIST_ERROR";

export const EMPLOYEE_ADD_REQUEST = "EMPLOYEE_ADD_REQUEST";
export const EMPLOYEE_ADD_SUCCESS = "EMPLOYEE_ADD_SUCCESS";
export const EMPLOYEE_ADD_ERROR = "EMPLOYEE_ADD_ERROR";

export const EMPLOYEE_DETAILS_REQUEST = "EMPLOYEE_DETAILS_REQUEST";
export const EMPLOYEE_DETAILS_SUCCESS = "EMPLOYEE_DETAILS_SUCCESS";
export const EMPLOYEE_DETAILS_ERROR = "EMPLOYEE_DETAILS_ERROR";

export const EMPLOYEE_UPDATE_REQUEST = "EMPLOYEE_UPDATE_REQUEST";
export const EMPLOYEE_UPDATE_SUCCESS = "EMPLOYEE_UPDATE_SUCCESS";
export const EMPLOYEE_UPDATE_ERROR = "EMPLOYEE_UPDATE_ERROR";
export const EMPLOYEE_UPDATE_RESET = "EMPLOYEE_UPDATE_RESET";

export const EMPLOYEE_DELETE_REQUEST = "EMPLOYEE_DELETE_REQUEST";
export const EMPLOYEE_DELETE_SUCCESS = "EMPLOYEE_DELETE_SUCCESS";
export const EMPLOYEE_DELETE_ERROR = "EMPLOYEE_DELETE_ERROR";
export const EMPLOYEE_DELETE_RESET = "EMPLOYEE_DELETE_RESET";

export const EMPLOYEE_ROOM_NO_REQUEST = "EMPLOYEE_ROOM_NO_REQUEST";
export const EMPLOYEE_ROOM_NO_SUCCESS = "EMPLOYEE_ROOM_NO_SUCCESS";
export const EMPLOYEE_ROOM_NO_ERROR = "EMPLOYEE_ROOM_NO_ERROR";
export const EMPLOYEE_ROOM_NO_RESET = "EMPLOYEE_ROOM_NO_RESET";
