export const GOOGLESHEET_LIST_REQUEST = "GOOGLESHEET_LIST_REQUEST";
export const GOOGLESHEET_LIST_SUCCESS = "GOOGLESHEET_LIST_SUCCESS";
export const GOOGLESHEET_LIST_ERROR = "GOOGLESHEET_LIST_ERROR";

export const GOOGLESHEET_ADD_REQUEST = "GOOGLESHEET_ADD_REQUEST";
export const GOOGLESHEET_ADD_SUCCESS = "GOOGLESHEET_ADD_SUCCESS";
export const GOOGLESHEET_ADD_ERROR = "GOOGLESHEET_ADD_ERROR";

export const GOOGLESHEET_DETAILS_REQUEST = "GOOGLESHEET_DETAILS_REQUEST";
export const GOOGLESHEET_DETAILS_SUCCESS = "GOOGLESHEET_DETAILS_SUCCESS";
export const GOOGLESHEET_DETAILS_ERROR = "GOOGLESHEET_DETAILS_ERROR";

export const GOOGLESHEET_EXECUTE_REQUEST = "GOOGLESHEET_EXECUTE_REQUEST";
export const GOOGLESHEET_EXECUTE_SUCCESS = "GOOGLESHEET_EXECUTE_SUCCESS";
export const GOOGLESHEET_EXECUTE_ERROR = "GOOGLESHEET_EXECUTE_ERROR";

export const GOOGLESHEET_UPDATE_REQUEST = "GOOGLESHEET_UPDATE_REQUEST";
export const GOOGLESHEET_UPDATE_SUCCESS = "GOOGLESHEET_UPDATE_SUCCESS";
export const GOOGLESHEET_UPDATE_ERROR = "GOOGLESHEET_UPDATE_ERROR";
export const GOOGLESHEET_UPDATE_RESET = "GOOGLESHEET_UPDATE_RESET";

export const GOOGLESHEET_DELETE_REQUEST = "GOOGLESHEET_DELETE_REQUEST";
export const GOOGLESHEET_DELETE_SUCCESS = "GOOGLESHEET_DELETE_SUCCESS";
export const GOOGLESHEET_DELETE_ERROR = "GOOGLESHEET_DELETE_ERROR";
export const GOOGLESHEET_DELETE_RESET = "GOOGLESHEET_DELETE_RESET";

export const GOOGLESHEET_ROOM_NO_REQUEST = "GOOGLESHEET_ROOM_NO_REQUEST";
export const GOOGLESHEET_ROOM_NO_SUCCESS = "GOOGLESHEET_ROOM_NO_SUCCESS";
export const GOOGLESHEET_ROOM_NO_ERROR = "GOOGLESHEET_ROOM_NO_ERROR";
export const GOOGLESHEET_ROOM_NO_RESET = "GOOGLESHEET_ROOM_NO_RESET";
