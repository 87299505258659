import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Form, Row, Col, FloatingLabel, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../../components/message";
import Loader from "../../components/loader";
import FormContainer from "../../components/formContainer";
import { login } from "../../actions/userActions";
import { LandingPageBgImage } from "../../components/backgroundImage";
import { Logo } from "../../components/logo";

const LoginView = ({ location, history }) => {
 const [email, setEmail] = useState("");
 const [password, setPassword] = useState("");

 const dispatch = useDispatch();

 const userLogin = useSelector((state) => state.userLogin);
 const { loading, error, userInfo } = userLogin;

 const redirect = location.search ? location.search.split("=")[1] : "/";

 useEffect(() => {
  if (userInfo) {
   history.push(redirect);
  }
 }, [history, userInfo, redirect]);

 const submitHandler = (e) => {
  e.preventDefault();
  dispatch(login(email, password));
 };

 return (
  <>
   {error && <Message variant="danger">{error}</Message>}
   {loading ? (
    <Loader />
   ) : (
    <>
     <LandingPageBgImage>
      <FormContainer>
       {/*eslint-disable-next-line*/}
       <h3></h3>
       <Logo />
       <Form onSubmit={submitHandler}>
        <FloatingLabel
         controlId="floatingInput"
         label="Email address"
         className="mb-3 mt-3"
        >
         <Form.Control
          type="email"
          placeholder="name@example.com"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          style={{ borderRadius: 5, width: "100%" }}
         />
        </FloatingLabel>
        <FloatingLabel
         controlId="floatingPassword"
         label="Password"
         className="mb-3"
        >
         <Form.Control
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          style={{ borderRadius: 5 }}
         />
        </FloatingLabel>
        <Button
         type="submit"
         style={{ width: "100%" }}
         variant="danger"
         className="mb-3"
        >
         Sign In
        </Button>
       </Form>
       <Row className="mb-3">
        <Col style={{ justifyContent: "center", display: "flex" }}>
         <Link to="/forgot-password">Forgot your password?</Link>
        </Col>
       </Row>
       <Row className="mb-3" style={{ marginBottom: "40px" }}>
        <Col style={{ justifyContent: "center", display: "flex" }}>
         Don't have an account?{" "}
         <Link to={redirect ? `/register?redirect=${redirect}` : "/register"}>
          Register
         </Link>
        </Col>
       </Row>
      </FormContainer>
     </LandingPageBgImage>
    </>
   )}
  </>
 );
};

export default LoginView;
