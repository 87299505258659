import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {} from "./actions/studentActions";
import {
 studentListReducer,
 studentAddReducer,
 studentDetailsReducer,
 getStudentsByRoomNoReducer,
 studentUpdateReducer,
 studentDeleteReducer,
} from "./reducers/studentsReducer";
import {
 userLoginReducer,
 userRegisterReducer,
 userDetailsReducer,
 userUpdateProfileReducer,
 userListReducer,
 userDeleteReducer,
 userUpdateReducer,
} from "./reducers/userReducers";
import {
 attendanceDataEnterReducer,
 attendanceAnalysisReducer,
 deleteAttendanceReducer,
 attendanceDetailsReducer,
 attendanceUpdateReducer,
 attendanceFileUploadReducer,
} from "./reducers/attendanceReducer";
import {} from "./actions/employeeActions";
import {
 employeeListReducer,
 employeeAddReducer,
 employeeDetailsReducer,
 getEmployeesByRoomNoReducer,
 employeeUpdateReducer,
 employeeDeleteReducer,
} from "./reducers/employeesReducer";
import {
 sheetListReducer,
 sheetAddReducer,
 sheetDetailsReducer,
 sheetUpdateReducer,
 sheetDeleteReducer,
} from "./reducers/sheetsReducer";
import {
 leaveListReducer,
 leaveAddReducer,
 leaveDetailsReducer,
 leaveUpdateReducer,
 leaveDeleteReducer,
} from "./reducers/leaveReducer";
import { googlesheetExecuteReducer } from "./reducers/googlesheetReducer";
import { profilePictureUploadReducer } from "./reducers/profilePictureReducer";
import {
 attendanceSummaryDataEnterReducer,
 attendanceSummaryAnalysisReducer,
 deleteAttendanceSummaryReducer,
 attendanceSummaryDetailsReducer,
 attendanceSummaryUpdateReducer,
} from "./reducers/attendanceSummaryReducer";
import {
 outletListReducer,
 outletAddReducer,
 outletDetailsReducer,
 outletUpdateReducer,
 outletDeleteReducer,
} from "./reducers/outletReducer";
import {
 settingsAddReducer,
 settingsDetailsReducer,
 settingsUpdateReducer,
 settingsDeleteReducer,
} from "./reducers/settingsReducer";
import {
 bankListReducer,
 bankAddReducer,
 bankDetailsReducer,
 bankUpdateReducer,
 bankDeleteReducer,
} from "./reducers/bankReducer";
import {
 statusListReducer,
 statusAddReducer,
 statusDetailsReducer,
 statusUpdateReducer,
 statusDeleteReducer,
} from "./reducers/statusReducer";
import {
 positionListReducer,
 positionAddReducer,
 positionDetailsReducer,
 positionUpdateReducer,
 positionDeleteReducer,
} from "./reducers/positionReducer";

const reducer = combineReducers({
 studentsList: studentListReducer,
 studentDetails: studentDetailsReducer,
 studentAdd: studentAddReducer,
 studentUpdate: studentUpdateReducer,
 studentDelete: studentDeleteReducer,
 getStudentsByRoomNo: getStudentsByRoomNoReducer,
 userLogin: userLoginReducer,
 userRegister: userRegisterReducer,
 userDetails: userDetailsReducer,
 userUpdateProfile: userUpdateProfileReducer,
 userList: userListReducer,
 userDelete: userDeleteReducer,
 userUpdate: userUpdateReducer,

 attendanceDataEnter: attendanceDataEnterReducer,
 attendanceAnalysis: attendanceAnalysisReducer,
 attendanceDelete: deleteAttendanceReducer,
 attendanceDetails: attendanceDetailsReducer,
 attendanceUpdate: attendanceUpdateReducer,
 attendanceFileUpload: attendanceFileUploadReducer,

 employeesList: employeeListReducer,
 employeeDetails: employeeDetailsReducer,
 employeeAdd: employeeAddReducer,
 employeeUpdate: employeeUpdateReducer,
 employeeDelete: employeeDeleteReducer,
 getEmployeesByRoomNo: getEmployeesByRoomNoReducer,

 sheetsList: sheetListReducer,
 sheetDetails: sheetDetailsReducer,
 sheetAdd: sheetAddReducer,
 sheetUpdate: sheetUpdateReducer,
 sheetDelete: sheetDeleteReducer,

 leavesList: leaveListReducer,
 leaveDetails: leaveDetailsReducer,
 leaveAdd: leaveAddReducer,
 leaveUpdate: leaveUpdateReducer,
 leaveDelete: leaveDeleteReducer,

 googlesheetExecute: googlesheetExecuteReducer,
 profilePictureUpload: profilePictureUploadReducer,

 attendanceSummaryDataEnter: attendanceSummaryDataEnterReducer,
 attendanceSummaryAnalysis: attendanceSummaryAnalysisReducer,
 attendanceSummaryDelete: deleteAttendanceSummaryReducer,
 attendanceSummaryDetails: attendanceSummaryDetailsReducer,
 attendanceSummaryUpdate: attendanceSummaryUpdateReducer,

 outletList: outletListReducer,
 outletDetails: outletDetailsReducer,
 outletAdd: outletAddReducer,
 outletUpdate: outletUpdateReducer,
 outletDelete: outletDeleteReducer,

 settingsDetails: settingsDetailsReducer,
 settingsAdd: settingsAddReducer,
 settingsUpdate: settingsUpdateReducer,
 settingsDelete: settingsDeleteReducer,

 bankList: bankListReducer,
 bankDetails: bankDetailsReducer,
 bankAdd: bankAddReducer,
 bankUpdate: bankUpdateReducer,
 bankDelete: bankDeleteReducer,

 statusList: statusListReducer,
 statusDetails: statusDetailsReducer,
 statusAdd: statusAddReducer,
 statusUpdate: statusUpdateReducer,
 statusDelete: statusDeleteReducer,

 positionList: positionListReducer,
 positionDetails: positionDetailsReducer,
 positionAdd: positionAddReducer,
 positionUpdate: positionUpdateReducer,
 positionDelete: positionDeleteReducer,
});
const userInfoFromStorage = localStorage.getItem("userInfo")
 ? JSON.parse(localStorage.getItem("userInfo"))
 : null;

const settingsFromStorage = localStorage.getItem("settings")
 ? JSON.parse(localStorage.getItem("settings"))
 : null;

const initialState = {
 userLogin: { userInfo: userInfoFromStorage },
 settingsDetails: { settings: settingsFromStorage },
};

const middleware = [thunk];
const store = createStore(
 reducer,
 initialState,
 composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
