import React from "react";
import dayjs from "dayjs";

export const useSortableData = (items, config = null) => {
 const [sortConfig, setSortConfig] = React.useState(config);

 const sortedItems = React.useMemo(() => {
  let sortableItems = items?.length ? [...items] : items;

  if (sortConfig !== null && items?.length) {
   sortableItems.sort((a, b) => {
    const aValue = a[sortConfig.key];
    const bValue = b[sortConfig.key];

    // Since date format is ISO string, we directly parse and compare
    const aIsDate = dayjs(aValue, dayjs.ISO_8601, true).isValid();
    const bIsDate = dayjs(bValue, dayjs.ISO_8601, true).isValid();

    // If both are valid dates, compare them
    if (aIsDate && bIsDate) {
     return sortConfig.direction === "ascending"
      ? dayjs(aValue).valueOf() - dayjs(bValue).valueOf()
      : dayjs(bValue).valueOf() - dayjs(aValue).valueOf();
    }

    // If not dates, use standard comparison
    if (aValue < bValue) {
     return sortConfig.direction === "ascending" ? -1 : 1;
    }
    if (aValue > bValue) {
     return sortConfig.direction === "ascending" ? 1 : -1;
    }
    return 0;
   });
  }

  return sortableItems;
 }, [items, sortConfig]);

 const requestSort = (key) => {
  let direction = "ascending";

  // If already sorted ascending, change to descending
  if (
   sortConfig &&
   sortConfig.key === key &&
   sortConfig.direction === "ascending"
  ) {
   direction = "descending";
  }
  // If already sorted descending, reset to default (no sort)
  else if (
   sortConfig &&
   sortConfig.key === key &&
   sortConfig.direction === "descending"
  ) {
   setSortConfig(null); // Reset to default
   return;
  }

  setSortConfig({ key, direction });
 };

 const getClassNamesFor = (name) => {
  if (!sortConfig) {
   return;
  }
  return sortConfig.key === name ? sortConfig.direction : undefined;
 };

 return { items: sortedItems, requestSort, sortConfig, getClassNamesFor };
};
