export const OUTLET_LIST_REQUEST = "OUTLET_LIST_REQUEST";
export const OUTLET_LIST_SUCCESS = "OUTLET_LIST_SUCCESS";
export const OUTLET_LIST_ERROR = "OUTLET_LIST_ERROR";

export const OUTLET_ADD_REQUEST = "OUTLET_ADD_REQUEST";
export const OUTLET_ADD_SUCCESS = "OUTLET_ADD_SUCCESS";
export const OUTLET_ADD_ERROR = "OUTLET_ADD_ERROR";

export const OUTLET_DETAILS_REQUEST = "OUTLET_DETAILS_REQUEST";
export const OUTLET_DETAILS_SUCCESS = "OUTLET_DETAILS_SUCCESS";
export const OUTLET_DETAILS_ERROR = "OUTLET_DETAILS_ERROR";

export const OUTLET_UPDATE_REQUEST = "OUTLET_UPDATE_REQUEST";
export const OUTLET_UPDATE_SUCCESS = "OUTLET_UPDATE_SUCCESS";
export const OUTLET_UPDATE_ERROR = "OUTLET_UPDATE_ERROR";
export const OUTLET_UPDATE_RESET = "OUTLET_UPDATE_RESET";

export const OUTLET_DELETE_REQUEST = "OUTLET_DELETE_REQUEST";
export const OUTLET_DELETE_SUCCESS = "OUTLET_DELETE_SUCCESS";
export const OUTLET_DELETE_ERROR = "OUTLET_DELETE_ERROR";
export const OUTLET_DELETE_RESET = "OUTLET_DELETE_RESET";
