export const LEAVE_LIST_REQUEST = "LEAVE_LIST_REQUEST";
export const LEAVE_LIST_SUCCESS = "LEAVE_LIST_SUCCESS";
export const LEAVE_LIST_ERROR = "LEAVE_LIST_ERROR";

export const LEAVE_ADD_REQUEST = "LEAVE_ADD_REQUEST";
export const LEAVE_ADD_SUCCESS = "LEAVE_ADD_SUCCESS";
export const LEAVE_ADD_ERROR = "LEAVE_ADD_ERROR";

export const LEAVE_DETAILS_REQUEST = "LEAVE_DETAILS_REQUEST";
export const LEAVE_DETAILS_SUCCESS = "LEAVE_DETAILS_SUCCESS";
export const LEAVE_DETAILS_ERROR = "LEAVE_DETAILS_ERROR";

export const LEAVE_UPDATE_REQUEST = "LEAVE_UPDATE_REQUEST";
export const LEAVE_UPDATE_SUCCESS = "LEAVE_UPDATE_SUCCESS";
export const LEAVE_UPDATE_ERROR = "LEAVE_UPDATE_ERROR";
export const LEAVE_UPDATE_RESET = "LEAVE_UPDATE_RESET";

export const LEAVE_DELETE_REQUEST = "LEAVE_DELETE_REQUEST";
export const LEAVE_DELETE_SUCCESS = "LEAVE_DELETE_SUCCESS";
export const LEAVE_DELETE_ERROR = "LEAVE_DELETE_ERROR";
export const LEAVE_DELETE_RESET = "LEAVE_DELETE_RESET";

export const LEAVE_ROOM_NO_REQUEST = "LEAVE_ROOM_NO_REQUEST";
export const LEAVE_ROOM_NO_SUCCESS = "LEAVE_ROOM_NO_SUCCESS";
export const LEAVE_ROOM_NO_ERROR = "LEAVE_ROOM_NO_ERROR";
export const LEAVE_ROOM_NO_RESET = "LEAVE_ROOM_NO_RESET";
