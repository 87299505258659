import React from "react";
import { useSelector } from "react-redux";
import Message from "../components/message";
import Loader from "../components/loader";
import { Link } from "react-router-dom";
import { capitalizeEachWord } from "../utils/utils";
import { useSortableData } from "../utils/sort";
import { Table } from "react-bootstrap";

const OutletTableView = ({ keyword, outlets, loading, error }) => {
 const userLogin = useSelector((state) => state.userLogin);
 const { userInfo } = userLogin;

 const ProductTable = ({ products }) => {
  const { items, requestSort, getClassNamesFor } = useSortableData(products);

  return (
   <Table striped bordered hover size="sm" className="custom-table">
    <thead className="sticky-header">
     <tr>
      <th style={{ textAlign: "center" }}>
       <button
        type="button"
        onClick={() => requestSort("outletID")}
        className={`${getClassNamesFor("outletID")} sort-btn`}
       >
        Outlet ID
       </button>
      </th>
      <th style={{ textAlign: "left" }}>
       <button
        type="button"
        onClick={() => requestSort("name")}
        className={`${getClassNamesFor("name")} sort-btn`}
       >
        Name
       </button>
      </th>
      <th style={{ textAlign: "left" }}>
       <button
        type="button"
        onClick={() => requestSort("type")}
        className={`${getClassNamesFor("type")} sort-btn`}
       >
        Type
       </button>
      </th>
     </tr>
    </thead>
    <tbody style={{ color: "#000000" }}>
     {userInfo.isAdmin &&
      Array.isArray(items) &&
      items.map(
       (item, index) =>
        item && (
         <tr key={item._id}>
          <td>{item.outletID}</td>
          <td>
           <Link to={`/outlet/${item._id}`} className="link-black">
            {capitalizeEachWord(item.name)}
           </Link>
          </td>
          <td>{item.type ? capitalizeEachWord(item.type) : ""}</td>
         </tr>
        )
      )}
    </tbody>
   </Table>
  );
 };

 return (
  <>
   {loading ? (
    <Loader />
   ) : error ? (
    <Message variant="danger">{error}</Message>
   ) : (
    <ProductTable products={outlets} />
   )}
  </>
 );
};

export default OutletTableView;
