import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import Message from "../components/message";
import Loader from "../components/loader";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { stringIncludes, capitalizeEachWord, getAge } from "../utils/utils";
import { Table } from "react-bootstrap";
import verifiedIcon from "../Assets/icon/verified.png";
import { useSortableData } from "../utils/sort";

const EmployeesTableAgeView = ({ isActiveEmployee, outlet }) => {
 const { loading, error, employees } = useSelector(
  (state) => state.employeesList
 );

 // Memoize sorted employees to prevent recalculation on each render
 const filteredEmployees = useMemo(() => {
  // Pastikan employees adalah array, jika tidak, kembalikan array kosong
  if (!Array.isArray(employees) || !employees.length) {
   return [employees];
  }

  return employees.filter((employee) => {
   const isAllOutlets = stringIncludes(outlet, "all outlets");
   const isCorrectOutlet =
    isAllOutlets || stringIncludes(outlet, employee.outlet);
   const isActive = isActiveEmployee
    ? !employee.dateResign
    : !!employee.dateResign;
   return isActive && isCorrectOutlet;
  });
 }, [employees, isActiveEmployee, outlet]);

 const ProductTable = ({ products }) => {
  const { items, requestSort, getClassNamesFor } = useSortableData(products);

  const renderTableRow = (employee) => (
   <tr key={employee._id}>
    <td>{employee.employeeID}</td>
    <td>
     <Link to={`/employee/${employee._id}`} className="link-black">
      {employee.name}{" "}
      {stringIncludes(employee.status, "tetap") && verifiedIcon && (
       <img
        src={verifiedIcon}
        style={{ width: "15px", height: "15px" }}
        alt="Verified"
       />
      )}
     </Link>
    </td>
    {stringIncludes(outlet, "all outlets") && (
     <td style={{ textAlign: "center" }}>{employee.outlet}</td>
    )}
    <td
     className={
      stringIncludes(employee.status, "tetap") ? "tetap" : "probation"
     }
    >
     {capitalizeEachWord(employee.status)}
    </td>
    <td style={{ textAlign: "center" }}>{employee.placeOfBirth}</td>
    <td style={{ textAlign: "right" }}>
     {employee.dateOfBirth
      ? dayjs(employee.dateOfBirth).format("DD-MMM-YYYY")
      : null}
    </td>
    <td style={{ textAlign: "right" }}>
     {getAge(employee.dateOfBirth, false)}
    </td>
   </tr>
  );

  return (
   <Table striped bordered hover size="sm" className="custom-table">
    <thead className="sticky-header">
     <tr>
      <th style={{ textAlign: "left" }}>
       <button
        type="button"
        onClick={() => requestSort("employeeID")}
        className={getClassNamesFor("employeeID") + " sort-btn"}
       >
        ID
       </button>
      </th>
      <th style={{ textAlign: "left" }}>
       <button
        type="button"
        onClick={() => requestSort("name")}
        className={getClassNamesFor("name") + " sort-btn"}
       >
        Name
       </button>
      </th>
      {stringIncludes(outlet, "ALL OUTLETS") && (
       <th style={{ textAlign: "center" }}>
        <button
         type="button"
         onClick={() => requestSort("outlet")}
         className={getClassNamesFor("outlet") + " sort-btn"}
        >
         Outlet
        </button>
       </th>
      )}
      <th style={{ textAlign: "center" }}>
       <button
        type="button"
        onClick={() => requestSort("status")}
        className={getClassNamesFor("status") + " sort-btn"}
       >
        Status
       </button>
      </th>
      <th style={{ textAlign: "center" }}>
       <button
        type="button"
        onClick={() => requestSort("placeOfBirth")}
        className={getClassNamesFor("placeOfBirth") + " sort-btn"}
       >
        Place of Birth
       </button>
      </th>
      {!stringIncludes(outlet, "all outlets") ? (
       <th style={{ textAlign: "right" }}>
        <button
         type="button"
         onClick={() => requestSort("dateOfBirth")}
         className={getClassNamesFor("dateOfBirth") + " sort-btn"}
        >
         Date of Birth
        </button>
       </th>
      ) : (
       <th style={{ textAlign: "right" }}>Date of Birth</th>
      )}
      <th style={{ textAlign: "right" }}>Age</th>
     </tr>
    </thead>
    <tbody style={{ color: "#000000" }}>{items.map(renderTableRow)}</tbody>
   </Table>
  );
 };

 return loading ? (
  <Loader />
 ) : error ? (
  <Message variant="danger">{error}</Message>
 ) : (
  <ProductTable products={filteredEmployees} />
 );
};

export default EmployeesTableAgeView;
