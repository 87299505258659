import {
 EMPLOYEE_LIST_REQUEST,
 EMPLOYEE_LIST_SUCCESS,
 EMPLOYEE_LIST_ERROR,
 EMPLOYEE_ADD_ERROR,
 EMPLOYEE_ADD_REQUEST,
 EMPLOYEE_ADD_SUCCESS,
 EMPLOYEE_UPDATE_ERROR,
 EMPLOYEE_UPDATE_REQUEST,
 EMPLOYEE_UPDATE_SUCCESS,
 EMPLOYEE_UPDATE_RESET,
 EMPLOYEE_DELETE_ERROR,
 EMPLOYEE_DELETE_REQUEST,
 EMPLOYEE_DELETE_SUCCESS,
 EMPLOYEE_DELETE_RESET,
 EMPLOYEE_DETAILS_REQUEST,
 EMPLOYEE_DETAILS_SUCCESS,
 EMPLOYEE_DETAILS_ERROR,
 EMPLOYEE_ROOM_NO_REQUEST,
 EMPLOYEE_ROOM_NO_SUCCESS,
 EMPLOYEE_ROOM_NO_ERROR,
 EMPLOYEE_ROOM_NO_RESET,
} from "../constants/employeeConstant";

export const employeeListReducer = (state = { employees: [] }, action) => {
 switch (action.type) {
  case EMPLOYEE_LIST_REQUEST:
   return { ...state, loading: true };
  case EMPLOYEE_LIST_SUCCESS:
   return {
    loading: false,
    employees: action.payload.employees,
    pages: action.payload.pages,
    page: action.payload.page,
   };
  case EMPLOYEE_LIST_ERROR:
   return { loading: false, error: action.payload };

  default:
   return state;
 }
};

export const employeeAddReducer = (state = { employees: {} }, action) => {
 switch (action.type) {
  case EMPLOYEE_ADD_REQUEST:
   return { ...state, loading: true };
  case EMPLOYEE_ADD_SUCCESS:
   return { loading: false, success: true };
  case EMPLOYEE_ADD_ERROR:
   return { loading: false, error: action.payload };

  default:
   return state;
 }
};

export const employeeDetailsReducer = (state = {}, action) => {
 switch (action.type) {
  case EMPLOYEE_DETAILS_REQUEST:
   return { ...state, loading: true };
  case EMPLOYEE_DETAILS_SUCCESS:
   return { loading: false, employee: action.payload };
  case EMPLOYEE_DETAILS_ERROR:
   return { loading: false, error: action.payload };

  default:
   return state;
 }
};

export const employeeUpdateReducer = (state = {}, action) => {
 switch (action.type) {
  case EMPLOYEE_UPDATE_REQUEST:
   return { ...state, loading: true };
  case EMPLOYEE_UPDATE_SUCCESS:
   return { loading: false, success: true };
  case EMPLOYEE_UPDATE_ERROR:
   return { loading: false, error: action.payload };
  case EMPLOYEE_UPDATE_RESET:
   return {};

  default:
   return state;
 }
};

export const employeeDeleteReducer = (state = {}, action) => {
 switch (action.type) {
  case EMPLOYEE_DELETE_REQUEST:
   return { ...state, loading: true };
  case EMPLOYEE_DELETE_SUCCESS:
   return { loading: false, success: true };
  case EMPLOYEE_DELETE_ERROR:
   return { loading: false, error: action.payload };
  case EMPLOYEE_DELETE_RESET:
   return {};

  default:
   return state;
 }
};
export const getEmployeesByRoomNoReducer = (state = {}, action) => {
 switch (action.type) {
  case EMPLOYEE_ROOM_NO_REQUEST:
   return { ...state, loading: true };
  case EMPLOYEE_ROOM_NO_SUCCESS:
   return {
    loading: false,
    employees: action.payload.employees,
    attendance: action.payload.attendance,
   };
  case EMPLOYEE_ROOM_NO_ERROR:
   return { loading: false, error: action.payload };
  case EMPLOYEE_ROOM_NO_RESET:
   return {};

  default:
   return state;
 }
};
