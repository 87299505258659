import { React } from "react";

// Komponen untuk menampilkan link email
const EmailLink = ({
 email,
 color = "#D93025",
 fontSize = "1.75em",
 marginLeft = 0,
 marginRight = 0,
}) => {
 if (!email) return "";

 const mailtoUrl = `mailto:${email}`;

 return (
  <a
   href={mailtoUrl}
   target="_blank"
   rel="noopener noreferrer"
   style={{ textDecoration: "none", color: "inherit" }}
  >
   <i
    className="fas fa-envelope"
    style={{
     fontSize: fontSize,
     color: color,
     marginLeft: marginLeft,
     marginRight: marginRight,
    }}
   />
  </a>
 );
};

export { EmailLink };
