import React from "react";
import { Row, Col } from "react-bootstrap";
import IconPosition from "../iconPosition.jsx";
import { capitalizeEachWord } from "../../utils/utils.jsx";

const Position = ({ position, className = "justify-content-md-center" }) => {
 if (!position) return "";

 return (
  <Row className={className}>
   <Col md="auto" className="d-flex justify-content-center">
    <IconPosition position={position} />
    <strong>{capitalizeEachWord(position)}</strong>
   </Col>
  </Row>
 );
};

export default Position;
