import React from "react";
import { Row, Col } from "react-bootstrap";
import dayjs from "dayjs";

const DateIn = ({
 dateIn,
 dateResign,
 className = "justify-content-md-center",
}) => {
 if (!dateIn) return "";
 const marginRight = "5px";

 return (
  <Row className={className}>
   {!dateResign && (
    <Col md="auto">
     {
      <i
       className="fas fa-user"
       style={{ color: "green", marginRight: marginRight }}
      ></i>
     }
     {`${dayjs(dateIn)?.format("DD MMM YYYY")} - present `}
    </Col>
   )}
   {dateResign && (
    <Col md="auto">
     {`${dayjs(dateIn)?.format("DD MMM YYYY")} - ${dayjs(dateResign)?.format(
      "DD MMM YYYY"
     )} `}
     {
      <i
       className="fas fa-sign-out-alt"
       style={{ color: "red", marginRight: marginRight }}
      ></i>
     }
    </Col>
   )}
  </Row>
 );
};

export default DateIn;
