export const STUDENT_LIST_REQUEST = "STUDENT_LIST_REQUEST";
export const STUDENT_LIST_SUCCESS = "STUDENT_LIST_SUCCESS";
export const STUDENT_LIST_ERROR = "STUDENT_LIST_ERROR";

export const STUDENT_ADD_REQUEST = "STUDENT_ADD_REQUEST";
export const STUDENT_ADD_SUCCESS = "STUDENT_ADD_SUCCESS";
export const STUDENT_ADD_ERROR = "STUDENT_ADD_ERROR";

export const STUDENT_DETAILS_REQUEST = "STUDENT_DETAILS_REQUEST";
export const STUDENT_DETAILS_SUCCESS = "STUDENT_DETAILS_SUCCESS";
export const STUDENT_DETAILS_ERROR = "STUDENT_DETAILS_ERROR";

export const STUDENT_UPDATE_REQUEST = "STUDENT_UPDATE_REQUEST";
export const STUDENT_UPDATE_SUCCESS = "STUDENT_UPDATE_SUCCESS";
export const STUDENT_UPDATE_ERROR = "STUDENT_UPDATE_ERROR";
export const STUDENT_UPDATE_RESET = "STUDENT_UPDATE_RESET";

export const STUDENT_DELETE_REQUEST = "STUDENT_DELETE_REQUEST";
export const STUDENT_DELETE_SUCCESS = "STUDENT_DELETE_SUCCESS";
export const STUDENT_DELETE_ERROR = "STUDENT_DELETE_ERROR";
export const STUDENT_DELETE_RESET = "STUDENT_DELETE_RESET";

export const STUDENT_ROOM_NO_REQUEST = "STUDENT_ROOM_NO_REQUEST";
export const STUDENT_ROOM_NO_SUCCESS = "STUDENT_ROOM_NO_SUCCESS";
export const STUDENT_ROOM_NO_ERROR = "STUDENT_ROOM_NO_ERROR";
export const STUDENT_ROOM_NO_RESET = "STUDENT_ROOM_NO_RESET";
