import React, { useEffect, useState, useMemo } from "react";
import dayjs from "dayjs";
import { NavLink, Modal, Form, Button, Row, Col, Table } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import {
 capitalizeEachWord,
 convertSeconds,
 getWorkDuration,
 calculateLate,
 stringIncludes,
} from "../utils/utils";
import { TooltipButton, TooltipButtonSimple } from "../components/tooltip";
import { useDispatch, useSelector } from "react-redux";
import { addAttendance, deleteAttendance } from "../actions/attendanceActions";
import { getAnalysisAttendanceSummaryByDate } from "../actions/attendanceSummaryActions.jsx";
import verifiedIcon from "../Assets/icon/verified.png";
import { useSortableData } from "../utils/sort.jsx";
import PhotoProfile from "../components/employee/photoProfile.jsx";

const AddAttendanceTableView = ({
 keyword,
 isActiveEmployee,
 outlet,
 date,
 userInfo,
}) => {
 // State untuk mengontrol visibilitas popup
 const [showAddAttendance, setShowAddAttendance] = useState(false);
 const [addAttendanceItem, setAddAttendanceItem] = useState({});
 const [addAttendanceDate, setAddAttendanceDate] = useState(null);
 const [addAttendanceStatus, setAddAttendanceStatus] = useState("select...");
 const [addAttendanceClockIn, setAddAttendanceClockIn] = useState(null);
 const [addAttendanceClockOut, setAddAttendanceClockOut] = useState(null);
 const [addAttendanceOvertimeHours, setAddAttendanceOvertimeHours] =
  useState(null);
 const [addAttendanceOvertimeType, setAddAttendanceOvertimeType] =
  useState("normal");
 const [addAttendanceDescription, setAddAttendanceDescription] = useState("");
 const [addAttendanceData, setAddAttendanceData] = useState(null);
 const [addAttendanceId, setAddAttendanceId] = useState(null);

 const dispatch = useDispatch();
 const attendanceSummaryAnalysis = useSelector(
  (state) => state.attendanceSummaryAnalysis
 );
 const { attendanceSummary } = attendanceSummaryAnalysis;
 // Memoized matchingDates logic
 const matchingDates = useMemo(() => {
  if (!addAttendanceDate || !Array.isArray(addAttendanceItem?.attendanceData)) {
   return null; // Handle cases where addAttendanceItem.attendanceData is undefined or not an array
  }

  const targetDate = dayjs(addAttendanceDate);

  return (
   addAttendanceItem.attendanceData.find(
    (item) =>
     dayjs(item.date).format("YYYY-MM-DD") === targetDate.format("YYYY-MM-DD")
   ) || null
  ); // Return null if no match is found
 }, [addAttendanceDate, addAttendanceItem]);

 useEffect(
  () => {
   if (addAttendanceDate && showAddAttendance) {
    setAddAttendanceData(matchingDates);
    if (addAttendanceData) {
     if (addAttendanceData.clockIn && addAttendanceData.clockOut) {
      setAddAttendanceClockIn(dayjs(addAttendanceData.clockIn).format("HH:mm"));
      setAddAttendanceClockOut(
       dayjs(addAttendanceData.clockOut).format("HH:mm")
      );
     } else {
      setAddAttendanceClockIn("");
      setAddAttendanceClockOut("");
     }
     setAddAttendanceOvertimeHours(addAttendanceData.overtimeHours);

     if (!addAttendanceData.overtimeType) {
      setAddAttendanceOvertimeType("normal");
     } else {
      setAddAttendanceOvertimeType(addAttendanceData.overtimeType);
     }
     if (!addAttendanceData.description) {
      setAddAttendanceDescription("");
     } else {
      setAddAttendanceDescription(addAttendanceData.description);
     }

     setAddAttendanceStatus(addAttendanceData.status);
     setAddAttendanceId(addAttendanceData._id);
    } else {
     // Reset all fields if no data is found
     setAddAttendanceClockIn("");
     setAddAttendanceClockOut("");
     setAddAttendanceOvertimeHours(null);
     setAddAttendanceOvertimeType("normal");
     setAddAttendanceDescription("");
     setAddAttendanceStatus("select...");
     setAddAttendanceId(null);
    }
   }
  },
  // eslint-disable-next-line
  [
   addAttendanceDate,
   showAddAttendance,
   addAttendanceData,
   attendanceSummary,
   addAttendanceItem,
  ]
 );

 // Cegah refresh saat submit form
 const handleSubmit = (event) => {
  if (event) {
   event.preventDefault(); // Mencegah halaman refresh
  }
  //console.log({ addAttendanceData });

  dispatch(
   addAttendance({
    _id: addAttendanceItem._id,
    employeeID: addAttendanceItem.employeeID,
    date: addAttendanceDate,
    clockIn: addAttendanceClockIn,
    clockOut: addAttendanceClockOut,
    status: addAttendanceStatus,
    overtimeHours: addAttendanceOvertimeHours,
    overtimeType: addAttendanceOvertimeType,
    attendanceId: addAttendanceId,
    description: addAttendanceDescription,
   })
  );

  // Close modal setelah submit
  handleCloseAddAttendance();
  dispatch(
   getAnalysisAttendanceSummaryByDate(isActiveEmployee, keyword, outlet, date)
  );
 };

 const deleteAttendanceHandler = (event) => {
  if (event) {
   event.preventDefault();
  }
  if (!addAttendanceData) {
   return;
  }
  if (
   window.confirm(
    `Delete${" " + capitalizeEachWord(addAttendanceData.name)} attendance${
     addAttendanceData.date &&
     " on " + dayjs(addAttendanceData.date).format("dddd, DD MMMM YYYY")
    }?`
   )
  ) {
   dispatch(deleteAttendance(addAttendanceId));
   //_id, attendanceId, employeeID, date
   // Close modal setelah delete
   handleCloseAddAttendance();
   dispatch(
    getAnalysisAttendanceSummaryByDate(isActiveEmployee, keyword, outlet, date)
   );
  }
 };

 // Fungsi untuk membuka dan menutup popup
 const handleCloseAddAttendance = (event) => {
  if (event) {
   event.preventDefault();
  }

  setShowAddAttendance(false);
  setAddAttendanceItem({});
  setAddAttendanceDate(null);
  setAddAttendanceStatus(null);
  setAddAttendanceClockIn(null);
  setAddAttendanceClockOut(null);
  setAddAttendanceData(null);
  setAddAttendanceDescription(null);
 };
 const handleShowAddAttendance = (event, item) => {
  if (!item || showAddAttendance) return; // prevent unnecessary state updates
  if (event) {
   event.preventDefault(); // Prevent the default action
  }

  setShowAddAttendance(true);
  setAddAttendanceItem(item);
 };

 const dateForPicker = (date) => {
  return date ? dayjs(date).format("YYYY-MM-DD") : "";
 };

 // Fungsi untuk mengubah string tanggal menjadi objek Date
 const dateFromDateString = (dateString) => {
  return dateString ? dayjs(dateString).toDate() : null;
 };

 const lateInText = (employee, clockIn, status = "hadir") => {
  if (!employee && !clockIn) {
   return "";
  }

  const outlet = employee.outlet;
  const position = employee.position;

  if (!outlet && !position) {
   return "";
  }
  if (
   typeof outlet === "string" &&
   !outlet.toLowerCase().includes("management") &&
   typeof position === "string" &&
   !position.toLowerCase().includes("security")
  ) {
   if (calculateLate(employee, clockIn, 0, status)) {
    return `Late ${calculateLate(employee, clockIn, 0, status)}`;
   }
  }
  return "";
 };

 const lateInNumber = (employee, clockIn, status = "hadir") => {
  if (!employee && !clockIn) {
   return 0;
  }

  const outlet = employee.outlet;
  const position = employee.position;

  if (!outlet && !position) {
   return 0;
  }

  if (calculateLate(employee, clockIn, 0, status)) {
   return calculateLate(employee, clockIn, 0, status);
  }

  return 0;
 };

 const getTotalText = (text, number, textEnd = "") => {
  if (!text || !number) return "";
  return `${text}${number}${textEnd}`;
 };

 const ProductTable = (props) => {
  const { items, requestSort, sortConfig } = useSortableData(props.products);
  const getClassNamesFor = (name) => {
   if (!sortConfig) {
    return;
   }
   return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  // Membuat array tanggal dari 26 Juli 2024 sampai 25 Agustus 2024
  const startDate = dayjs(date);
  const endDate = dayjs(startDate).add(1, "month").subtract(1, "day");
  const totalDays = endDate.diff(startDate, "day") + 1;
  const dateRange = [];

  //console.log(`startDate: ${startDate.format("DD/MM/YYYY")}`);
  //console.log(`endDate: ${endDate.format("DD/MM/YYYY")}`);

  for (
   let date = startDate;
   date.isBefore(endDate) || date.isSame(endDate);
   date = date.add(1, "day")
  ) {
   dateRange.push(date);
  }

  return (
   <div>
    <Table bordered hover size="sm" className="custom-table">
     <thead className="sticky-header">
      <tr>
       <th
        style={{
         textAlign: "center",
         padding: "0.5px",
         minWidth: "400px",
         border: "1px solid lightgray",
        }}
       >
        <Button
         variant="dark"
         type="button"
         onClick={() => requestSort("name")}
         className={getClassNamesFor("name") + " sort-btn"}
        >
         Name
        </Button>
       </th>
       {/* Generate columns for dates */}
       {dateRange.map((date) => (
        <th
         key={date.format("YYYY-MM-DD")}
         style={{
          textAlign: "center",
          border: "1px solid lightgray",
         }}
        >
         <span style={{ fontSize: "8px", fontWeight: "normal" }}>
          {date.format("MMM")}
         </span>
         <br />
         {date.format("DD")}
         <br />
         <span style={{ fontSize: "8px", fontWeight: "normal" }}>
          {capitalizeEachWord(date.format("ddd"))}
         </span>
        </th>
       ))}
      </tr>
     </thead>
     <tbody style={{ color: "#000000" }}>
      {items &&
       items
        .filter((item) => {
         if (isActiveEmployee) {
          return !item.dateResign; // Show only employees who have not resigned
         } else {
          return item.dateResign; // Show only employees who have resigned
         }
        })
        .map((item) => (
         <tr
          key={item._id}
          style={{
           border: "1px solid lightgray",
           maxHeight: "10px", // Set maximum height for the row
           overflow: "hidden", // Hide overflow content
           verticalAlign: "middle", // Vertically align the content
           minWidth: "530px",
          }}
         >
          <td
           style={{
            minWidth: "200px",
            overflowWrap: "break-word",
            lineHeight: "10px", // Adjust line height to fit the 20px height
            height: "10px", // Set fixed height for the cells
            padding: "0px", // Remove padding to fit the max height
            verticalAlign: "middle", // Vertically align the content
           }}
          >
           <div
            style={{
             display: "flex",
             justifyContent: "space-between",
             gap: "10px", // optional, to add space between the image and the name
             textAlign: "center", // Horizontal centering
             verticalAlign: "middle", // Vertical centering
             marginLeft: "5px",
            }}
           >
            <LinkContainer to={`/employee/${item._id}`}>
             <NavLink className="mt-1">
              <PhotoProfile
               profilePicturePath={item.profilePicture}
               height="25px"
               width="25px"
               borderRadius="50%"
               isInsideRow={false}
               marginRight="10px"
              />
              <span
               style={{
                flexGrow: 1,
                textAlign: "left",
                fontSize: "14px",
                verticalAlign: "middle", // Vertical centering
                fontWeight: "400",
               }}
              >
               {`${capitalizeEachWord(item.name)} `}
               {typeof item.status === "string" &&
                item.status.toLowerCase().includes("tetap") && (
                 <img
                  alt="Verified"
                  src={verifiedIcon}
                  style={{ width: "18px", height: "18px" }}
                  loading="lazy"
                 />
                )}
              </span>
             </NavLink>
            </LinkContainer>

            <div style={{ display: "flex", gap: "5px" }}>
             {item.totalHanyaLembur > 0 && (
              <TooltipButtonSimple
               variant="secondary"
               buttonColor="white"
               buttonText={`HL:${item.totalHanyaLembur}`}
               text={`Hanya lembur saja ${
                item.totalHanyaLembur &&
                " sebanyak " + item.totalHanyaLembur + " hari"
               }, tidak dihitung hari kerja, tidak mendapatkan tunjangan harian, hanya dapat upah jam lembur saja`}
              ></TooltipButtonSimple>
             )}
             {item.totalSakit > 0 ||
             item.totalSakitNonSurat > 0 ||
             item.totalSakitNonSurat > 0 ||
             item.totalIzin > 0 ||
             item.totalAbsen > 0 ||
             item.totalCutiTahunan > 0 ||
             item.totalCutiMelahirkan > 0 ||
             item.totalCutiIstriMelahirkan > 0 ||
             item.totalCutiAnggotaKeluargaMeninggal > 0 ? (
              <TooltipButtonSimple
               variant="light"
               buttonMaxHeight={"50px"}
               buttonText={`${getTotalText(
                "Sakit:",
                item.totalSakit
               )}${getTotalText(
                " SNS:",
                item.totalSakitNonSurat
               )}${getTotalText(" Izin:", item.totalIzin)}${getTotalText(
                " Absen:",
                item.totalAbsen
               )}${getTotalText(" Cuti:", item.totalCutiTahunan)}${getTotalText(
                " CM:",
                item.totalCutiMelahirkan
               )}${getTotalText(
                " CI:",
                item.totalCutiIstriMelahirkan
               )}${getTotalText(
                " CKM:",
                item.totalCutiAnggotaKeluargaMeninggal
               )}`}
               text={`${getTotalText(
                "Sakit:",
                item.totalSakit,
                " hari,"
               )}${getTotalText(
                " Sakit tanpa surat keterangan dokter:",
                item.totalSakitNonSurat,
                " hari,"
               )}${getTotalText(
                " Izin:",
                item.totalIzin,
                " hari,"
               )}${getTotalText(
                " Absen:",
                item.totalAbsen,
                " hari,"
               )}${getTotalText(
                " Cuti tahunan:",
                item.totalCutiTahunan,
                " hari,"
               )}${getTotalText(
                " Cuti melahirkan:",
                item.totalCutiMelahirkan,
                " hari,"
               )}${getTotalText(
                " Cuti istri melahirkan:",
                item.totalCutiIstriMelahirkan,
                " hari,"
               )}${getTotalText(
                " Cuti anggota keluarga satu rumah meninggal dunia:",
                item.totalCutiAnggotaKeluargaMeninggal,
                " hari,"
               )}`}
              ></TooltipButtonSimple>
             ) : (
              <></>
             )}
             {item.totalHadir > 0 && (
              <TooltipButtonSimple
               buttonText={`${item.totalHadir}/${totalDays}`}
               text={`Total hari kerja (HK) ${
                " sebanyak " + item.totalHadir + " hari"
               }, karyawan Tetap mendapatkan tunjangan harian berdasarkan HK. Karyawan Probation mendapatkan upah harian berdasarkan HK`}
              ></TooltipButtonSimple>
             )}
             {userInfo.isAdmin && (
              <Button
               variant="light"
               className="btn-sm d-flex align-items-center"
               onClick={(event) => handleShowAddAttendance(event, item)}
               style={{
                padding: "5px",
                height: "27px",
               }}
              >
               <i className="fas fa-edit" style={{ fontSize: "12px" }}></i>
              </Button>
             )}
            </div>
           </div>

           <Row
            style={{
             marginLeft: "27px",
             display: "flex",
             justifyContent: "flex-start",
            }}
           >
            <Col md="auto">
             <div style={{ display: "flex", gap: "5px" }}>
              {
               <>
                <Button
                 variant="secondary"
                 className="btn-sm d-flex align-items-center"
                 style={{
                  verticalAlign: "middle", // Vertical centering
                  borderRadius: "5px",
                 }}
                >
                 <span
                  style={{
                   textAlign: "left",
                   fontSize: "8px",
                   verticalAlign: "middle", // Vertical centering
                  }}
                 >
                  {item.position &&
                  typeof item.position === "string" &&
                  item.position.length < 4
                   ? item.position
                   : capitalizeEachWord(item.position)}
                 </span>
                </Button>
                <Button
                 variant="light"
                 className="btn-sm d-flex align-items-center"
                 style={{
                  verticalAlign: "middle", // Vertical centering
                  borderRadius: "5px",
                 }}
                >
                 <span
                  style={{
                   textAlign: "left",
                   fontSize: "8px",
                   verticalAlign: "middle", // Vertical centering
                  }}
                 >
                  {item.employeeID}
                 </span>
                </Button>
               </>
              }
              {typeof item.status === "string" &&
               !item.status.toLowerCase().includes("tetap") && (
                <Button
                 variant="secondary"
                 className="btn-sm d-flex align-items-center"
                 style={{
                  borderColor: "transparent", // Agar warna bordernya sesuai dengan background
                  verticalAlign: "middle", // Vertical centering
                  borderRadius: "5px",
                  backgroundColor:
                   typeof item.status === "string" &&
                   item.status.toLowerCase().includes("tetap")
                    ? "rgb(169, 199, 169)"
                    : "rgb(168, 240, 245)",
                  color:
                   typeof item.status === "string" &&
                   item.status.toLowerCase().includes("tetap")
                    ? "rgb(6, 106, 6)"
                    : "rgb(8, 48, 181)",
                 }}
                >
                 <span
                  style={{
                   textAlign: "left",
                   fontSize: "8px",
                   verticalAlign: "middle", // Vertical centering
                  }}
                 >
                  {capitalizeEachWord(item.status)}
                 </span>
                </Button>
               )}
              {item.lateInTolerance > 0 && (
               <TooltipButtonSimple
                variant="light"
                buttonText={`LT: ${item.lateInTolerance} minutes`}
                text={`Late in tolerance / toleransi keterlambatan masuk ${
                 item.lateInTolerance && item.lateInTolerance + " menit"
                }, jika keterlambatan di atas ${
                 item.lateInTolerance
                } menit, maka gaji dipotong denda keterlambatan, jika terlambat dan keterlambatan di bawah ${
                 item.lateInTolerance
                } menit, maka tidak berhak dapat tunjangan kehadiran`}
               ></TooltipButtonSimple>
              )}
             </div>
            </Col>
           </Row>
           <Row
            style={{
             marginLeft: "27px",
             display: "flex",
             justifyContent: "flex-start",
            }}
           >
            <Col md="auto">
             {item.dateIn && (
              <div style={{ display: "flex", gap: "5px" }}>
               {item.dateIn && (
                <TooltipButtonSimple
                 buttonText={capitalizeEachWord(
                  dayjs(item.dateIn).format("DD MMM YYYY")
                 )}
                 text={`Tanggal join ke perusahaan ${capitalizeEachWord(
                  dayjs(item.dateIn).format("dddd, DD MMMM YYYY")
                 )}`}
                 buttonBackgroundColor={"rgb(169, 199, 169)"}
                 buttonColor="rgba(52, 58, 64, 0.8)"
                ></TooltipButtonSimple>
               )}

               {item.dateResign && (
                <TooltipButtonSimple
                 buttonText={capitalizeEachWord(
                  dayjs(item.dateResign).format("DD MMM YYYY")
                 )}
                 text={`Tanggal resign ${capitalizeEachWord(
                  dayjs(item.dateResign).format("dddd, DD MMMM YYYY")
                 )}`}
                 buttonBackgroundColor={"rgb(242, 153, 153)"}
                 buttonColor="rgba(52, 58, 64, 0.8)"
                ></TooltipButtonSimple>
               )}

               {item.dateIn && (
                <TooltipButtonSimple
                 buttonText={getWorkDuration(
                  dayjs(item.dateIn),
                  dayjs(item.dateResign),
                  true
                 )}
                 text={`Masa kerja ${getWorkDuration(
                  dayjs(item.dateIn),
                  dayjs(item.dateResign),
                  true
                 )}`}
                ></TooltipButtonSimple>
               )}

               {item.totalOvertimeHours > 0 && (
                <TooltipButtonSimple
                 buttonText={`OT: ${item.totalOvertimeHours} hours`}
                 text={`Total jam lembur ${
                  " sebanyak " + item.totalOvertimeHours + " jam"
                 }`}
                 buttonBackgroundColor={"rgba(204, 153, 255, 1)"}
                ></TooltipButtonSimple>
               )}

               {item.totalOvertimeHLN > 0 && (
                <TooltipButtonSimple
                 buttonText={`HLN: ${item.totalOvertimeHLN}`}
                 text={`Total lembur di hari libur nasional ${
                  " sebanyak " + item.totalOvertimeHLN + " hari"
                 }, Karyawan Tetap mendapatkan tunjangan harian dan tambahan 2 x tunjangan harian`}
                 buttonBackgroundColor={"rgb(242, 153, 153)"}
                ></TooltipButtonSimple>
               )}

               {item.totalOvertimeLongshift > 0 && (
                <TooltipButtonSimple
                 buttonText={`Longshift: ${item.totalOvertimeLongshift}`}
                 text={`Total lembur Longshift ${
                  " sebanyak " + item.totalOvertimeLongshift + " hari"
                 }, Karyawan Tetap mendapatkan tunjangan harian dan tambahan 2 x tunjangan harian, karyawan Probation tidak ada longshift`}
                 buttonBackgroundColor={"rgb(242, 153, 153)"}
                ></TooltipButtonSimple>
               )}
              </div>
             )}
            </Col>
           </Row>
          </td>
          {/* Generate columns for each date */}
          {dateRange.map((date) => {
           const dateString = date.format("YYYY-MM-DD");
           const attendance = item.attendanceData.find((entry) =>
            dayjs(entry.date).isSame(date, "day")
           );

           return (
            <td
             key={`${item._id}-${dateString}`}
             style={{
              border: "1px solid lightgray",
              maxWidth: "80px",
              textAlign: "center", // Horizontal centering
              verticalAlign: "middle", // Vertical centering
              justifyContent: "center",
              fontSize: "8px",
             }}
            >
             <TooltipButton
              date={date.toDate()}
              dateIn={item.dateIn}
              dateResign={item.dateResign}
              text={
               attendance
                ? attendance.status === "hadir" ||
                  attendance.status === "hanya lembur"
                  ? `${attendance.status}\ndate: ${dayjs(
                     attendance.date
                    ).format("ddd, DD-MMM-YYYY")}\nin: ${
                     attendance.clockIn &&
                     dayjs(attendance.clockIn).format("HH:mm")
                    }\nout: ${
                     attendance.clockOut &&
                     dayjs(attendance.clockOut).format("HH:mm")
                    }\novertime: ${
                     attendance.overtimeHours
                    } hours\novertime type: ${
                     attendance.overtimeType && attendance.overtimeType
                    }\nduration: ${
                     convertSeconds(attendance.workingDurationInSeconds).hours
                    } hours, ${
                     convertSeconds(attendance.workingDurationInSeconds).minutes
                    } minutes\n${
                     lateInText(item, attendance.clockIn, attendance.status) ||
                     0
                    } minutes`
                  : `${attendance.status}\ndate: ${dayjs(
                     attendance.date
                    ).format("DD-MMM-YYYY")}`
                : `date: ${dayjs(date).format("ddd, DD-MMM-YYYY")}\n`
              }
              buttonText={
               attendance
                ? attendance.status === "hadir" ||
                  attendance.status === "hanya lembur"
                  ? attendance.overtimeHours
                    ? attendance.status === "hanya lembur"
                      ? `${
                         attendance.clockIn &&
                         dayjs(attendance.clockIn).format("HH:mm")
                        }\n${
                         attendance.clockOut &&
                         dayjs(attendance.clockOut).format("HH:mm")
                        } OT ${attendance.overtimeHours} ${lateInText(
                         item,
                         attendance.clockIn,
                         attendance.status
                        )}`
                      : attendance.status === "hadir" &&
                        `${
                         attendance.clockIn &&
                         dayjs(attendance.clockIn).format("HH:mm")
                        }\n${
                         attendance.clockOut &&
                         dayjs(attendance.clockOut).format("HH:mm")
                        } OT ${attendance.overtimeHours} ${lateInText(
                         item,
                         attendance.clockIn,
                         attendance.status
                        )}`
                    : `${
                       attendance.clockIn &&
                       dayjs(attendance.clockIn).format("HH:mm")
                      }\n${
                       attendance.clockOut &&
                       dayjs(attendance.clockOut).format("HH:mm")
                      } ${lateInText(
                       item,
                       attendance.clockIn,
                       attendance.status
                      )} ${
                       attendance.overtimeType
                        ? attendance.overtimeType === "hari libur nasional"
                          ? "HLN"
                          : attendance.overtimeType === "longshift"
                          ? "Long"
                          : ""
                        : ""
                      }`
                  : `${attendance.status}`
                : "-"
              }
              status={attendance && attendance.status}
              late={
               item &&
               attendance &&
               attendance.clockIn &&
               lateInNumber(item, attendance.clockIn, attendance.status)
              }
              lateInTolerance={item.lateInTolerance ? item.lateInTolerance : 0}
              description={attendance?.description && attendance.description}
              clockIn={attendance?.clockIn}
              clockOut={attendance?.clockOut}
             />
            </td>
           );
          })}
         </tr>
        ))}
     </tbody>
    </Table>
   </div>
  );
 };

 const isOutletValid =
  typeof addAttendanceItem.outlet === "string" &&
  !addAttendanceItem.outlet.toLowerCase().includes("management");

 const renderOvertimeFields = () => (
  <Row>
   <Col>
    <Form.Group controlId="overtimeHours">
     <Form.Label>Overtime Hours</Form.Label>
     <Form.Control
      type="number"
      placeholder="overtime hours"
      value={addAttendanceOvertimeHours}
      onChange={(e) => setAddAttendanceOvertimeHours(e.target.value)}
      onWheel={(e) => e.target.blur()}
     />
    </Form.Group>
   </Col>
   <Col>
    <Form.Group controlId="overtimeType">
     <Form.Label>Overtime Type</Form.Label>
     <Form.Select
      value={addAttendanceOvertimeType}
      onChange={(e) => setAddAttendanceOvertimeType(e.target.value)}
      style={{
       color: "white",
       backgroundColor: "#dc3545",
      }}
     >
      {stringIncludes(addAttendanceItem.status, "tetap") &&
      !addAttendanceOvertimeHours
       ? ["select...", "normal", "longshift", "hari libur nasional"].map(
          (type, index) => (
           <option key={index} value={type}>
            {capitalizeEachWord(type)}
           </option>
          )
         )
       : ["normal"].map((type, index) => (
          <option key={index} value={type}>
           {capitalizeEachWord(type)}
          </option>
         ))}
     </Form.Select>
    </Form.Group>
   </Col>
  </Row>
 );
 return (
  <>
   {<ProductTable products={attendanceSummary} />}
   {/* Modal untuk menampilkan popup */}
   {userInfo.isAdmin && (
    <Modal
     show={showAddAttendance}
     onHide={(event) => handleCloseAddAttendance(event)}
    >
     <Modal.Header closeButton>
      <Modal.Title>Add Attendance</Modal.Title>
     </Modal.Header>
     <Modal.Body>
      {/* Form di dalam popup */}
      <Form onSubmit={handleSubmit}>
       <Row>
        <Col md="3">
         <Form.Group controlId="employeeID">
          <Form.Label>Employee ID</Form.Label>
          <Form.Control
           type="text"
           placeholder="employeeID"
           value={addAttendanceItem.employeeID}
           disabled
          />
         </Form.Group>
        </Col>
        <Col md="9">
         <Form.Group controlId="name">
          <Form.Label>Name</Form.Label>
          <Form.Control
           type="text"
           placeholder="name"
           value={addAttendanceItem.name}
           disabled
          />
         </Form.Group>
        </Col>
       </Row>

       <Row>
        <Col>
         <Form.Group controlId="date">
          <Form.Label>Date</Form.Label>
          <Form.Control
           type="date"
           value={dateForPicker(addAttendanceDate)}
           onChange={(e) => {
            setAddAttendanceDate(dateFromDateString(e.target.value));
            setAddAttendanceData(matchingDates);
            //matchingDates();
           }}
          />
         </Form.Group>
        </Col>
        <Col>
         <Form.Group controlId="status">
          <Form.Label>Status</Form.Label>
          <Form.Select
           value={addAttendanceStatus}
           onChange={(e) => setAddAttendanceStatus(e.target.value)}
           style={{
            color: "white",
            backgroundColor: "#dc3545",
           }}
          >
           {[
            "select...",
            "hadir",
            "sakit",
            "sakit non surat",
            "izin",
            "absen",
            "hanya lembur",
            "cuti tahunan",
            "cuti istri melahirkan",
            "cuti melahirkan",
            "cuti anggota keluarga satu rumah meninggal",
           ].map((status, index) =>
            !index ? (
             <option key={index} value="">
              {capitalizeEachWord(status)}
             </option>
            ) : (
             <option key={index} value={status}>
              {capitalizeEachWord(status)}
             </option>
            )
           )}
          </Form.Select>
         </Form.Group>
        </Col>
       </Row>

       <Row>
        <Col>
         <Form.Group controlId="clockIn">
          <Form.Label>Clock In</Form.Label>
          <Form.Control
           type="time"
           value={addAttendanceClockIn}
           onChange={(e) => setAddAttendanceClockIn(e.target.value)}
          />
         </Form.Group>
        </Col>
        <Col>
         <Form.Group controlId="clockOut">
          <Form.Label>Clock Out</Form.Label>
          <Form.Control
           type="time"
           value={addAttendanceClockOut}
           onChange={(e) => setAddAttendanceClockOut(e.target.value)}
          />
         </Form.Group>
        </Col>
       </Row>

       {(addAttendanceStatus === "hadir" &&
        isOutletValid &&
        renderOvertimeFields()) ||
        (addAttendanceStatus === "hanya lembur" &&
         isOutletValid &&
         renderOvertimeFields())}
       <Row>
        <Col>
         <Form.Group controlId="description">
          <Form.Label>Description (optional)</Form.Label>
          <Form.Control
           placeholder="description"
           value={addAttendanceDescription}
           onChange={(e) => setAddAttendanceDescription(e.target.value)}
           as="textarea"
           rows={3}
           disabled={
            addAttendanceStatus &&
            !stringIncludes(addAttendanceStatus, "select")
             ? false
             : true
           }
          />
         </Form.Group>
        </Col>
       </Row>
      </Form>
     </Modal.Body>
     <Modal.Footer style={{ display: "flex", justifyContent: "space-between" }}>
      {addAttendanceData && (
       <Button
        variant="danger"
        className="btn-sm"
        onClick={deleteAttendanceHandler}
        style={{ marginRight: "auto" }} // Agar tombol trash berada di paling kiri
       >
        <i className="fas fa-trash"></i>
       </Button>
      )}

      <Button
       variant="secondary"
       onClick={(event) => handleCloseAddAttendance(event)}
      >
       Cancel
      </Button>
      <Button
       variant="danger"
       type="submit"
       onClick={(event) => handleSubmit(event)}
      >
       Apply
      </Button>
     </Modal.Footer>
    </Modal>
   )}
  </>
 );
};

export default AddAttendanceTableView;
