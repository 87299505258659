export const SHEET_LIST_REQUEST = "SHEET_LIST_REQUEST";
export const SHEET_LIST_SUCCESS = "SHEET_LIST_SUCCESS";
export const SHEET_LIST_ERROR = "SHEET_LIST_ERROR";

export const SHEET_ADD_REQUEST = "SHEET_ADD_REQUEST";
export const SHEET_ADD_SUCCESS = "SHEET_ADD_SUCCESS";
export const SHEET_ADD_ERROR = "SHEET_ADD_ERROR";

export const SHEET_DETAILS_REQUEST = "SHEET_DETAILS_REQUEST";
export const SHEET_DETAILS_SUCCESS = "SHEET_DETAILS_SUCCESS";
export const SHEET_DETAILS_ERROR = "SHEET_DETAILS_ERROR";

export const SHEET_UPDATE_REQUEST = "SHEET_UPDATE_REQUEST";
export const SHEET_UPDATE_SUCCESS = "SHEET_UPDATE_SUCCESS";
export const SHEET_UPDATE_ERROR = "SHEET_UPDATE_ERROR";
export const SHEET_UPDATE_RESET = "SHEET_UPDATE_RESET";

export const SHEET_DELETE_REQUEST = "SHEET_DELETE_REQUEST";
export const SHEET_DELETE_SUCCESS = "SHEET_DELETE_SUCCESS";
export const SHEET_DELETE_ERROR = "SHEET_DELETE_ERROR";
export const SHEET_DELETE_RESET = "SHEET_DELETE_RESET";

export const SHEET_ROOM_NO_REQUEST = "SHEET_ROOM_NO_REQUEST";
export const SHEET_ROOM_NO_SUCCESS = "SHEET_ROOM_NO_SUCCESS";
export const SHEET_ROOM_NO_ERROR = "SHEET_ROOM_NO_ERROR";
export const SHEET_ROOM_NO_RESET = "SHEET_ROOM_NO_RESET";
